import { FormGroup, InputGroup } from '@blueprintjs/core'
import '@blueprintjs/table/lib/css/table.css'
import produce from 'immer'
import React from 'react'
import { compose, withProps } from 'recompose'
import { withContext } from './StrengthPlanContext'

class StrengthPlanEditMesocycle extends React.Component {
  handleUpdate = mesocycle => {
    const {
      updateMesocycle,
      selected: { mesoIndex },
    } = this.props
    updateMesocycle({
      mesoIndex,
      mesocycle,
    })
  }

  handleChangeName = ({ target: { value } }) => {
    const { mesocycle } = this.props
    this.handleUpdate(
      produce(mesocycle, draft => {
        draft.name = value
      }),
    )
  }

  render() {
    const {
      mesocycle: { name },
    } = this.props

    return (
      <FormGroup label="Name">
        <InputGroup value={name} onChange={this.handleChangeName} />
      </FormGroup>
    )
  }
}

const enhance = compose(
  withContext,
  withProps(({ selected: { mesoIndex }, plan: { mesocycles } }) => ({
    mesocycle: mesocycles[mesoIndex],
  })),
)

export default enhance(StrengthPlanEditMesocycle)
