import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects'
import {
  FETCH_PLAN_FAIL,
  FETCH_PLAN_REQUEST,
  FETCH_PLAN_SUCCESS,
  MARK_AS_DIRTY,
  SET_ENTRYPOINTS,
} from './ducks/common'
import { TRIGGER_COMPONENT_UPDATE, UPDATE_COMPONENT } from './ducks/components'
import { SET_EXERCISES } from './ducks/exercises'
import {
  dispatchFetchError,
  FETCH_END,
  FETCH_ERROR,
  FETCH_START,
} from './ducks/fetch'
import { SET_SUBSCRIPTION_PLANS } from './ducks/subscriptionPlans'
import {
  getEntryPoints,
  getExercises,
  getSubscriptionPlans,
  getLifts,
} from './lib/fetchService'
import { SET_LIFTS } from './ducks/lifts'
import history from './createHistory'

export function* getCommonData() {
  const [
    { data: entryPoints },
    { data: exercises },
    { data: subscriptionPlans },
    { data: lifts },
  ] = yield all([
    call(getEntryPoints),
    call(getExercises),
    call(getSubscriptionPlans),
    call(getLifts),
  ])

  yield put({
    type: SET_SUBSCRIPTION_PLANS,
    subscriptionPlans: subscriptionPlans['hydra:member'],
  })

  yield put({
    type: SET_ENTRYPOINTS,
    entryPoints,
  })

  yield put({
    type: SET_EXERCISES,
    exercises: exercises['hydra:member'],
  })

  yield put({
    type: SET_LIFTS,
    lifts: lifts['hydra:member'],
  })
}

function* getPlanFromServer(action) {
  try {
    yield put({ type: FETCH_START, endpoint: 'day' })

    const [
      { data: entryPoints },
      { data: exercises },
      { data: subscriptionPlans },
      { data: lifts },
      // { data: modules },
      // { data: moduleDates },
    ] = yield all([
      call(getEntryPoints),
      call(getExercises),
      call(getSubscriptionPlans),
      call(getLifts),
      // call(fetchModules, action.date.format()),
      // call(fetchModuleDates, action.date.toISODate()),
    ])

    yield put({
      type: SET_SUBSCRIPTION_PLANS,
      subscriptionPlans: subscriptionPlans['hydra:member'],
    })

    yield put({
      type: SET_ENTRYPOINTS,
      entryPoints,
    })

    yield put({
      type: SET_EXERCISES,
      exercises: exercises['hydra:member'],
    })

    yield put({
      type: SET_LIFTS,
      exercises: lifts['hydra:member'],
    })

    // const normalizedModuleData = normalize(
    //   moduleDates['hydra:member'],
    //   arrayOfModuleDates,
    // )

    // yield put({
    //   type: SET_PLAN,
    //   plan: normalizedModuleData,
    // })
    // const normalizedModules = normalize(modules['hydra:member'], arrayOfModules)

    // yield put({
    //   type: SET_PLAN,
    //   plan: normalizedModules,
    // })
    yield put({ type: FETCH_PLAN_SUCCESS })
    yield put({ type: FETCH_END, endpoint: 'day' })
  } catch (err) {
    console.log(err)
    yield put({ type: FETCH_PLAN_FAIL })
    yield put(dispatchFetchError({ endpoint: 'day', error: err }))
  }
}

export function* fetchStateSaga() {
  yield takeLatest(FETCH_PLAN_REQUEST, getPlanFromServer)
}

function* handleUpdateComponent(action) {
  yield put({ type: UPDATE_COMPONENT, component: action.component })
}

export function* watchInputForComponent() {
  yield throttle(1000, TRIGGER_COMPONENT_UPDATE, handleUpdateComponent)
}

export function* watchForFetchError() {
  yield takeLatest(FETCH_ERROR, function* redirectIfNotauthenticated({
    error,
  }) {
    if (error.status === 401 || error.status === 403) {
      if (window.location.pathname !== '/login') {
        console.log(history)
        history.push('/login')
      }
    }
  })
}

const isExerciseUpdateAction = action =>
  action.type.includes('MARK_AS_DIRTY') ||
  action.type.includes('EXERCISE') ||
  action.type.includes('MINUTE') ||
  action.type.includes('UPDATE')

function* handleUpdateAction(action) {
  if (action.parent) {
    yield put({ type: MARK_AS_DIRTY, entity: action.parent })
  } else if (action.entity && action.entity.parent) {
    yield put({ type: MARK_AS_DIRTY, entity: action.entity.parent })
  }
}

export function* watchForExerciseBlockChange() {
  yield throttle(1000, isExerciseUpdateAction, handleUpdateAction)
}

export function* watchForMarkAsDirty() {
  yield takeEvery(MARK_AS_DIRTY, handleUpdateAction)
}
