import { Button, ButtonGroup } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import React from 'react'
import ModuleDeleteButton from '../Common/ModuleDeleteButton'

const ModuleDateActions = ({
  handleSave,
  expanded,
  dirty,
  handleExpand,
  handleCopyToDate,
  handleRemove,
  ...props
}) => (
  <ButtonGroup {...props}>
    {dirty && (
      <Button
        intent="success"
        icon="floppy-disk"
        onClick={handleSave}
        disabled={!dirty}
      />
    )}

    <Button
      icon={expanded ? 'chevron-up' : 'chevron-down'}
      onClick={handleExpand}
    />

    <ModuleDeleteButton onDelete={handleRemove} />
  </ButtonGroup>
)

ModuleDateActions.propTypes = {
  handleSave: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  dirty: PropTypes.bool,
  handleExpand: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
}

ModuleDateActions.defaultProps = {
  dirty: false,
}

export default ModuleDateActions
