import axios from 'axios'
import get from 'lodash/get'
import startsWith from 'lodash/startsWith'
import PromiseFileReader from 'promise-file-reader'
import history from '../createHistory'
import { AUTH_TOKEN } from './constants'

const baseUrl = process.env.REACT_APP_API_URL

const defaultHeaders = {
  Accept: 'application/ld+json',
  'Content-Type': 'application/ld+json',
  admin: true,
}

export const instance = axios.create({
  baseURL: `${baseUrl}/api`,
  headers: defaultHeaders,
})

instance.defaults.params = {}
instance.defaults.params.pagination = false

instance.interceptors.request.use(config => {
  // eslint-disable-line
  if (startsWith(config.url, '/api/')) {
    config.url = config.url.replace('api/', '')
  }
  const token = localStorage.getItem(AUTH_TOKEN)
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

instance.interceptors.response.use(
  response => response,
  error => {
    const status = get(error, 'response.status')

    if (status === 401 || status === 403) {
      if (window.location.pathname !== '/login') {
        history.push('/login')
      }
    }
    // Do something with response error
    return Promise.reject(error)
  },
)

export const getEntryPoints = () => instance.get()

export const saveExercise = exercise => instance.put(exercise['@id'], exercise)
// instance.put(`exercises/${exercise.id}`, exercise)
// callApiWithIRI(`${exercise['@id']}`, {}, 'PUT', JSON.stringify(exercise))
export const createExercise = (type, name) => instance.post(type, { name })
// callApiWithIRI(`/api_2/${type}`, {}, 'POST', JSON.stringify({ name }))
export const fetchExercise = id => instance.get(`/exercises/${id}`)

export const uploadFile = async inputFile => {
  const file = await PromiseFileReader.readAsDataURL(inputFile)
  const uploadedFile = await instance.post('images', { file })
  return uploadedFile
}

export const fetchUsers = () => instance.get('users')
export const fetchBlogPosts = () =>
  instance.get('posts', {
    params: { 'order[createdAt]': 'desc' },
  })

export const fetchBlogPost = slug => instance.get('posts', { params: { slug } })

export const updatePost = post => instance.put(`posts/${post.id}`, post)
export const createPost = post => instance.post('posts', post)

export const getSubscriptionPlans = () => instance.get('subscription_plans')
export const getLifts = () => instance.get('lifts')

export const fetchWeightliftingModuleComponents = ({
  page = 1,
  order = 'DESC',
  before,
  after,
}) =>
  instance.get('weightlifting_components', {
    params: {
      pagination: !!page,
      'module.date[before]': before.format('YYYY-MM-DD'),
      'module.date[after]': after.format('YYYY-MM-DD'),
      'order[date]': order || null,
      page: page || null,
    },
  })

export const fetchAthletes = () => instance.get('athleteDetails')

export const deleteWeightliftingModuleComponent = id =>
  instance.delete(`weightlifting_components/${id}`)

export const fetchWeightliftingTrainingTrainingUnits = () =>
  instance.get('modules', {
    params: { type: 'weightlifting', subtype: 'training' },
  })

export const getWeekPlanByDate = startDate =>
  instance.get('microcycles', {
    params: { startDate: startDate.format('Y-MM-DD') },
  })

export const getWeekPlan = date => getWeekPlanByDate(date.startOf('week'))

export const getExercises = params =>
  instance.get('exercises', { params: { pagination: false, ...params } })

export const updateComponent = component =>
  instance.put(component['@id'], component)

export const importWeightlifting = data =>
  instance.post('import_weightlifting', data)

export const createComponent = (module, endPoint, position) =>
  instance.post(
    endPoint,
    { module: module.id, position },
    { headers: { 'Content-Type': 'application/json' } },
  )

export const removeComponent = component => instance.delete(component['@id'])

export const login = async (email, password) =>
  instance.post('login_check', { email, password })

export const copyModuleAsTemplate = ({ module: { id } }) =>
  instance.post(`modules/${id}/copy`, {})

export const addModuleToTemplateMicrocycle = ({
  module,
  templateMicrocycle,
  day,
  session,
  copy,
}) =>
  instance.post('modules/add_to_microcycle', {
    module,
    templateMicrocycle,
    day,
    session,
    copy,
  })

export const fetchGeneric = type => instance.get(type)

export const uploadVideo = async data => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_GRAPHQL_URL}/video_upload`,
      data,
    )

    return res
  } catch (e) {
    console.error('upload error')
    console.error(e)
  }
}
