import { Card, Classes, MenuDivider } from '@blueprintjs/core'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { fetchStrengthPlans } from '../../lib/fetch/strengthplans'
import NewStrenghPlanForm from './NewStrengthPlanForm'

const LinkMenuItem = ({ label, ...props }) => (
  <NavLink
    activeClassName={Classes.INTENT_PRIMARY}
    className="bp3-tag"
    {...props}
  >
    {label}
  </NavLink>
)

const List = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }
`

class StrengthPlanList extends React.Component {
  state = {
    plans: [],
    loading: false,
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    this.start()
    try {
      const res = await fetchStrengthPlans()
      this.setState({ plans: res.data['hydra:member'] })
    } catch (e) {}
    this.finish()
  }

  start = () => this.setState({ loading: true })

  finish = () => this.setState({ loading: false })

  render() {
    return (
      <Card>
        <List>
          <MenuDivider title="Pläne" />
          {this.state.plans.map(({ id, name }) => (
            <LinkMenuItem to={`/strengthplans/${id}`} key={name} label={name} />
          ))}
          <NewStrenghPlanForm
            style={{ marginTop: '1em' }}
            refetch={this.fetchData}
          />
        </List>
      </Card>
    )
  }
}

export default StrengthPlanList
