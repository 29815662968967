import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { DropDownAdapter } from 'components/Form/Adapters'

const QUERY = gql`
  query lift {
    lifts {
      id
      name
    }
  }
`

const EditableBenchmarkCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  handleExerciseUpdate, // This is a custom function that we supplied to our table instance
  handleTableUpdate,
}) => {
  const { data, error, loading } = useQuery(QUERY)

  const liftOptions = useMemo(
    () =>
      (data?.lifts || []).map(({ id, name }) => ({ value: id, label: name })),
    [data],
  )
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = value => {
    const option = data.lifts.find(o => o.id === value)
    handleExerciseUpdate(index, id, option)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  if (loading) {
    return <div>Loading</div>
  }

  return (
    <div style={{ width: '200px' }}>
      <DropDownAdapter options={liftOptions} input={{ value, onChange }} />
    </div>
  )
}

export default EditableBenchmarkCell
