import {
  Classes,
  Radio,
  RadioGroup,
  Spinner,
  FormGroup,
  Menu,
  InputGroup,
  Checkbox,
  ButtonGroup,
  Drawer,
  Position,
  Card,
  Tag,
  Elevation,
  Button,
} from '@blueprintjs/core'
import * as _ from 'lodash'
import React, { useState, useEffect, useMemo } from 'react'
import { Link, Route, Switch, useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  VariableSizeList,
  VariableSizeGrid,
  FixedSizeGrid,
  FixedSizeList,
} from 'react-window'
import GraphQLErrors from 'components/Common/GraphQLErrors'
import { useWindowSize, useMeasure, useToggle } from 'react-use'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useSetRecoilState,
  useRecoilValue,
} from 'recoil'
import { easeElastic } from 'd3'
import { Colors } from '../../lib/Colors'
import ExerciseContainer from './ExerciseContainer'
import NewExerciseContainer from './NewExerciseContainer'

const types = [
  { value: 'gymnasticsexercise', label: 'Gymnastics' },
  { value: 'crossfitexercise', label: 'MetCon' },
  { value: 'weightliftingexercise', label: 'Weightlifting' },
]

const exerciseAppGroupFilterState = atom({
  key: 'exerciseAppGroupFilterState',
  default: {},
})

const exerciseSortState = atom({
  key: 'exerciseSortState',
  default: 'name',
})

const exerciseSortOrderState = atom({
  key: 'exerciseSortOrderState',
  default: 'asc',
})

const ALL_EXERCISES = gql`
  query exercises {
    exercises(pageSize: 1000) {
      exercises {
        ... on IExercise {
          id
          name
          category
          exerciseBlockCount
          discr
          pluralName
          description
          quillDescription
          createdAt
          updatedAt
          femaleScalingFactor
          groups {
            id
            name
            type
          }
        }
      }
    }
    exerciseGroups {
      id
      name
      type
    }
  }
`

const ExerciseApp = () => {
  const { data, error, loading } = useQuery(ALL_EXERCISES)
  const history = useHistory()

  const [filter, setFilter] = useState('')
  const [filterType, setFilterType] = useState('all')
  const [groupFilter, setGroupFilter] = useRecoilState(
    exerciseAppGroupFilterState,
  )

  const [exerciseSort, setExerciseSort] = useRecoilState(exerciseSortState)
  const [exerciseSortOrder, setExerciseSortOrder] = useRecoilState(
    exerciseSortOrderState,
  )

  const handleFilterTypeChange = ({ currentTarget: { value } }) => {
    setFilterType(value)
  }

  const [open, setOpen] = useToggle(false)

  const exerciseGroups = useMemo(
    () => _.groupBy(data?.exerciseGroups ?? [], 'type'),
    [data],
  )

  useEffect(() => {
    const filter = {}

    Object.entries(exerciseGroups).forEach(([type, entries]) => {
      const groupFilter = {}

      entries.forEach(e => {
        groupFilter.enabled = false
        groupFilter[e.name] = true
      })

      filter[type] = groupFilter
    })

    setGroupFilter(filter)
  }, [exerciseGroups, setGroupFilter])

  const filteredExercises = useMemo(() => {
    let exercises = data?.exercises?.exercises ?? []

    exercises = exercises
      .filter(e => filterType === 'all' || e.discr === filterType)
      .filter(e => e.name.match(new RegExp(filter, 'i')))

    for (const { enabled, ...items } of Object.values(groupFilter)) {
      if (enabled) {
        const searchValues = Object.entries(items)
          .filter(([, value]) => value)
          .map(([name]) => name)

        exercises = exercises.filter(e =>
          e.groups.find(g => searchValues.includes(g.name)),
        )
      }
    }

    return _.orderBy(
      exercises,
      ['discr', exerciseSort],
      ['asc', exerciseSortOrder],
    )
  }, [data, exerciseSort, exerciseSortOrder, filter, filterType, groupFilter])

  if (error) {
    return (
      <GraphQLErrors error={error}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </GraphQLErrors>
    )
  }

  if (loading) {
    return <Spinner />
  }

  const groupedExercises = _.groupBy(filteredExercises, 'discr')

  const handleSortChange = ({ target: { value } }) => {
    setExerciseSort(value)
  }

  const handleSortOrderChange = ({ target: { value } }) => {
    setExerciseSortOrder(value)
  }

  return (
    <>
      <div>
        <ButtonGroup style={{ marginBottom: '8px' }}>
          <Button onClick={() => setOpen(true)}>
            Übungen filtern & sortieren
          </Button>
          <Link to="/exercises/new" className={Classes.BUTTON}>
            Neue Übung hinzufügen
          </Link>
        </ButtonGroup>
        <div>
          <InputGroup
            id="exercise-filter"
            placeholder="Übung suchen"
            value={filter}
            onChange={({ target: { value } }) => setFilter(value)}
          />
        </div>
      </div>
      <Drawer
        isOpen={open}
        title="Filter"
        onClose={() => setOpen(false)}
        position={Position.LEFT}
      >
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY}>
            <h5>Filter</h5>
            <RadioGroup
              inline
              onChange={handleFilterTypeChange}
              selectedValue={filterType}
            >
              <Radio label="Alle" value="all" />
              <Radio label="MetCon" value="crossfitexercise" />
              <Radio label="Weightlifting" value="weightliftingexercise" />
              <Radio label="Gymnastics" value="gymnasticsexercise" />
            </RadioGroup>

            {Object.entries(groupFilter).map(
              ([type, { enabled, ...items }]) => (
                <div key={type}>
                  <div>
                    <Checkbox
                      inline
                      checked={groupFilter[type].enabled}
                      label={`Nach ${type} filtern`}
                      onChange={() =>
                        setGroupFilter(groupFilter => ({
                          ...groupFilter,
                          [type]: {
                            ...groupFilter[type],
                            enabled: !groupFilter[type].enabled,
                          },
                        }))
                      }
                    />
                  </div>
                  {Object.keys(items).map(item => (
                    <Checkbox
                      key={item}
                      inline
                      disabled={!groupFilter[type].enabled}
                      checked={groupFilter[type][item]}
                      label={item}
                      onChange={() =>
                        setGroupFilter(groupFilter => ({
                          ...groupFilter,
                          [type]: {
                            ...groupFilter[type],
                            [item]: !groupFilter[type][item],
                          },
                        }))
                      }
                    />
                  ))}
                </div>
              ),
            )}

            <h5>Sortierung</h5>

            <RadioGroup
              inline
              onChange={handleSortChange}
              selectedValue={exerciseSort}
            >
              <Radio label="Name" value="name" />
              <Radio label="# Verwendet" value="exerciseBlockCount" />
            </RadioGroup>
            <RadioGroup
              inline
              onChange={handleSortOrderChange}
              selectedValue={exerciseSortOrder}
            >
              <Radio label="Aufsteigend" value="asc" />
              <Radio label="Absteigend" value="desc" />
            </RadioGroup>
          </div>
        </div>
      </Drawer>

      {Object.entries(groupedExercises).map(([discr, exercises]) => {
        const group = types.find(t => t.value === discr)

        return (
          <>
            <div style={{ padding: '16px' }}>{group.label}</div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {exercises
                .filter(e => e)
                .map(exercise => (
                  <Card
                    interactive
                    elevation={Elevation.ZERO}
                    style={{
                      width: `300px`,
                      height: '180px',
                      margin: '8px',
                    }}
                    onClick={() => history.push(`/exercises/${exercise.id}`)}
                  >
                    <h5 style={{ marginBlockEnd: 0 }}>
                      #{exercise.id} - {exercise.name}
                    </h5>
                    <div
                      className="bp3-text-muted bp3-text-small"
                      style={{ marginBottom: '8px' }}
                    >
                      Verwendet: ~{exercise.exerciseBlockCount} Mal
                    </div>

                    <div>
                      {exercise.groups ? (
                        exercise.groups.map(group => (
                          <Tag
                            style={{
                              margin: '4px',
                            }}
                          >
                            {group.name}
                          </Tag>
                        ))
                      ) : (
                        <pre>{JSON.stringify(exercise, null, 2)}</pre>
                      )}
                    </div>
                  </Card>
                ))}
            </div>
          </>
        )
      })}

      <Switch>
        <Route path="/exercises/new" component={NewExerciseContainer} />
        <Route path="/exercises/:id" component={ExerciseContainer} />
      </Switch>
    </>
  )
}

export default ExerciseApp
