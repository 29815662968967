import { useMutation } from '@apollo/react-hooks'
import {
  Button,
  Classes,
  Label,
  Spinner,
  Code,
  Divider,
  Drawer,
  H5,
  HTMLSelect,
  IOptionProps,
  Position,
  Switch,
} from '@blueprintjs/core'
import gql from 'graphql-tag'
import { AppToaster } from 'lib/toaster'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import TextInputAdapter from '../UI/Form/Adapters/TextInputAdapter'

const types = [
  { value: 'gymnasticsexercise', label: 'Gymnastics' },
  { value: 'crossfitexercise', label: 'MetCon' },
]

const CREATE_EXERCISE = gql`
  mutation createExercise($data: CreateExerciseInput!) {
    createExercise(data: $data) {
      ... on IExercise {
        id
      }
    }
  }
`

const NewExerciseContainer = () => {
  const history = useHistory()
  const [submit] = useMutation(CREATE_EXERCISE, {
    refetchQueries: ['exercise', 'exercises'],
  })

  const handleSave = async values => {
    try {
      const { data } = await submit({
        variables: {
          data: values,
        },
      })
      AppToaster.show({
        message: 'Gespeichert',
        intent: 'success',
      })
      history.push(`/exercises/${data.createExercise.id}`)
    } catch (e) {
      AppToaster.show({
        message: 'Fehler beim Speichern',
        intent: 'warning',
      })
    }
  }

  return (
    <Drawer
      isOpen
      title="Neue Übung"
      onClose={() => history.push('/exercises')}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          <Form
            onSubmit={handleSave}
            initialValues={{ discr: 'crossfitexercise' }}
            render={({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <Field name="name" label="Name" component={TextInputAdapter} />
                <Field
                  name="discr"
                  render={({ input }) => (
                    <Label>
                      Typ
                      <div className={Classes.SELECT}>
                        <select {...input}>
                          {types.map(({ value, label }) => (
                            <option value={value}>{label}</option>
                          ))}
                        </select>
                      </div>
                    </Label>
                  )}
                />
                <Button type="submit" intent="primary">
                  {submitting ? 'Wird gespeichert... ' : 'Speichern'}
                </Button>
              </form>
            )}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default NewExerciseContainer
