import React from 'react'
import { withStateHandlers, compose, withProps } from 'recompose'
import { flatMapDeep, map, includes, groupBy, size } from 'lodash'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import Chart from './Chart'
import TextModuleComponent from './TextModuleComponent'
import dateFormats from '../../../lib/dateFormats'

const enhance = compose(
  connect(({ evaluation: { filter } }) => ({ filter })),
  withProps(({ component, filter }) => ({
    logs: flatMapDeep(component.logs, log =>
      map(log.value, v => ({
        set: v.set,
        reps: Number(v.reps),
        weight: Number(v.weight),
        reference: Number(v.reference),
        athleteDetails: log.athleteDetails.id,
      })),
    )
      .filter(l => l.reference > 0)
      .filter(l =>
        filter.id.length > 0 ? includes(filter.id, l.athleteDetails) : true,
      ),
  })),
  withStateHandlers(
    { mode: 'chart' },
    {
      toggleMode: ({ mode }) => () => ({
        mode: mode === 'chart' ? 'text' : 'chart',
      }),
    },
  ),
)

const Component = ({ component, mode, toggleMode, logs }) => (
  <div
    className="cell"
    style={{
      marginTop: '16px',
      marginBottom: '16px',
      padding: '16px',
      border: '1px solid rgba(0, 0, 0, 0.37)',
    }}
  >
    <div className="grid-x align-justify">
      <div className="cell shrink">
        <h6>
          {DateTime.fromISO(component.date).toLocaleString(
            dateFormats.DATE_FULL,
          )}
        </h6>
      </div>
      <div className="cell shrink">
        {size(groupBy(logs, 'athleteDetails'))} Protokolle
      </div>
      <div className="cell shrink">
        <button className="button" onClick={toggleMode}>
          {mode === 'chart' ? 'als Text' : 'als Diagramm'}
        </button>
      </div>
    </div>
    {mode === 'chart' ? (
      <Chart data={{ component, logs }} id={component.id} />
    ) : (
      <TextModuleComponent component={component} logs={logs} />
    )}
  </div>
)

export default enhance(Component)
