import { Intent } from '@blueprintjs/core'
import React from 'react'
import ConfirmButton from './ConfirmButton'

const ModuleDeleteButton = ({ onDelete }) => (
  <ConfirmButton
    onConfirm={onDelete}
    buttonProps={{ intent: Intent.DANGER, icon: 'trash' }}
    alertProps={{
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Löschen',
      icon: 'trash',
      intent: Intent.DANGER,
      content: <p>Das Modul wirklich unwideruflich löschen?</p>,
    }}
  />
)

export default ModuleDeleteButton
