import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { last } from 'lodash'
import map from 'lodash/map'
import { DateTime } from 'luxon'
import matchSorter from 'match-sorter'
import React from 'react'
import ReactTable, { useTable, useTokenPagination } from 'react-table'
import ReactTooltip from 'react-tooltip'
import dateFormats from '../../lib/dateFormats'

const MEMBERS_QUERY = gql`
  query members(
    $first: Int
    $after: ID
    $orderBy: MemberOrderByInput
    $filter: MemberFilterInput
  ) {
    members(first: $first, after: $after, orderBy: $orderBy, filter: $filter) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          email
          createdAt
          lastLogin
          athleteDetails {
            firstName
          }
          memberships {
            id
            expiresAt
            plan {
              name
            }
          }
        }
      }
    }
  }
`

const InvalidDate = () => <span>Nie</span>

const TimeAgoCell = ({ value }) => {
  const date = DateTime.fromISO(value)
  if (!date.isValid) {
    return <span>Nie</span>
  }

  return (
    <div
      data-tip={date.toLocaleString({
        ...dateFormats.DATE_FULL,
        hour: '2-digit',
        minute: '2-digit',
      })}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <ReactTooltip />
      <span>
        {date.toLocaleString({
          ...dateFormats.DATE_FULL,
          hour: '2-digit',
          minute: '2-digit',
        })}
      </span>
    </div>
  )
}

const UserApp = () => {
  const [after, setAfter] = React.useState(null)

  const { data, error, loading } = useQuery(MEMBERS_QUERY, {
    variables: {
      first: 20,
      after,
    },
  })

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        id: 'id',
        accessor: 'id',
      },
      {
        Header: 'E-Mail',
        id: 'email',
        accessor: d => d.email,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['email'] }),
        filterAll: true,
      },
      {
        Header: 'zuletzt eingeloggt',
        accessor: 'lastLogin',
        Cell: TimeAgoCell,
      },
      {
        Header: 'Mitglied seit',
        accessor: 'createdAt',
        Cell: props => (
          <span className="number">
            {DateTime.fromISO(props.value).toLocaleString(
              dateFormats.DATE_FULL,
            )}
          </span>
        ),
      },
      {
        id: 'membership-plan', // Required because our accessor is not a string
        Header: 'Mitgliedschaft',
        accessor: d => map(d.memberships, m => m.plan.name), // Custom value accessors!
        Cell: ({ value }) =>
          value.length ? value.join(', ') : 'Keine aktive Mitgliedschaft',
      },
      // {
      //   id: 'competition-date', // Required because our accessor is not a string
      //   Header: 'Nächster Wettkampf',
      //   accessor: 'athleteDetails.nextCompetition', // Custom value accessors!
      //   Cell: props => (
      //     <span className="number">
      //       {DateTime.fromISO(props.value).toLocaleString(dateFormats.DATE_FULL)}
      //     </span>
      //   ),
      // },
      {
        id: 'latest-expiration',
        Header: props => <span>Gültig bis</span>, // Custom header components!
        accessor: d => {
          if (d.memberships.length > 0) {
            return last(d.memberships).expiresAt
          }
          return null
        }, // Custom value accessors!
        Cell: props => (
          <span>
            {DateTime.fromISO(props.value).toLocaleString(
              dateFormats.DATE_FULL,
            )}
          </span>
        ),
      },
    ],
    [],
  )

  const users = data?.members.edges.map(e => e.node) || []

  const tableInstance = useTable({ columns, data: users }, useTokenPagination)

  if (loading) {
    return 'Lade...'
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    setNextPageToken,
    pageToken,
    pageIndex,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    resetPagination,
  } = tableInstance

  return (
    <>
      <pre>{JSON.stringify({ after }, null, 2)}</pre>
      <table {...getTableProps()} className="bp3-html-table">
        <thead>
          {// Loop over the header rows
          headerGroups.map(headerGroup => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {// Loop over the headers in each row
              headerGroup.headers.map(column => (
                // Apply the header cell props
                <th {...column.getHeaderProps()}>
                  {// Render the header
                  column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
          rows.map(row => {
            // Prepare the row for display
            prepareRow(row)
            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {// Loop over the rows cells
                row.cells.map(cell => (
                  // Apply the cell props
                  <td {...cell.getCellProps()}>
                    {// Render the cell contents
                    cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      {data?.members.pageInfo.hasPreviousPage && (
        <button
          onClick={() => {
            setAfter(data.members.pageInfo.startCursor)
          }}
        >
          previous page
        </button>
      )}
      {data?.members.pageInfo.hasNextPage && (
        <button
          onClick={() => {
            setAfter(data.members.pageInfo.endCursor)
          }}
        >
          next page
        </button>
      )}
    </>
  )

  // return (
  //   <ReactTable
  //     columns={columns}
  //     data={users}
  //     filterable
  //     SubComponent={({ original }) => (
  //       <div>
  //         <SubTable
  //           user={original.memberships.map(m => ({
  //             email: original.email,
  //             ...m,
  //           }))}
  //         />
  //       </div>
  //     )}
  //   />
  // )
}

const SubTable = ({ user }) => (
  <ReactTable
    columns={[
      {
        Header: 'Plan',
        accessor: 'plan.name',
      },
      {
        Header: 'Gekauft am',
        accessor: 'createdAt',
        Cell: props => (
          <span>
            {DateTime.fromISO(props.value).toLocaleString(
              dateFormats.DATE_FULL,
            )}
          </span>
        ),
      },
      {
        Header: 'Gültig bis',
        accessor: 'expiresAt',
        Cell: props => (
          <span>
            {DateTime.fromISO(props.value).toLocaleString(
              dateFormats.DATE_FULL,
            )}
          </span>
        ),
      },
    ]}
    data={user}
    defaultPageSize={5}
  />
)

export default UserApp
