import deepFilter from 'deep-filter'
import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  all,
} from 'redux-saga/effects'
import {
  CREATE_COMPONENT_FAIL,
  CREATE_COMPONENT_REQUEST,
  CREATE_COMPONENT_SUCCESS,
  REMOVE_COMPONENT_FAIL,
  REMOVE_COMPONENT_REQUEST,
  REMOVE_COMPONENT_SUCCESS,
  RESORT_COMPONENT,
  RESORT_COMPONENT_REQUEST,
  SAVE_COMPONENT_FAIL,
  SAVE_COMPONENT_REQUEST,
  SAVE_COMPONENT_SUCCESS,
} from '../ducks/components'
import { dispatchFetchError, FETCH_END, FETCH_START } from '../ducks/fetch'
import { UPDATE_MODULE } from '../ducks/modules'
import {
  createComponent,
  removeComponent,
  updateComponent,
} from '../lib/fetchService'
import { lowerCaseFirstLetter } from '../lib/helper'
import { denormalizeComponent } from '../lib/normalize'

const getEntryPoint = (state, componentType) =>
  state.config.entryPoints[lowerCaseFirstLetter(componentType)]

function* createComponentRequest({ module, componentType, position }) {
  try {
    yield put({ type: FETCH_START, module, endpoint: 'component' })
    const endPoint = yield select(getEntryPoint, componentType)
    const { data: component } = yield call(
      createComponent,
      module,
      endPoint,
      position,
    )
    yield put({ type: CREATE_COMPONENT_SUCCESS, component, module })
    yield put({ type: FETCH_END, module, endpoint: 'component' })
  } catch (error) {
    yield put({ type: CREATE_COMPONENT_FAIL, message: error.message })
    yield put(dispatchFetchError({ endpoint: 'component', error, module }))
  }
}

export function* createComponentSaga() {
  yield takeEvery(CREATE_COMPONENT_REQUEST, createComponentRequest)
}

function* removeComponentRequest({ module, component }) {
  try {
    yield put({ type: FETCH_START, module, component, endpoint: 'component' })
    yield call(removeComponent, component)
    yield put({ type: REMOVE_COMPONENT_SUCCESS, module, component })
    yield put({ type: FETCH_END, module, endpoint: 'component' })
  } catch (error) {
    yield put({ type: REMOVE_COMPONENT_FAIL, message: error.message })
    yield put(
      dispatchFetchError({ endpoint: 'component', error, module, component }),
    )
  }
}

export function* removeComponentSaga() {
  yield takeEvery(REMOVE_COMPONENT_REQUEST, removeComponentRequest)
}

const getDenormalizedComponent = (state, component) =>
  denormalizeComponent(component, state)

function* saveComponentToServerRequest({ component }) {
  try {
    yield put({ type: FETCH_START, component, endpoint: 'component' })
    const denormalizedComponent = yield select(
      getDenormalizedComponent,
      component,
    )
    const filteredComponent = deepFilter(
      denormalizedComponent,
      (value, prop) => !(prop === 'id' && value < 0),
    )

    yield call(updateComponent, filteredComponent)
    yield put({ type: SAVE_COMPONENT_SUCCESS, component })
    yield put({ type: FETCH_END, component, endpoint: 'component' })
  } catch (error) {
    yield put({ type: SAVE_COMPONENT_FAIL, message: error.response.statusText })
    yield put({ type: FETCH_END, component, endpoint: 'component' })
    yield put(dispatchFetchError({ endpoint: 'component', error, component }))
  }
}

export function* saveComponentSaga() {
  yield takeLatest(SAVE_COMPONENT_REQUEST, saveComponentToServerRequest)
}

function* resortModuleComponents({ components, module }) {
  yield all(
    components.map(component =>
      put({
        type: RESORT_COMPONENT,
        component: { id: component.id, position: component.position },
      }),
    ),
  )
  yield put({ type: UPDATE_MODULE, module: { id: module.id } })
}

export function* resortComponentSaga() {
  yield takeLatest(RESORT_COMPONENT_REQUEST, resortModuleComponents)
}
