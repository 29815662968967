import React from 'react'
import { Form, Field } from 'react-final-form'
import Dropzone from 'react-dropzone'
import { head } from 'lodash'
import FormAdaptersTextInputAdapter from 'components/UI/Form/Adapters/TextInputAdapter'
import { uploadVideo } from 'lib/fetchService'
import styled from 'styled-components'
import { Button } from '@blueprintjs/core'

const StyledDropzone = styled(Dropzone)`
  background-color: #f0f0f0;
  border: dashed;
  border-color: #c8c8c8;
  cursor: pointer;
  height: 144px;
  width: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DropZoneLink = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
  height: 20px;
  margin-top: 5px;
`

const DropZoneContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
`

export const required = value => (value ? undefined : 'Required')

const VideoUploadForm = ({ refetch }) => {
  const handleSubmit = async values => {
    const formData = new FormData()
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value)
    })

    await uploadVideo(formData)
    refetch()
    return null
  }

  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
            component={FormAdaptersTextInputAdapter}
            label="Name"
          />
          <Field
            name="file"
            validate={required}
            render={({ input }) => (
              <div>
                <StyledDropzone
                  onDrop={acceptedFiles => {
                    input.onChange(head(acceptedFiles))
                  }}
                  multiple={false}
                >
                  {input.value ? (
                    <DropZoneContent>
                      <DropZoneLink>{input.value.name}</DropZoneLink>
                    </DropZoneContent>
                  ) : (
                    <div>Klicken oder Datei hineinziehen</div>
                  )}
                </StyledDropzone>
              </div>
            )}
          />
          <Button
            type="submit"
            intent="primary"
            disabled={submitting}
            loading={submitting}
          >
            Video hochladen
          </Button>
        </form>
      )}
    </Form>
  )
}

export default VideoUploadForm
