import { Checkbox, Classes, NumericInput } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import moduleTypes from 'lib/moduleTypes'
import useModule from 'lib/hooks/useModule'
import Dropdown from '../Common/Dropdown'
import { RightAlignedControlGroup, Spacer } from '../ModuleDate/Controls'
import ModuleActions from './Actions/Actions'

const StyledDiv = styled.div`
  & > *:not(:first-child) {
    margin-top: 10px;
  }
`

const ModuleControls = ({
  moduleId,
  handleTypeSelectChange,
  handleSubTypeSelectChange,
  handleValueChange,
  handleNumberValueChange,
  handleSaveModule,
  toggleExpand,
  handleRemoveModule,
  expanded,
  bind,
  handleReorder,
  reordering,
}) => {
  const module = useModule(moduleId)

  const handleDurationChange = value =>
    handleNumberValueChange({ name: 'duration', value })
  const handleSessionChange = value =>
    handleNumberValueChange({ name: 'session', value })
  return (
    <StyledDiv>
      <RightAlignedControlGroup fill>
        <input
          name="name"
          className={Classes.INPUT}
          value={module.name || undefined}
          onChange={handleValueChange}
        />

        <Dropdown
          options={moduleTypes.types}
          name="type"
          label="Typ"
          value={module.type}
          onChange={handleTypeSelectChange}
        />
        <Dropdown
          name="subtype"
          label="Subtyp"
          value={module.subtype}
          options={moduleTypes.subtypes[module.type]}
          onChange={handleSubTypeSelectChange}
        />

        <NumericInput
          leftIcon="time"
          name="duration"
          style={{ width: '60px', flexGrow: 0 }}
          value={module.duration / 60}
          onValueChange={handleDurationChange}
        />
        <Spacer />
        <ModuleActions
          style={{ flexGrow: 0 }}
          dirty={module.dirty}
          handleSave={handleSaveModule}
          handleExpand={toggleExpand}
          handleRemove={handleRemoveModule}
          handleReorder={handleReorder}
          reordering={reordering}
          expanded={expanded}
          {...bind}
        />
      </RightAlignedControlGroup>
      <RightAlignedControlGroup fill>
        <Checkbox
          type="checkbox"
          name="evaluable"
          label="Evaluierbar"
          checked={module.evaluable}
          onChange={handleValueChange}
        />

        <Checkbox
          type="checkbox"
          name="placeholder"
          label="Platzhalter"
          checked={module.placeholder}
          onChange={handleValueChange}
        />
        <Spacer />
      </RightAlignedControlGroup>
    </StyledDiv>
  )
}

ModuleControls.propTypes = {
  module: PropTypes.object.isRequired, // eslint-disable-line
  handleTypeSelectChange: PropTypes.func.isRequired,
  handleSubTypeSelectChange: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  handleNumberValueChange: PropTypes.func.isRequired,
  handleSaveModule: PropTypes.func.isRequired,
  toggleExpand: PropTypes.func.isRequired,
  handleRemoveModule: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  bind: PropTypes.object, // eslint-disable-line
}

ModuleControls.defaultProps = {
  bind: {},
}

export default ModuleControls
