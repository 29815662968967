import React, { useState } from 'react'

import DatePicker from 'react-datepicker'
import PromiseFileReader from 'promise-file-reader'
import { Spinner } from '@blueprintjs/core'

import 'react-datepicker/dist/react-datepicker.css'
import { DateTime } from 'luxon'
import { importWeightlifting } from '../../lib/fetchService'
import history from '../../createHistory'
import LuxonLocaleUtils from '../../lib/LuxonLocaleUtils'

const ImportWeightliftingContainer = () => {
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .startOf('week')
      .toJSDate(),
  )
  const [inputFile, setInputFile] = useState(null)
  const [loading, setLoading] = useState(false)

  if (loading) {
    return <Spinner />
  }

  const handleUploadClick = async () => {
    setLoading(true)
    const file = await PromiseFileReader.readAsDataURL(inputFile)

    const data = {
      importFile: file,
      date: DateTime.fromJSDate(startDate).toISODate(),
    }
    try {
      await importWeightlifting(data)
      history.push('/weightlifting/assign')
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }
  return (
    <div className="grid-x align-center">
      <div className="cell auto">
        <label>Importdatei</label>
        <input type="file" onChange={e => setInputFile(e.target.files[0])} />
      </div>
      <div className="cell auto">
        <label>Wochenbeginn</label>
        <DatePicker
          locale="de"
          localeUtils={LuxonLocaleUtils}
          selected={startDate}
          maxDate={DateTime.local()
            .plus({ years: 1 })
            .toJSDate()}
          onChange={date => setStartDate(date)}
          filterDate={date => DateTime.fromJSDate(date).weekday === 1}
        />
      </div>
      <div className="cell auto">
        <button
          className="button primary"
          disabled={!inputFile}
          onClick={handleUploadClick}
        >
          Upload
        </button>
      </div>
    </div>
  )
}

export default ImportWeightliftingContainer
