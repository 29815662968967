import {
  Button,
  Classes,
  Dialog,
  Intent,
  MenuItem,
  Slider,
} from '@blueprintjs/core'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import React from 'react'
import { Toggle } from 'react-powerplug'
import CenteredRow from '../../Layout/StyledComponents/CenteredRow'
import MicrocycleTemplateSearch from '../../Templates/MicrocycleTemplates/MicrocycleTemplateSearch'

class CopyToTemplateDialog extends React.Component {
  state = {
    targetCycle: null,
    targetDay: 0,
  }

  setTargetCycle = targetCycle =>
    this.setState({
      targetCycle,
    })

  setTargetDay = targetDay => this.setState({ targetDay })

  handleCopy = () => {
    const { targetCycle, targetDay } = this.state
    this.props.handleCopyToTemplate({
      templateMicrocycle: targetCycle,
      day: targetDay,
    })
    this.props.toggle()
  }

  render() {
    const { toggle } = this.props
    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          <CenteredRow>
            <MicrocycleTemplateSearch
              shouldDismissPopover={false}
              cycle={this.state.targetCycle}
              onChange={this.setTargetCycle}
            />
          </CenteredRow>
          <Slider
            min={0}
            max={6}
            stepSize={1}
            labelStepSize={1}
            onChange={this.setTargetDay}
            value={this.state.targetDay}
            labelRenderer={number =>
              DateTime.local()
                .startOf('week')
                .plus({ days: number }).weekdayShort
            }
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={toggle}>abbrechen</Button>
            <Button
              disabled={!this.state.targetCycle}
              intent={Intent.PRIMARY}
              onClick={this.handleCopy}
            >
              Kopieren
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const CopyModuleToTemplateAction = ({ handleCopyToTemplate, duplicate }) => {
  const copyToTemplate = values =>
    handleCopyToTemplate({ ...values, copy: duplicate })
  return (
    <Toggle>
      {({ on, toggle }) => (
        <div>
          <MenuItem
            text={`In Template Microzyklus ${
              duplicate ? 'duplizieren' : 'kopieren'
            }`}
            onClick={toggle}
            shouldDismissPopover={false}
          />

          <Dialog isOpen={on}>
            <CopyToTemplateDialog
              toggle={toggle}
              handleCopyToTemplate={copyToTemplate}
            />
          </Dialog>
        </div>
      )}
    </Toggle>
  )
}

CopyModuleToTemplateAction.propTypes = {
  handleCopyToTemplate: PropTypes.func.isRequired,
  duplicate: PropTypes.bool,
}

CopyModuleToTemplateAction.defaultProps = {
  duplicate: false,
}

export default CopyModuleToTemplateAction
