import { Button, Classes, Intent, ProgressBar } from '@blueprintjs/core'
import ApolloExerciseInput from 'components/ApolloExerciseInput'
import { required } from 'components/Apps/VideoUploadForm'
import {
  CheckboxAdapter,
  DropDownAdapter,
  TextFieldAdapter,
} from 'components/Form/Adapters'
import { Info } from 'luxon'
import React, { useMemo } from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'

import { gql } from 'apollo-boost'

const Error = styled.div`
  color: red;
`

const Row = styled.div`
  display: flex;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`

const query = gql`
  query lift {
    lifts {
      id
      name
    }
  }
`

const PowerbuildingExerciseForm = ({
  handleSubmit,
  invalid,
  submitting,
  submitError,
}) => {
  const { data, error, loading } = useQuery(query)

  const liftOptions = useMemo(
    () =>
      (data?.lifts || []).map(({ id, name }) => ({ value: id, label: name })),
    [data],
  )
  return (
    <form onSubmit={handleSubmit}>
      <div className={Classes.DIALOG_BODY}>
        <div>
          <label>
            Woche
            <Field
              name="week"
              label="Woche"
              component={DropDownAdapter}
              options={[
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
              ].map(i => ({
                value: i,
                label: `${i}`,
              }))}
            />
          </label>
          <label>
            Tag
            <Field
              name="day"
              label="Tag"
              component={DropDownAdapter}
              options={[1, 2, 3, 4, 5, 6, 7].map(i => ({
                value: i,
                label: Info.weekdays()[i - 1],
              }))}
            />
          </label>
        </div>
        <div>
          <Field
            name="identifier"
            type="text"
            label="Identifier"
            validate={required}
            component={TextFieldAdapter}
          />
        </div>

          <div>
              <label>
                  Übung
                  <Row>
                      <Field
                          style={{ width: '100px' }}
                          component={TextFieldAdapter}
                          name="preModifier"
                          placeholder="preModifier"
                          type="text"
                      />
                      <ApolloExerciseInput
                          style={{ width: '300px' }}
                          name="exerciseId"
                          placeholder="Movement"
                      />
                      <Field
                          style={{ width: '100px' }}
                          component={TextFieldAdapter}
                          name="postModifier"
                          placeholder="postModifier"
                          type="text"
                      />
                  </Row>

              </label>
          </div>

          <div>
              <Field
                  name="strengthPlanId"
                  component={CheckboxAdapter}
                  format={value => (value ? true : false)}
                  parse={value => (value ? '3' : null)}
                  label="Rep Scheme aus Strength Plan"
              />
          </div>

          <Row>
              <Field
                  name="benchmarkId"
                  label="Last relativ zu"
                  component={DropDownAdapter}
                  allowNull
                  options={[
                      { value: null, label: 'Keine' },
                      ...liftOptions,
                  ]}
              />
          </Row>

        <div>



          <Field name="strengthPlanId" subscription={{ value: true }}>
            {({ input: { value } }) => {
              if (!value) {
                return (
                  <>
                      <Row>
                          <Field
                              name="sets"
                              type="text"
                              label="Sets"
                              component={TextFieldAdapter}
                          />
                          <Field
                              name="reps"
                              type="text"
                              label="Reps"
                              component={TextFieldAdapter}
                          />
                      </Row>
                    <Row>
                      <Field
                        name="repsInReserve"
                        type="text"
                        label="Reps In Reserve"
                        parse={value => (value === '' ? null : Number(value))}
                        component={TextFieldAdapter}
                      />
                      <Field
                        name="tempo"
                        type="text"
                        label="Tempo"
                        component={TextFieldAdapter}
                      />
                    </Row>

                  </>
                )
              }
              return null
            }}
          </Field>
        </div>
        <div>
          <Field
            name="comment"
            type="text"
            label="Comment"
            component={TextFieldAdapter}
          />
        </div>
        {submitError && <Error>{submitError}</Error>}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            type="submit"
            intent={Intent.SUCCESS}
            disabled={invalid}
            loading={submitting}
          >
            Speichern
          </Button>
        </div>
      </div>
    </form>
  )
}

export default PowerbuildingExerciseForm
