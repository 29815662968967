import { invert } from 'lodash'
import { DateTime } from 'luxon'
import React, {
  Component,
  useState,
  useEffect,
  useCallback,
  useReducer,
} from 'react'
import ReactCalendar from 'react-calendar'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import { createSelector } from 'reselect'
import { fetchModuleDatesBetween } from '../../ducks/calendar'
import Dots from './Dots'
import './style.css'

const getTypes = state =>
  state.calendar.result
    .map(id => state.calendar.entities.moduleDates[id])
    .reduce((acc, item) => {
      const ISODate = DateTime.fromISO(item.date).toISODate()
      if (!acc[ISODate]) {
        acc[ISODate] = []
      }
      const type = state.calendar.entities.modules[item.module].type
      if (!acc[ISODate].some(t => t === type)) {
        acc[ISODate].push(type)
      }
      return acc
    }, {})

const typesSelector = createSelector(
  getTypes,
  types => types,
)

function reducer(state, action) {
  switch (action.type) {
    case 'setDate':
      return { date: action.date, prevDate: state.date }
    default:
      // A reducer must always return a valid state.
      // Alternatively you can throw an error if an invalid action is dispatched.
      return state
  }
}

const Calendar = ({
  types,
  match: {
    params: { date: propsDate },
  },
  history,
  fetchModuleDatesBetween,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    date: DateTime.fromISO(propsDate).toJSDate(),
  })

  const fetchData = date => {
    const start = date.startOf('month').startOf('week')
    const end = date.endOf('month').endOf('week')

    fetchModuleDatesBetween(start.toISODate(), end.toISODate())
  }

  useEffect(
    () => {
      const date = DateTime.fromJSDate(state.date)

      if (
        !state.prevDate ||
        !DateTime.fromJSDate(state.prevDate).hasSame(date, 'month')
      ) {
        fetchData(date)
      }
    },
    [state],
  )

  const onChange = date => {
    dispatch({ type: 'setDate', date })
    history.push(`/plan/${DateTime.fromJSDate(date).toISODate()}`)
  }

  const getTileContent = useCallback(
    ({ date, view }) => {
      if (view !== 'month') {
        return null
      }

      const isoDate = DateTime.fromJSDate(date).toISODate()
      return <Dots {...invert(types[isoDate])} />
    },
    [types],
  )

  return (
    <div>
      <ReactCalendar
        showFixedNumberOfWeeks
        showWeekNumbers
        minDetail="month"
        onChange={onChange}
        value={state.date}
        tileContent={getTileContent}
      />
    </div>
  )
}

const mapStateToProps = ({ fetch, calendar, subscriptionPlans }) => ({
  fetch: fetch.calendar > 0,
  calendar,
  subscriptionPlans,
  types: typesSelector({ calendar }),
})

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    { fetchModuleDatesBetween },
  ),
)

export default enhance(Calendar)
