import {
  Button,
  ButtonGroup,
  Card,
  Icon,
  Checkbox,
  Dialog,
  Classes,
} from '@blueprintjs/core'
import color from 'color'
import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { Toggle } from 'react-powerplug'
import { compose, withHandlers } from 'recompose'
import styled from 'styled-components'
import { Colors } from '../../../lib/Colors'
import {
  removeTemplateModule,
  saveTemplateModule,
} from '../../../lib/fetch/templates'
import moduleTypes from '../../../lib/moduleTypes'
import ModuleDeleteButton from '../../Common/ModuleDeleteButton'
import ModuleEditButton from '../../Common/ModuleEditButton'
import ViewModuleContainer from '../../ModuleApp/ViewModuleContainer'
import { withTemplateTriggers } from '../Generic/TemplateContext'
import ModuleSubscriptionPlanControl from './ModuleSubscriptionPlanControl'

const ButtonPanel = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -20px;
  margin-top: -20px;
  margin-bottom: 1em;
`
const StyledCard = styled.div.attrs({
  className: Classes.CARD,
})`
  width: 100%;
  position: relative;
  background-color: ${props =>
    color(Colors[props.type])
      .fade(0.8)
      .hsl()
      .toString()};
`

const Name = styled.div`
  font-size: small;
`

const TemplatesMicrocycleModule = ({
  id,
  position,
  module: { id: moduleId, type, subtype, name },
  placeholder,
  handleDelete,
  handleChangePlaceholder,
  subscriptionPlans,
  allSubscriptionPlans,
  saveSubscriptionPlans,
  snapshot,
  provided,
}) => (
  <StyledCard
    type={type}
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
  >
    <ButtonPanel>
      <ButtonGroup>
        <div
          className={classnames(Classes.BUTTON, Classes.MINIMAL)}
          {...provided.dragHandleProps}
        >
          <Icon icon="move" />
        </div>
        <Toggle initial={false}>
          {({ on, toggle }) => (
            <div>
              <Dialog
                style={{ minWidth: '500px', width: 'auto' }}
                isOpen={on}
                onClose={toggle}
                enforceFocus={false}
              >
                <ViewModuleContainer id={moduleId} />
              </Dialog>
              <Button icon="eye-open" onClick={toggle} />
            </div>
          )}
        </Toggle>
        <ModuleEditButton id={moduleId} />
        <ModuleDeleteButton onDelete={handleDelete} />
      </ButtonGroup>
    </ButtonPanel>
    <div>
      {position + 1}. {moduleTypes.types.find(t => t.value === type).shortLabel}{' '}
      - {subtype}
    </div>

    <Name>{`${name || 'kein Name'}`}</Name>
    <div>
      <Checkbox
        label="Platzhalter"
        checked={placeholder}
        onChange={handleChangePlaceholder}
      />
    </div>
    <ModuleSubscriptionPlanControl
      subscriptionPlans={subscriptionPlans}
      allSubscriptionPlans={allSubscriptionPlans}
      onSave={saveSubscriptionPlans}
    />
  </StyledCard>
)

TemplatesMicrocycleModule.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string,
  subtype: PropTypes.string,
  placeholder: PropTypes.bool.isRequired,
}

const enhance = compose(
  withTemplateTriggers,
  withHandlers({
    handleDelete: ({ refresh, id }) => async () => {
      await removeTemplateModule({ id })
      refresh()
    },
    handleChangePlaceholder: ({ refresh, id }) => async ({
      target: { checked },
    }) => {
      await saveTemplateModule({ id, placeholder: checked })
      refresh()
    },
    saveSubscriptionPlans: ({ refresh, id }) => async subscriptionPlans => {
      await saveTemplateModule({
        id,
        subscriptionPlans: subscriptionPlans.map(p => p['@id']),
      })
      refresh()
    },
  }),
)

export default enhance(TemplatesMicrocycleModule)
