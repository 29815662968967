import PropTypes from 'prop-types'
import React from 'react'

import { FormGroup, Intent, NumericInput } from '@blueprintjs/core'

const FormAdaptersNumberInputAdapter = ({ label, input, meta, t, ...rest }) => (
  <FormGroup
    intent={meta.touched && meta.error ? Intent.DANGER : Intent.PRIMARY}
    helperText={meta.touched ? meta.error : ''}
    label={label}
  >
    <NumericInput
      onValueChange={input.onChange}
      value={input.value}
      {...rest}
      intent={meta.touched && meta.error ? Intent.DANGER : Intent.PRIMARY}
    />
  </FormGroup>
)

FormAdaptersNumberInputAdapter.propTypes = {
  input: PropTypes.object.isRequired, //eslint-disable-line
  meta: PropTypes.object.isRequired, //eslint-disable-line
  label: PropTypes.string,
  t: PropTypes.func.isRequired,
}

FormAdaptersNumberInputAdapter.defaultProps = {
  label: null,
}

export default FormAdaptersNumberInputAdapter
