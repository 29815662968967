import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { branch, compose, renderNothing } from 'recompose'

const MessageContainer = ({ message: { type, message } }) => (
  <div className="grid-x align-center">
    <div className={`column shrink message ${type}`}>{message}</div>
  </div>
)

MessageContainer.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.number,
  }).isRequired,
}

const mapStateToProps = state => ({
  message: state.messages,
})

const enhance = compose(
  connect(mapStateToProps),
  branch(({ message }) => Object.keys(message).length === 0, renderNothing),
)

export default enhance(MessageContainer)
