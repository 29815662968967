import { Button, ControlGroup, ProgressBar } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { fetchModuleDatesBetween } from 'ducks/calendar'
import { saveModuleDate as fetchSaveModuleDate } from 'lib/fetch/moduleDates'
import { AppToaster } from 'lib/toaster'
import history from '../../createHistory'
import PlanDropdown from '../PlanDropdown'
import ModuleDateActions from './Actions'
import {
  removeModuleDate,
  saveModuleDate,
  toggleSubscriptionPlans,
  updateModuleDate,
} from '../../ducks/moduleDates'

export const RightAlignedControlGroup = styled(ControlGroup)`
  justify-content: flex-end;
  > * {
    flex-grow: 0 !important;
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
`

export const Spacer = styled.div`
  flex-grow: 1 !important;
`

export const Label = styled.b`
  flex-grow: 0 !important;
`

const ModuleDateControls = ({
  moduleDate,
  handleValueChange,
  handleSaveModuleDate,
  toggleExpand,
  handleRemoveModuleDate,
  expanded,
}) => {
  const dispatch = useDispatch()
  const handleSubscriptionPlans = async plans => {
    const payload = {
      id: moduleDate.id,
      subscriptionPlans: plans.map(p => p['@id']),
    }

    dispatch(toggleSubscriptionPlans(moduleDate, plans.map(p => p['@id'])))

    const toast = AppToaster.show({
      message: <ProgressBar />,
      timeout: 0,
    })

    await fetchSaveModuleDate(payload)

    AppToaster.show(
      {
        message: 'Gespeichert',
        intent: 'success',
      },
      toast,
    )

    dispatch(fetchModuleDatesBetween(moduleDate.date, moduleDate.date))
  }

  return (
    <div>
      <RightAlignedControlGroup style={{ alignItems: 'center' }} fill>
        {/* <Checkbox
          type="checkbox"
          label="Alternativ"
          name="alternative"
          checked={moduleDate.alternative}
          onChange={handleValueChange}
        />
        <Label>Option</Label>
        <InputGroup
          placeholder="Option"
          name="choice"
          style={{ width: '100px' }}
          value={moduleDate.choice || ''}
          onChange={handleValueChange}
        /> */}
        <Label>Plan</Label>
        <PlanDropdown
          moduleDate={moduleDate}
          onChange={handleSubscriptionPlans}
        />

        <Spacer />
        <Button
          onClick={() => history.push(`/module/${moduleDate.module}/edit`)}
        >
          Modul editieren
        </Button>
        <ModuleDateActions
          style={{ flexGrow: 0 }}
          dirty={moduleDate.dirty}
          handleSave={handleSaveModuleDate}
          handleExpand={toggleExpand}
          handleRemove={handleRemoveModuleDate}
          expanded={expanded}
        />
      </RightAlignedControlGroup>
    </div>
  )
}

ModuleDateControls.propTypes = {
  moduleDate: PropTypes.object.isRequired, // eslint-disable-line
  handleValueChange: PropTypes.func.isRequired,
  handleSaveModuleDate: PropTypes.func.isRequired,
  toggleExpand: PropTypes.func.isRequired,
  handleRemoveModuleDate: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleSubscriptionPlans: PropTypes.func.isRequired,
}

export default ModuleDateControls
