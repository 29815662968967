import { Button, Label } from '@blueprintjs/core'
import React from 'react'
import { Field, Form } from 'react-final-form'
import FormAdaptersNumberInputAdapter from '../UI/Form/Adapters/NumberInputAdapter'
import TextInputAdapter from '../UI/Form/Adapters/TextInputAdapter'
import GroupAssignment from './GroupAssignment'
import QuillEditor from './QuillEditor'

const GymnasticsExercise = ({ exercise, handleSave, groups }) => (
  <Form
    onSubmit={handleSave}
    initialValues={exercise}
    render={({ handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Field name="name" label="Name" component={TextInputAdapter} />
        <Field name="pluralName" label="Plural" component={TextInputAdapter} />

        <Label>
          Beschreibung
          <Field
            name="description"
            render={({ input, meta }) => (
              <QuillEditor
                key={exercise.id}
                content={input.value}
                onChange={input.onChange}
              />
            )}
          />
        </Label>

        <Field
          name="groups"
          render={({ input }) => <GroupAssignment groups={groups} {...input} />}
        />

        {/* <Field
            name="femaleScalingFactor"
            label="Frauen Skalierung"
            component={FormAdaptersNumberInputAdapter}
          /> */}
        <Button type="submit" intent="primary">
          {submitting ? 'Wird gespeichert... ' : 'Speichern'}
        </Button>
      </form>
    )}
  />
)

export default GymnasticsExercise
