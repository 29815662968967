import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'

import Dropzone from 'react-dropzone'
import { Spinner } from '@blueprintjs/core'
import { uploadFile } from '../../lib/fetchService'

const QuillEditor = ({ content, onChange }) => {
  const editorRef = useRef(null)
  const [text, setText] = useState(content)
  const [uploading, setUploading] = useState(false)

  const handleDrop = async acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setUploading(true)
      const file = acceptedFiles[0]
      const uploadedFile = await uploadFile(file)
      const editor = editorRef.current.getEditor()
      const selection = editor.getSelection()

      editor.insertEmbed(
        selection ? selection.index : 0,
        'image',
        uploadedFile.data.image,
      )
      setUploading(false)
    }
  }
  const handleChange = value => {
    onChange(value)
    setText(value)
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['video'],
      ['clean'],
    ],
  }

  return (
    <div>
      <Dropzone disableClick disablePreview style={{}} onDrop={handleDrop}>
        <ReactQuill
          ref={editorRef}
          theme="snow"
          defaultValue={text}
          onChange={handleChange}
          modules={modules}
        />
      </Dropzone>
      {uploading && <Spinner />}
    </div>
  )
}

QuillEditor.propTypes = {
  content: PropTypes.string.isRequired,
  quillContent: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

QuillEditor.defaultProps = {
  quillContent: { ops: [{ insert: '\n' }] },
}

export default QuillEditor
