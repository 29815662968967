import React from 'react'

const TemplateContext = React.createContext({ refresh: () => {} })

export function withTemplateTriggers(Component) {
  return function ComponentWithTemplateTriggers(props) {
    return (
      <TemplateContext.Consumer>
        {({ refresh }) => <Component {...props} refresh={refresh} />}
      </TemplateContext.Consumer>
    )
  }
}

export default TemplateContext
