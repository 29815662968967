import { Alert, Button } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import React from 'react'
import { Toggle } from 'react-powerplug'

const ConfirmButton = ({
  onConfirm,
  buttonProps,
  alertProps: { content, ...alertProps },
}) => (
  <Toggle>
    {({ on, toggle }) => (
      <div>
        <Button {...buttonProps} onClick={toggle} />
        <Alert
          {...alertProps}
          isOpen={on}
          onCancel={toggle}
          onConfirm={() => {
            toggle()
            onConfirm()
          }}
        >
          {content}
        </Alert>
      </div>
    )}
  </Toggle>
)

ConfirmButton.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  buttonProps: PropTypes.object.isRequired,
  alertProps: PropTypes.object.isRequired,
}

export default ConfirmButton
