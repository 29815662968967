import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import Plan from './Plan'

const PlanDayContainer = ({ date }) => {
  const moduleDates = useSelector(state =>
    state.calendar.result
      .map(id => state.calendar.entities.moduleDates[id])
      .filter(
        moduleDate => DateTime.fromISO(moduleDate.date).toISODate() === date,
      ),
  )
  return <Plan moduleDates={moduleDates} date={date} />
}

PlanDayContainer.propTypes = {
  date: PropTypes.string.isRequired,
}

export default PlanDayContainer
