import React from 'react'
import { Field } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { SearchAdapter } from './Form/Adapters'
import { required } from './Form/Validation'

const ALL_EXERCISES = gql`
  query exercises {
    exercises(pageSize: 1000) {
      exercises {
        ... on IExercise {
          id
          name
          category
          exerciseBlockCount
          discr
          pluralName
          description
          quillDescription
          createdAt
          updatedAt
          femaleScalingFactor
          groups {
            id
            name
            type
          }
        }
      }
    }
  }
`

const ExerciseInput = ({ name, validate, ...rest }) => {
  const { data, loading, error } = useQuery(ALL_EXERCISES)

  if (loading) {
    return <div>Loading</div>
  }

  const { exercises } = data?.exercises || []

  return (
    <Field name="exerciseType" subscription={{ value: true }}>
      {({ input: { value } }) => {
        const filteredExercises = exercises
        if (value) {
          filteredExercises.filter(
            exercise =>
              value === 'all' || exercise.__typename === `${value}Exercise`,
          )
        }
        const options = filteredExercises
          .map(exercise => {
            let { name } = exercise
            if (value === 'all') {
              switch (exercise.__typename) {
                case 'WeightliftingExercise':
                  name = `WL - ${name}`
                  break
                case 'GymnasticsExercise':
                  name = `G - ${name}`
                  break
                case 'CrossfitExercise':
                  name = `CF - ${name}`
                  break
                default:
                  break
              }
            }
            return { value: exercise.id, label: name }
          })
          .sort((a, b) => {
            if (a.label.toUpperCase() > b.label.toUpperCase()) return 1
            if (a.label.toUpperCase() < b.label.toUpperCase()) return -1
            return 0
          })
        return (
          <div {...rest}>
            <Field
              name={name}
              component={SearchAdapter}
              options={options}
              validate={validate}
              placeholder="Übung auswählen"
            />
          </div>
        )
      }}
    </Field>
  )
}

export default ExerciseInput
