import { Checkbox, TextArea, NumericInput, Label } from '@blueprintjs/core'
import React from 'react'
import ForPowerbuildingCheckbox from 'components/Weightlifting/ForPowerbuildingCheckbox'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { last } from 'lodash'
import WeightliftingComplexExercise from './WeightliftingComplexExercise'
import Dropdown from './Common/Dropdown'

const Row = styled.div`
  display: flex;
  margin: 0 -4px;
  & > * {
    margin: 0 4px;
  }
`

const query = gql`
  query exercise($id: ID!) {
    exercise(id: $id) {
      ... on IExercise {
        id
        name
      }
    }
  }
`

const ExerciseDisplay = ({ iri }) => {
  const id = last(iri.split('/'))

  const { data } = useQuery(query, { variables: { id } })

  return <h4>{data?.exercise.name}</h4>
}

const WeightliftingComplex = ({
  id,
  benchmarkLift,
  benchmarkReps,
  comment,
  exercises,
  lifts,
  weight,
  onChange,
  handleRepsChange,
  index,
  name,
  subscriptionPlans,
  addSet,
  removeSet,
  onSetPremium,
}) => {
  const benchmarkLifts = lifts.map(lift => ({
    value: lift['@id'],
    label: lift.name,
  }))

  return (
    <div style={{ marginTop: '20px' }}>
      <Row style={{ marginBottom: '8px' }}>
        {exercises.map(e => (
          <ExerciseDisplay iri={e.exercise} />
        ))}
      </Row>
      {exercises.map((exercise, index) => (
        <WeightliftingComplexExercise
          key={index}
          index={index}
          handleRepsChange={handleRepsChange}
          name={`${name}.exercises.${index}`}
          weight={weight}
          addSet={addSet}
          removeSet={removeSet}
          {...exercise}
        />
      ))}

      <Row>
        <Label>
          Referenz Übung
          <Dropdown
            value={benchmarkLift}
            options={benchmarkLifts}
            name=""
            onChange={({ value }) =>
              onChange({ target: { name: `${name}.benchmarkLift`, value } })
            }
          />
        </Label>
        <Label>
          Referenz Reps
          <NumericInput
            value={benchmarkReps}
            onValueChange={value =>
              onChange({ target: { name: `${name}.benchmarkReps`, value } })
            }
          />
        </Label>
      </Row>

      <ForPowerbuildingCheckbox id={id} />

      <Checkbox
        name="titan"
        label="Nur für Titan"
        checked={subscriptionPlans.find(p => p.name === 'titan')}
        onChange={() => onSetPremium(index)}
      />
      <TextArea
        onChange={onChange}
        name={`${name}.comment`}
        autoHeight
        fill
        cols="30"
        value={comment}
        rows="3"
        style={{ width: '100%' }}
      />
    </div>
  )
}

const mapStateToProps = ({ lifts }) => ({
  lifts,
})

export default connect(mapStateToProps)(WeightliftingComplex)
