import { Spinner } from '@blueprintjs/core'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import useModule from '../../lib/hooks/useModule'

const Container = styled.div`
  & > *:not(:first-child) {
    margin-left: 1em;
  }
`

const ModuleTitle = ({ id }) => {
  const module = useModule(id)
  const loading = useSelector(({ fetch }) => fetch.module > 0)
  if (loading || !module) {
    return <Spinner />
  }
  const { name } = module

  return (
    <Container>
      <span>Modul {id}</span>
      <span>{name ? ` Name: ${name}` : null}</span>
    </Container>
  )
}

export default ModuleTitle
