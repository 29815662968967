import update from 'immutability-helper'
import produce from 'immer'

import { FETCH_END, FETCH_ERROR, FETCH_START } from './fetch'
import { SET_PLAN } from './common'
import { normalize } from 'normalizr'
import { moduleDate } from '../lib/normalize'

export const SET_MODULE_DATES = 'SET_MODULE_DATES'

export const FETCH_MODULE_DATE_REQUEST = 'FETCH_MODULE_DATE_REQUEST'
export const FETCH_MODULE_DATE_SUCCESS = 'FETCH_MODULE_DATE_SUCCESS'
export const FETCH_MODULE_DATE_FAIL = 'FETCH_MODULE_DATE_FAIL'

export const CREATE_MODULE_DATE_REQUEST = 'CREATE_MODULE_DATE_REQUEST'
export const CREATE_MODULE_DATE_SUCCESS = 'CREATE_MODULE_DATE_SUCCESS'
export const CREATE_MODULE_DATE_FAIL = 'CREATE_MODULE_DATE_FAIL'

export const SAVE_MODULE_DATE_REQUEST = 'SAVE_MODULE_DATE_REQUEST'
export const SAVE_MODULE_DATE_SUCCESS = 'SAVE_MODULE_DATE_SUCCESS'
export const SAVE_MODULE_DATE_FAIL = 'SAVE_MODULE_DATE_FAIL'

export const REMOVE_MODULE_DATE_REQUEST = 'REMOVE_MODULE_DATE_REQUEST'
export const REMOVE_MODULE_DATE_SUCCESS = 'REMOVE_MODULE_DATE_SUCCESS'
export const REMOVE_MODULE_DATE_FAIL = 'REMOVE_MODULE_DATE_FAIL'

export const ADD_MODULE_DATE = 'ADD_MODULE_DATE'
export const UPDATE_MODULE_DATE = 'UPDATE_MODULE_DATE'
export const REMOVE_MODULE_DATE = 'REMOVE_MODULE_DATE'
export const TOGGLE_MODULE_DATE_SUBSCRIPTION_PLANS =
  'TOGGLE_MODULE_DATE_SUBSCRIPTION_PLANS'

export const addModuleDate = ({
  session,
  date,
  type,
  subtype,
  position,
  module,
}) => ({
  type: CREATE_MODULE_DATE_REQUEST,
  moduleDate: {
    session,
    date,
    type,
    subtype,
    position,
    module,
  },
})

export const updateModuleDate = moduleDate => ({
  type: UPDATE_MODULE_DATE,
  moduleDate,
})

export const toggleSubscriptionPlans = (moduleDate, plans) => ({
  type: TOGGLE_MODULE_DATE_SUBSCRIPTION_PLANS,
  payload: {
    moduleDate,
    plans,
  },
})

export const removeModuleDate = (moduleDate, session) => ({
  type: REMOVE_MODULE_DATE_REQUEST,
  moduleDate,
  session,
})

export const saveModuleDate = moduleDate => ({
  type: SAVE_MODULE_DATE_REQUEST,
  moduleDate,
})

export const fetchModuleDate = id => ({
  type: FETCH_MODULE_DATE_REQUEST,
  payload: { id },
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_PLAN:
      return {
        ...action.plan.entities.moduleDates,
        ...state,
      }
    case CREATE_MODULE_DATE_SUCCESS:
    case FETCH_MODULE_DATE_SUCCESS: {
      const normalized = normalize(action.moduleDate, moduleDate)
      return {
        ...state,
        ...normalized.entities.moduleDates,
      }
    }
    case SAVE_MODULE_DATE_SUCCESS:
      return update(state, {
        [action.moduleDate.id]: { $merge: { dirty: false } },
      })
    case UPDATE_MODULE_DATE:
      return produce(state, draft => {
        const md = draft[action.moduleDate.id]
        if (md) {
          draft[action.moduleDate.id] = {
            ...md,
            ...action.moduleDate,
            dirty: true,
          }
        }
      })
    case REMOVE_MODULE_DATE_SUCCESS:
      return update(state, { $unset: [action.moduleDate.id] })
    case FETCH_START: {
      if (action.moduleDate && state[action.moduleDate.id]) {
        return update(state, {
          [action.moduleDate.id]: { $merge: { fetch: true } },
        })
      }
    }
    case FETCH_ERROR: // eslint-disable-line no-fallthrough
    // falls through

    case FETCH_END: {
      if (action.moduleDate && state[action.moduleDate.id]) {
        return update(state, {
          [action.moduleDate.id]: { $merge: { fetch: false } },
        })
      }
      return state
    }
    default:
      // eslint-disable-line no-fallthrough
      return state
  }
}

export default reducer
