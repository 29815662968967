// @flow
import update from 'immutability-helper'
import { SET_MODULE } from './common'
import {
  RESORT_COMPONENT,
  UPDATE_COMPONENT,
  CREATE_COMPONENT_SUCCESS,
  REMOVE_COMPONENT_SUCCESS,
  SAVE_COMPONENT_SUCCESS,
} from './components'
import { FETCH_END, FETCH_ERROR, FETCH_START } from './fetch'

const componentType = 'ScoringModuleComponent'

export const ADD_SCORING_COMPONENT = 'ADD_SCORING_COMPONENT'

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_MODULE:
      return {
        ...state,
        ...action.plan.entities.scoringModuleComponents,
      }

    case SAVE_COMPONENT_SUCCESS:
      if (!state[action.component.id]) {
        return state
      }
      return update(state, {
        [action.component.id]: { $merge: { dirty: false } },
      })
    case CREATE_COMPONENT_SUCCESS:
      if (action.component['@type'] !== componentType) {
        return state
      }
      return update(state, {
        [action.component.id]: { $set: action.component },
      })
    case REMOVE_COMPONENT_SUCCESS:
      if (!state[action.component.id]) {
        return state
      }
      return update(state, {
        [action.component.id]: { $set: null },
      })
    case UPDATE_COMPONENT:
      if (!state[action.component.id]) {
        return state
      }
      return update(state, {
        [action.component.id]: { $merge: { ...action.component, dirty: true } },
      })
    case RESORT_COMPONENT:
      if (!state[action.component.id]) {
        return state
      }
      return update(state, {
        [action.component.id]: { $merge: { ...action.component } },
      })

    case FETCH_START: {
      if (action.component && state[action.component.id]) {
        return update(state, {
          [action.component.id]: { $merge: { fetch: true } },
        })
      }
    }
    case FETCH_ERROR: // eslint-disable-line no-fallthrough
    // falls through

    case FETCH_END: {
      if (action.component && state[action.component.id]) {
        return update(state, {
          [action.component.id]: { $merge: { fetch: false } },
        })
      }
      return state
    }
    default:
      // eslint-disable-line no-fallthrough
      return state
  }
}

export default reducer
