import { Button, ButtonGroup, Card, ControlGroup } from '@blueprintjs/core'
import { DropDownAdapter, TextFieldAdapter } from 'components/Form/Adapters'
import Row from 'components/Layout/StyledComponents/Row'
import useModuleComponent from 'lib/hooks/useModuleComponent'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useModuleActions } from '../hooks'

const ScoringModuleComponent = ({ id, dragHandle }) => {
  const component = useModuleComponent(id, 'scoring')
  const { handleRemove, handleSave } = useModuleActions(component)

  return (
    <Card>
      <Form
        onSubmit={handleSave}
        initialValues={component}
        render={({ form, values, handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            {submitError && <div className="error">{submitError}</div>}
            <Row>
              {dragHandle}
              <div style={{ flexGrow: 1 }}>
                <h3>{component.title || component['@type']}</h3>
              </div>
              <ButtonGroup>
                <Button
                  intent="success"
                  icon="floppy-disk"
                  type="submit"
                  loading={submitting}
                />
                {/* <Button
                  icon={this.state.expanded ? 'chevron-up' : 'chevron-down'}
                  onClick={this.toggleExpand}
                /> */}
                {component['@type'] !== 'WeightliftingModuleComponent' && (
                  <Button icon="trash" intent="danger" onClick={handleRemove} />
                )}
              </ButtonGroup>
            </Row>
            <ControlGroup
              style={{
                alignItems: 'flex-end',
                marginTop: '8px',
                marginBottom: '8px',
              }}
              fill
            >
              <Field
                name="comment"
                type="text"
                label="Kommentar"
                component={TextFieldAdapter}
                parse={value => (value === '' ? null : value)}
              />
              <Field
                name="scoreType"
                label="Typ"
                component={DropDownAdapter}
                options={[
                  { value: 'seconds', label: 'Sekunden' },
                  { value: 'weight', label: 'Gewicht' },
                  { value: 'reps', label: 'Wiederholungen' },
                  { value: 'distance', label: 'Distanz' },
                  { value: 'scale', label: 'scale' },
                  { value: 'boolean', label: 'boolean' },
                ]}
              />

              <Field
                name="sortOrder"
                label="Aufsteigend/Absteigend"
                component={DropDownAdapter}
                options={[
                  { value: 'DESC', label: 'Mehr ist besser' },
                  { value: 'ASC', label: 'Weniger ist besser' },
                ]}
              />
            </ControlGroup>
          </form>
        )}
      />
    </Card>
  )
}

export default ScoringModuleComponent
