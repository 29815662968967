import PropTypes from 'prop-types'
import React from 'react'

import { FormGroup, Intent, InputGroup } from '@blueprintjs/core'

const FormAdaptersTextInputAdapter = ({ label, t, input, meta, ...rest }) => (
  <FormGroup
    intent={meta.touched && meta.error ? Intent.DANGER : Intent.PRIMARY}
    helperText={meta.touched ? meta.error : ''}
    label={label}
  >
    <InputGroup
      {...input}
      {...rest}
      intent={meta.touched && meta.error ? Intent.DANGER : Intent.PRIMARY}
    />
  </FormGroup>
)

FormAdaptersTextInputAdapter.propTypes = {
  input: PropTypes.object.isRequired, //eslint-disable-line
  meta: PropTypes.object.isRequired, //eslint-disable-line
  label: PropTypes.string,
  helperText: PropTypes.string,
}

FormAdaptersTextInputAdapter.defaultProps = {
  label: null,
  helperText: null,
}

export default FormAdaptersTextInputAdapter
