import { TextFieldAdapter } from 'components/Form/Adapters'
import React from 'react'

const EditableNumberCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  handleTableUpdate, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = e => {
    setValue(Number(e.target.value))
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    handleTableUpdate(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return <TextFieldAdapter input={{ value, onChange, onBlur }} meta={{}} />
}

export default EditableNumberCell
