import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { removeModule } from '../../lib/fetch/modules'
import ModuleTemplateGrid from './ModuleTemplateGrid'
import { fetchTemplateModules } from '../../lib/fetch/templates'

class ModuleTemplateApp extends React.Component {
  state = {
    modules: [],
    loading: false,
  }

  componentWillMount() {
    this.fetchData()
  }

  fetchData = async () => {
    this.setState({ loading: true })
    const res = await fetchTemplateModules()
    this.setState({ modules: res.data['hydra:member'], loading: false })
  }

  deleteModule = async module => {
    await removeModule(module)
    this.fetchData()
  }

  render() {
    const {
      match: {
        params: { type, subtype },
      },
    } = this.props

    return (
      <ModuleTemplateGrid
        modules={this.state.modules.filter(
          module =>
            (!type || module.type === type) &&
            (!subtype || module.subtype === subtype),
        )}
        onDelete={this.deleteModule}
        loading={this.state.loading}
      />
    )
  }
}

const enhance = compose(withRouter)

export default enhance(ModuleTemplateApp)
