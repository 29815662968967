import {
  Classes,
  InputGroup,
  Checkbox,
  Intent,
  RadioGroup,
  Radio,
  NumericInput,
} from '@blueprintjs/core'
import classnames from 'classnames'
import React from 'react'
import styled from 'styled-components/macro'
import Dropdown from '../../Common/Dropdown'
import BlueprintSearch from '../../Common/BlueprintSearch'

const StyledLabel = styled.label`
  flex-grow: ${props => (props.fill ? 1 : 0)};
`

export const TextFieldAdapter = ({ label, input, meta, fill, ...rest }) => (
  <StyledLabel fill={fill}>
    {label}
    <InputGroup
      className={classnames({
        [Classes.INTENT_DANGER]: meta.touched && meta.error,
      })}
      {...input}
      {...rest}
    />
  </StyledLabel>
)

export const NumberInputAdapter = ({ label, input, meta, fill, ...rest }) => (
  <StyledLabel fill={fill}>
    {label}
    <NumericInput
      onValueChange={input.onChange}
      value={input.value}
      {...rest}
      intent={meta.touched && meta.error ? Intent.DANGER : Intent.PRIMARY}
    />
  </StyledLabel>
)

export const DropDownAdapter = ({ options, input, meta, ...rest }) => {
  const handleSelectChange = ({ value }) => input.onChange(value)
  return (
    <Dropdown
      options={options}
      value={input.value}
      onChange={handleSelectChange}
      {...rest}
    />
  )
}

export const CheckboxAdapter = ({ label, input, meta, fill, ...rest }) => (
  <StyledLabel fill={fill}>
    {label}
    <Checkbox
      style={{ display: 'block' }}
      type="checkbox"
      checked={input.value}
      onChange={input.onChange}
    />
  </StyledLabel>
)

export const InputGroupAdapter = ({ input, meta, ...rest }) => (
  <InputGroup
    className={classnames({
      [Classes.INTENT_DANGER]: meta.touched && meta.error,
    })}
    {...input}
    {...rest}
  />
)

export const SearchAdapter = ({
  input,
  options,
  meta,
  placeholder,
  ...rest
}) => {
  const handleSelectChange = ({ value }) => input.onChange(value)
  return (
    <BlueprintSearch
      className={classnames({
        [Classes.INTENT_DANGER]: meta.touched && meta.error,
      })}
      value={input.value}
      options={options}
      onChange={handleSelectChange}
      inputProps={{ placeholder }}
      {...rest}
    />
  )
}

export const RadioAdapter = ({ input, options, meta, ...rest }) => (
  <RadioGroup onChange={input.onChange} selectedValue={input.value} {...rest}>
    {options.map(({ label, value }) => (
      <Radio label={label} value={value} />
    ))}
  </RadioGroup>
)
