import { Spinner } from '@blueprintjs/core'
import { lowerFirst, sortBy } from 'lodash'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import arrayMove from 'array-move'
import {
  addComponent,
  removeComponent,
  resortModuleComponents,
} from 'ducks/components'
import { fetchModuleDate } from 'ducks/moduleDates'
import { removeModule, saveModule, updateModule } from 'ducks/modules'
import { createModuleDate } from 'lib/fetch/moduleDates'
import {
  addModuleToTemplateMicrocycle,
  copyModuleAsTemplate,
} from 'lib/fetchService'
import { AppToaster } from 'lib/toaster'
import { DragDropContext } from 'lib/dragdrop'
import AddModuleActions from '../NewModuleActions'
import Components from './Components'
import ModuleControls from './Controls'
import ModuleInfo from './Info/Info'
import { ModuleActionsContext } from './ModuleActionsContext'
import ModuleTitle from './Title'
import { ModuleContextProvider } from './ModuleContext'

const Module = ({ id, expanded: initialExpanded }) => {
  const [expanded, setExpanded] = useState(initialExpanded)
  const [reordering, setReordering] = useState(false)
  const dispatch = useDispatch()

  const { module, components } = useSelector(state => {
    const module = state.modules[id] || { components: [] }
    const components = module.components
      .map(c => ({
        ...c,
        position: state[`${lowerFirst(c.schema)}s`][c.id].position,
      }))
      .sort((a, b) => a.position - b.position)
      .map((c, index) => ({ ...c, position: index }))

    return { module, components }
  })

  const onSortEnd = event => {
    if (!event.destination) {
      return
    }
    if (event.source.index === event.destination.index) {
      return
    }

    // const sortedModuleComponents = sortBy(components, c => c.position)
    const newOrderedModuleComponents = arrayMove(
      components,
      event.source.index,
      event.destination.index,
    )

    dispatch(
      resortModuleComponents(
        newOrderedModuleComponents.map((c, i) => ({ ...c, position: i })),
        module,
      ),
    )
  }

  if (!module.id) {
    return <Spinner />
  }

  const handleValueChange = ({
    target: { name, type, checked, value: inputValue },
  }) => {
    const value = type === 'checkbox' ? checked : inputValue

    dispatch(
      updateModule({
        id: module.id,
        [name]: name === 'duration' ? value * 60 : value,
      }),
    )
  }

  const handleNumberValueChange = ({ name, value }) => {
    dispatch(
      updateModule({
        id: module.id,
        [name]: name === 'duration' ? value * 60 : value,
      }),
    )
  }

  const handleTypeSelectChange = value => {
    dispatch(
      updateModule({
        id: module.id,
        type: value.value,
      }),
    )
  }

  const handleSubTypeSelectChange = value => {
    dispatch(
      updateModule({
        id: module.id,
        subtype: value.value,
      }),
    )
  }

  const handleNewComponent = schema => {
    dispatch(addComponent(module, schema, module.components.length))
  }

  const handleSaveModule = () => {
    dispatch(saveModule(module))
  }

  const handleRemoveModule = () => {
    dispatch(removeModule(module, module.session))
  }

  const handleRemoveComponent = component => {
    dispatch(removeComponent(module, component))
  }

  const handleCopyModule = async () => {
    try {
      await copyModuleAsTemplate({ module })
      AppToaster.show({
        message: 'Modul als Vorlage gespeichert.',
        intent: 'success',
      })
    } catch (e) {
      AppToaster.show({
        message: 'Fehler beim Speichern als Vorlage.',
        intent: 'warning',
      })
    }
  }

  const handleAssignModuleToDate = async date => {
    try {
      const { data: moduleDate } = await createModuleDate({
        module: module['@id'],
        date,
      })
      AppToaster.show({
        message: 'Modul eingefügt.',
        intent: 'success',
      })

      dispatch(fetchModuleDate(moduleDate.id))
    } catch (e) {
      AppToaster.show({
        message: 'Fehler beim einfügen',
        intent: 'warning',
      })
    }
  }

  const handleDuplicateModuleToDate = async date => {
    try {
      const { data: moduleDate } = await createModuleDate({
        module: module['@id'],
        date,
        copy: true,
      })
      AppToaster.show({
        message: 'Modul eingefügt.',
        intent: 'success',
      })

      dispatch(fetchModuleDate(moduleDate.id))
    } catch (e) {
      AppToaster.show({
        message: 'Fehler beim einfügen',
        intent: 'warning',
      })
    }
  }

  const handleCopyModuleToTemplate = async ({
    templateMicrocycle,
    day,
    copy = false,
  }) => {
    try {
      await addModuleToTemplateMicrocycle({
        module: module['@id'],
        templateMicrocycle: templateMicrocycle['@id'],
        day,
        copy,
      })
      AppToaster.show({
        message: 'Modul kopiert.',
        intent: 'success',
      })
    } catch (e) {
      AppToaster.show({
        message: 'Fehler beim Kopieren',
        intent: 'warning',
      })
    }
  }

  const handleReorder = () => {
    setReordering(r => !r)
  }

  const toggleExpand = () => {
    setExpanded(e => !e)
  }

  return (
    <ModuleContextProvider module={module}>
      <DragDropContext>
        <ModuleTitle id={id} />
        <ModuleInfo id={id} />
        <ModuleActionsContext.Provider
          value={{
            handleCopyModule,
            handleAssignModuleToDate,
            handleDuplicateModuleToDate,
            handleCopyModuleToTemplate,
          }}
        >
          <ModuleControls
            moduleId={id}
            handleTypeSelectChange={handleTypeSelectChange}
            handleSubTypeSelectChange={handleSubTypeSelectChange}
            handleValueChange={handleValueChange}
            handleNumberValueChange={handleNumberValueChange}
            handleSaveModule={handleSaveModule}
            toggleExpand={toggleExpand}
            handleRemoveModule={handleRemoveModule}
            expanded={expanded}
            handleReorder={handleReorder}
            reordering={reordering}
          />
        </ModuleActionsContext.Provider>
        <div style={{ display: expanded ? 'block' : 'none' }}>
          <Components
            components={components}
            onSortEnd={onSortEnd}
            onRemoveComponent={handleRemoveComponent}
            reordering={reordering}
          />

          <AddModuleActions
            handleNewComponent={handleNewComponent}
            moduleId={module.id}
          />
        </div>
      </DragDropContext>
    </ModuleContextProvider>
  )
}

export default Module
