export default {
  types: [
    { value: 'crossfit', label: 'MetCon', shortLabel: 'CF' },
    { value: 'weightlifting', label: 'Weightlifting', shortLabel: 'WL' },
    { value: 'gymnastics', label: 'Gymnastics', shortLabel: 'G' },
    { value: 'endurance', label: 'Endurance', shortLabel: 'E' },
    { value: 'powerbuilding', label: 'Powerbuilding', shortLabel: 'PB' },
    // { value: 'strength', label: 'Strength (Do not use)', shortLabel: 'S' },
  ],
  subtypes: {
    crossfit: [
      { value: 'Preparation', label: 'Preparation' },
      { value: 'Conditioning', label: 'Conditioning' },
      { value: 'Accessory', label: 'Accessory' },
      { value: 'CoolDown', label: 'CoolDown' },
    ],
    weightlifting: [
      { value: 'Preparation', label: 'Preparation' },
      { value: 'Training', label: 'Training' },
      { value: 'Accessory', label: 'Accessory' },
      { value: 'CoolDown', label: 'CoolDown' },
    ],
    gymnastics: [
      { value: 'Preparation', label: 'Preparation' },
      { value: 'Skill', label: 'Skill' },
      { value: 'Strength', label: 'Strength' },
      { value: 'Training', label: 'Training' },
      { value: 'CoolDown', label: 'CoolDown' },
    ],
    endurance: [
      { value: 'Preparation', label: 'Preparation' },
      { value: 'TrackPrep', label: 'TrackPrep' },
      { value: 'SwimPrep', label: 'SwimPrep' },
      { value: 'Training', label: 'Training' },
      { value: 'LTE', label: 'LTE' },
      { value: 'MTE', label: 'MTE' },
      { value: 'STE', label: 'STE' },
      { value: 'Sprint', label: 'Sprint' },
      { value: 'CoolDown', label: 'CoolDown' },
    ],
    powerbuilding: [
      {
        value: 'Training',
        label: 'Training',
      },
    ],
    strength: [
      {
        value: 'Training',
        label: 'Training (Do not use)',
      },
    ],
  },
}
