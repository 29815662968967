import React, { useEffect } from 'react'
import {
  DragDropContext as RealDragDropContext,
  Droppable as RealDroppable,
  Draggable,
} from 'react-beautiful-dnd'

const DroppableManager = {
  _onDragEndLookup: {},

  register(droppableId, onDragEnd) {
    assert(
      !(droppableId in this._onDragEndLookup),
      `droppableId="${droppableId}" has already been used. droppableIds should be unique.`,
    )
    this._onDragEndLookup[droppableId] = onDragEnd
  },

  unregister(droppableId) {
    delete this._onDragEndLookup[droppableId]
  },

  getOnDragEnd(droppableId) {
    return this._onDragEndLookup[droppableId]
  },
}

const DragDropContext = props => {
  const globalOnDragEnd = result => {
    const { type, source, destination } = result
    if (!destination) {
      return
    }
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return
    }

    if (source.droppableId !== destination.droppableId) {
      const sourceOnDragEnd = DroppableManager.getOnDragEnd(source.droppableId)
      sourceOnDragEnd(result)
    }

    const destinationOnDragEnd = DroppableManager.getOnDragEnd(
      destination.droppableId,
    )
    destinationOnDragEnd(result)

    if (props.onDragEnd) {
      props.onDragEnd(result)
    }
  }
  return (
    <RealDragDropContext onDragEnd={globalOnDragEnd}>
      {props.children}
    </RealDragDropContext>
  )
}

function assert(condition, message) {
  if (!condition) {
    throw new Error(message || 'Assertion failed')
  }
}

const Droppable = ({ children, ...props }) => {
  const { droppableId, onDragEnd } = props
  useEffect(() => {
    assert(
      onDragEnd,
      `Droppable with droppableId="${droppableId}"" is missing 'onDragEnd' hook.`,
    )
    DroppableManager.register(droppableId, onDragEnd)
    return () => DroppableManager.unregister(droppableId)
  }, [droppableId, onDragEnd])
  return <RealDroppable {...props}>{children}</RealDroppable>
}

export { Droppable, DragDropContext, Draggable }
