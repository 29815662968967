import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { FormGroup, HTMLSelect } from '@blueprintjs/core'
import { updateComponent } from 'ducks/components'
import { Colors } from 'lib/Colors'
import useModuleComponent from 'lib/hooks/useModuleComponent'
import LiftSearch from 'components/Lift/LiftSearch'
import ModuleComponent from './ModuleComponent'
import StrengthPlanSearch from 'components/StrengthPlan/StrengthPlanSearch'
import Row from 'components/Layout/StyledComponents/Row'

export const StrengthModuleComponent = ({ id }) => {
  const component = useModuleComponent(id, 'strength')
  const dispatch = useDispatch()
  return (
    <ModuleComponent title="Strength" component={component} color={Colors.REST}>
      <Row>
        <FormGroup label="Plan">
          <StrengthPlanSearch
            plan={{ '@id': component.plan }}
            onChange={value =>
              dispatch(
                updateComponent({
                  id: component.id,
                  plan: get(value, '@id'),
                }),
              )
            }
          />
        </FormGroup>
        <FormGroup label="Lift">
          <LiftSearch
            lift={{ '@id': component.lift || undefined }}
            onChange={value =>
              dispatch(
                updateComponent({
                  id: component.id,
                  lift: get(value, '@id'),
                }),
              )
            }
          />
        </FormGroup>
        <FormGroup label="Eigener Lift">
          <HTMLSelect
            name="customLift"
            value={component.customLift}
            onChange={({ target: { value } }) =>
              dispatch(
                updateComponent({
                  id: component.id,
                  customLift: parseInt(value, 10),
                }),
              )
            }
          >
            {[
              { value: -1, label: 'Nein' },
              { value: 0, label: 'Erster' },
              { value: 1, label: 'Zweiter' },
              { value: 2, label: 'Dritter' },
            ].map(type => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </HTMLSelect>
        </FormGroup>
      </Row>
    </ModuleComponent>
  )
}

StrengthModuleComponent.propTypes = {
  id: PropTypes.number.isRequired,
}

export default StrengthModuleComponent
