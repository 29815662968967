// @flow

export const TRIGGER_COMPONENT_UPDATE = 'TRIGGER_COMPONENT_UPDATE'

export const CREATE_COMPONENT_REQUEST = 'CREATE_COMPONENT_REQUEST'
export const CREATE_COMPONENT_SUCCESS = 'CREATE_COMPONENT_SUCCESS'
export const CREATE_COMPONENT_FAIL = 'CREATE_COMPONENT_FAIL'

export const REMOVE_COMPONENT_REQUEST = 'REMOVE_COMPONENT_REQUEST'
export const REMOVE_COMPONENT_SUCCESS = 'REMOVE_COMPONENT_SUCCESS'
export const REMOVE_COMPONENT_FAIL = 'REMOVE_COMPONENT_FAIL'

export const SAVE_COMPONENT_REQUEST = 'SAVE_COMPONENT_REQUEST'
export const SAVE_COMPONENT_SUCCESS = 'SAVE_COMPONENT_SUCCESS'
export const SAVE_COMPONENT_FAIL = 'SAVE_COMPONENT_FAIL'

export const RESORT_COMPONENT_REQUEST = 'RESORT_COMPONENT_REQUEST'
export const RESORT_COMPONENT_SUCCESS = 'RESORT_COMPONENT_SUCCESS'
export const RESORT_COMPONENT_FAIL = 'RESORT_COMPONENT_FAIL'

export const UPDATE_COMPONENT = 'UPDATE_COMPONENT'
export const RESORT_COMPONENT = 'RESORT_COMPONENT'

export const addComponent = (module, componentType, position) => {
  return {
    type: CREATE_COMPONENT_REQUEST,
    module,
    componentType,
    position,
  }
}

export const removeComponent = (module, component) => ({
  type: REMOVE_COMPONENT_REQUEST,
  module,
  component,
})

export const throttledUpdateComponent = component => ({
  type: TRIGGER_COMPONENT_UPDATE,
  component,
})

export const updateComponent = component => ({
  type: UPDATE_COMPONENT,
  component,
})

export const resortModuleComponents = (components, module) => ({
  type: RESORT_COMPONENT_REQUEST,
  components,
  module,
})

export const saveComponentToServer = component => ({
  type: SAVE_COMPONENT_REQUEST,
  component,
})
