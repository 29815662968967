import { intersection } from 'lodash'
import { DateTime } from 'luxon'

export const isSameDay = (date, comp) =>
  DateTime.fromISO(date).hasSame(DateTime.fromISO(comp), 'day')

export const filterModuleDatesForSubscription = (moduleDates, state, day) => {
  const {
    subscriptionPlans: { plans, showAll },
  } = state
  if (showAll) {
    return moduleDates
  }

  return moduleDates.filter(moduleDate => {
    const modulePlans = moduleDate.subscriptionPlans.map(p => p.id)
    const activePlans = plans.filter(p => p.active).map(p => p.id)
    const intersect = intersection(modulePlans, activePlans).length > 0
    return intersect
  })
}

export const addSession = (week, day) => {
  const dayPlan = week[day]
  return [
    ...week.splice(0, day),
    {
      sessions: dayPlan.sessions.concat({}),
    },
    ...week.splice(day + 1, week.length),
  ]
}

export const lowerCaseFirstLetter = string =>
  string.charAt(0).toLowerCase() + string.slice(1)

export const generateId = () => Math.floor(Math.random() * -(100000 + 1000000))
