import { branch, renderComponent } from 'recompose'
import CenteredSpinner from '../CenteredSpinner'

export const withSpinnerWhileCondition = condition =>
  branch(condition, renderComponent(CenteredSpinner))

export const withSpinnerWhileLoading = withSpinnerWhileCondition(
  ({ loading }) => loading,
)

export default withSpinnerWhileLoading
