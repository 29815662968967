import { branch, compose, renderComponent, renderNothing } from 'recompose'
import { withContext } from './StrengthPlanContext'
import StrengthPlanEditMesocycle from './StrengthPlanEditMesocycle'
import StrengthPlanEditMicrocycle from './StrengthPlanEditMicrocycle'

const StrengthPlanEditDetail = () => null

const enhance = compose(
  withContext,
  branch(({ selected }) => !selected, renderNothing),
  branch(
    ({ selected: { type } }) => type === 'microcycle',
    renderComponent(StrengthPlanEditMicrocycle),
  ),
  branch(
    ({ selected: { type } }) => type === 'mesocycle',
    renderComponent(StrengthPlanEditMesocycle),
  ),
)

export default enhance(StrengthPlanEditDetail)
