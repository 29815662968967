import React from 'react'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Checkbox } from '@blueprintjs/core'

const query = gql`
  query weightliftingComplex($id: ID!) {
    weightliftingComplex(id: $id) {
      id
      forPowerbuilding
    }
  }
`

const mutation = gql`
  mutation updateWeightliftingComplex($data: UpdateWeightliftingComplexInput!) {
    updateWeightliftingComplex(data: $data) {
      id
    }
  }
`

const ForPowerbuildingCheckbox = ({ id }) => {
  const a = 'b'
  const { data, error, loading } = useQuery(query, { variables: { id } })
  const [mutate] = useMutation(mutation, {
    refetchQueries: ['weightliftingComplex'],
  })

  if (loading || error) {
    return <div>Wird geladen</div>
  }

  const handleChange = async ({ target: { checked } }) => {
    await mutate({ variables: { data: { id, forPowerbuilding: checked } } })
  }

  return (
    <div>
      <Checkbox
        label="Für Powerbuilding"
        checked={data.weightliftingComplex.forPowerbuilding}
        onChange={handleChange}
      />
    </div>
  )
}

export default ForPowerbuildingCheckbox
