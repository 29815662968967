import { useQuery } from '@apollo/react-hooks'
import { ProgressBar } from '@blueprintjs/core'
import { gql } from 'apollo-boost'
import PowerbuildingAddExerciseDialog from 'components/Powerbuilding/AddExerciseDialog'
import PowerbuildingDay from 'components/Powerbuilding/Day'
import React from 'react'
import { useParams } from 'react-router-dom'
import { sortBy } from 'lodash'

const query = gql`
  query powerbuildingCycle($id: ID!) {
    powerbuildingCycle(id: $id) {
      id
      slug
      exercises {
        id
        week
        day
        exerciseOfWeightliftingModule
        exercise {
          ... on IExercise {
            id
            name
          }
        }
        benchmark {
          id
        }
        preModifier
        postModifier
        identifier
        sets {
          reps
        }
        repsInReserve
        comment
        tempo

        strengthPlan {
          id
          name
        }

        createdAt
        updatedAt
      }
    }
  }
`

const PowerbuildingCycle = () => {
  const { id } = useParams()

  const { data, error, loading } = useQuery(query, { variables: { id } })

  if (error) {
    return <pre>{JSON.stringify({ error }, null, 2)}</pre>
  }

  const powerbuildingCycle = data?.powerbuildingCycle || { exercises: [] }

  const weeks = {}

  powerbuildingCycle.exercises.forEach(({ day, week }) => {
    if (weeks[week]) {
      if (!weeks[week].includes(day)) {
        weeks[week].push(day)
      }
    } else {
      weeks[week] = [day]
    }
  })

  return (
    <div>
      <div>Cycle: {powerbuildingCycle.slug}</div>
      {Object.keys(weeks)
        .map(week => Number(week))
        .sort((a, b) => a - b)
        .flatMap(week =>
          weeks[week].sort().map(day => (
            <PowerbuildingDay
              key={`${week}-${day}`}
              week={week}
              day={day}
              cycleId={id}
              exercises={sortBy(
                powerbuildingCycle.exercises.filter(
                  exercise => exercise.week === week && exercise.day === day,
                ),
                'identifier',
              )}
            />
          )),
        )}
      <PowerbuildingAddExerciseDialog cycleId={id} />
    </div>
  )
}

export default PowerbuildingCycle
