import React from 'react'
import { compose, withProps } from 'recompose'
import {
  groupBy,
  includes,
  map,
  partial,
  reduce,
  round as roundClassic,
} from 'lodash'
import { flow, mean } from 'lodash/fp'
import { connect } from 'react-redux'
import { setDetailedComplex } from '../../../ducks/evaluation'

const roundTo = p => partial(roundClassic, partial.placeholder, p)

const average = flow(mean, roundTo(2))

const TextModuleComponent = ({ component: { complexes }, logs }) => (
  <div>
    {complexes.map(c => <Complex key={c.id} complex={c} logs={logs} />)}
  </div>
)

export const calculateTotal = sets =>
  reduce(sets, (prev, set) => prev + Number(set.reps) * Number(set.weight), 0)

const calculateLogTotal = logs =>
  logs.reduce(
    (prev, log) => prev + log.reps * (log.weight / log.reference * 100),
    0,
  )

const filterLogs = (logs, sets) => {
  const setIds = sets.map(s => s.id)
  return logs.filter(l => includes(setIds, l.set))
}

const Complex = ({ complex: { id, exercises, benchmarkExercise }, logs }) => (
  <div
    className="grid-container"
    style={{ marginTop: '16px', borderBottom: '1px solid rgba(0, 0, 0, 0.37)' }}
  >
    <div>
      <div className="grid-x grid-margin-x">
        <div className="cell auto">
          {exercises.map(e => e.exercise.name).join(' & ')}
        </div>
        <div className="cell auto">
          <span style={setEstimatedSetStyle}>({benchmarkExercise})</span>
        </div>
      </div>
      {exercises.map(exercise => (
        <Exercise
          complex={{ id }}
          key={exercise.id}
          exercise={exercise}
          benchmarkExercise={benchmarkExercise}
          logs={logs}
        />
      ))}
    </div>
  </div>
)

const enhanceExercise = compose(
  connect(
    ({ evaluation: { detail: { complex } } }) => ({ detailedComplex: complex }),
    { setDetailedComplex },
  ),
  withProps(({ exercise: { sets }, logs }) => ({
    filteredLogs: filterLogs(logs, sets),
    estimate: calculateTotal(sets),
    actual: average(
      map(groupBy(filterLogs(logs, sets), 'athleteDetails'), logs =>
        calculateLogTotal(logs),
      ),
    ),
  })),
)

const Exercise = enhanceExercise(
  ({
    complex: { id },
    exercise: { exercise, sets },
    logs,
    benchmarkExercise,
    estimate,
    actual,
    detailedComplex,
    setDetailedComplex,
  }) => (
    <div className="div">
      <div className="grid-x grid-margin-x">
        <div className="cell small-3">
          Soll: <b>{estimate}</b>
        </div>
        <div className="cell small-3">
          Ist: <b>{actual}</b>
        </div>
        <div className="cell small-3">
          Diff: <b>{roundClassic(actual - estimate, 2)}</b>
        </div>
        <div className="cell-small-3">
          <button
            className="button tiny"
            onClick={() => setDetailedComplex({ id: id })}
          >
            Sätze anzeigen
          </button>
        </div>
      </div>
      {detailedComplex === id && (
        <div className="grid-x grid-margin-x">
          {sets.map(s => <Set key={s.id} {...s} logs={logs} />)}
          <div className="cell shrink">
            <button
              className="button tiny"
              onClick={() => setDetailedComplex({ id: null })}
            >
              ausblenden
            </button>
          </div>
        </div>
      )}
    </div>
  ),
)

const enhance = compose(
  withProps(({ id, logs }) => ({
    filteredLogs: logs.filter(l => l.set === id),
  })),
)

const setEstimatedSetStyle = {
  fontSize: '12px',
  fontStyle: 'italic',
  color: 'rgba(0, 0, 0, 0.57)',
}
const setStyle = { fontSize: '12px' }

const Set = enhance(({ id, reps, weight, logs, filteredLogs }) => (
  <div className="cell shrink">
    <div>
      <span style={setEstimatedSetStyle}>
        {reps}x{weight}
      </span>
    </div>
    <div>
      <span style={setStyle}>
        {average(filteredLogs.map(l => l.reps))}x{average(
          filteredLogs.map(l => l.weight / l.reference * 100),
        )}
      </span>
    </div>
  </div>
))

export default TextModuleComponent
