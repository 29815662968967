import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { SearchAdapter } from 'components/Form/Adapters'

const ALL_EXERCISES = gql`
  query exercises {
    exercises(pageSize: 1000) {
      exercises {
        ... on IExercise {
          id
          name
          category
          exerciseBlockCount
          discr
          pluralName
          description
          quillDescription
          createdAt
          updatedAt
          femaleScalingFactor
          groups {
            id
            name
            type
          }
        }
      }
    }
  }
`
const EditableExerciseCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  handleExerciseUpdate, // This is a custom function that we supplied to our table instance
}) => {
  const { data, loading, error } = useQuery(ALL_EXERCISES)

  const options = useMemo(() => {
    const exercises = data?.exercises?.exercises || []

    return exercises
      .map(exercise => {
        let { name } = exercise
        switch (exercise.__typename) {
          case 'WeightliftingExercise':
            name = `WL - ${name}`
            break
          case 'GymnasticsExercise':
            name = `G - ${name}`
            break
          case 'CrossfitExercise':
            name = `CF - ${name}`
            break
          default:
            break
        }
        return { value: exercise.id, label: name }
      })
      .sort((a, b) => {
        if (a.label.toUpperCase() > b.label.toUpperCase()) return 1
        if (a.label.toUpperCase() < b.label.toUpperCase()) return -1
        return 0
      })
  }, [data])

  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = value => {
    const option = data?.exercises?.exercises.find(o => o.id === value)
    console.log(index, id, option)
    handleExerciseUpdate(index, id, option)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  if (loading) {
    return <div>Loading</div>
  }

  return (
    <div style={{ width: '300px' }}>
      <SearchAdapter
        style={{ width: '300px' }}
        name="exerciseId"
        options={options}
        input={{ value, onChange }}
        meta={{}}
        placeholder="Movement"
      />
    </div>
  )
}

export default EditableExerciseCell
