import { Button, ButtonGroup, Card, Dialog } from '@blueprintjs/core'
import React from 'react'
import { Toggle } from 'react-powerplug'
import styled from 'styled-components'
import ModuleDeleteButton from '../Common/ModuleDeleteButton'
import ModuleEditButton from '../Common/ModuleEditButton'
import ViewModuleContainer from '../ModuleApp/ViewModuleContainer'
import { DateTime } from 'luxon'

const Subtitle = styled.div`
  margin-top: -20px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.37);
`

const ModuleTemplate = ({
  id,
  type,
  subtype,
  onDelete,
  name,
  createdAt,
  updatedAt,
}) => (
  <Card interactive>
    <h3>
      {type} / {subtype}
    </h3>

    <Subtitle>
      <div>ID: {id}</div>
      <div>{name || 'kein Name'}</div>
      <div>
        Erstellt:{' '}
        {DateTime.fromISO(createdAt).toLocaleString(DateTime.DATETIME_SHORT)}
      </div>
      <div>
        Geändert:{' '}
        {DateTime.fromISO(updatedAt).toLocaleString(DateTime.DATETIME_SHORT)}
      </div>
    </Subtitle>

    <ButtonGroup>
      <Toggle initial={false}>
        {({ on, toggle }) => (
          <div>
            <Dialog
              style={{ minWidth: '500px', width: 'auto' }}
              isOpen={on}
              onClose={toggle}
            >
              <ViewModuleContainer id={id} />
            </Dialog>
            <Button icon="eye-open" onClick={toggle} />
          </div>
        )}
      </Toggle>

      <ModuleEditButton id={id} />
      <ModuleDeleteButton onDelete={onDelete} />
    </ButtonGroup>
  </Card>
)

export default ModuleTemplate
