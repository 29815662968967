import React from 'react'
import {
  Popover,
  Menu,
  Position,
  Button,
  MenuItem,
  Spinner,
} from '@blueprintjs/core'
import { DateTime } from 'luxon'
import { fetchModuleInfo } from '../../../lib/fetch/modules'
import dateFormats from '../../../lib/dateFormats'

class ModuleInfoDates extends React.Component {
  state = {
    dates: [],
    loading: true,
  }

  handleOpen = async () => {
    this.setState({ loading: true })
    const res = await fetchModuleInfo(this.props.id)
    this.setState({
      loading: false,
      dates: res.data.dates.map(date => date.date),
    })
  }

  render() {
    return (
      <Popover
        onOpening={this.handleOpen}
        content={
          <Menu>
            {this.state.loading ? (
              <MenuItem text={<Spinner size={20} />} />
            ) : (
              this.state.dates.map(date => (
                <MenuItem
                  key={date}
                  text={DateTime.fromISO(date).toLocaleString({
                    ...dateFormats.DATE_FULL,
                    weekday: 'short',
                  })}
                  href={`/plan/${DateTime.fromISO(date).toLocaleString(
                    dateFormats.DATE_FULL,
                  )}`}
                  label={`KW ${DateTime.fromISO(date).toFormat('WW')}`}
                />
              ))
            )}
          </Menu>
        }
        position={Position.RIGHT}
      >
        <Button minimal icon="calendar" text="Daten..." />
      </Popover>
    )
  }
}

export default ModuleInfoDates
