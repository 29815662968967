import React from 'react'
import { Field } from 'react-final-form'
import { useSelector } from 'react-redux'
import { SearchAdapter } from './Form/Adapters'
import { required } from './Form/Validation'

const ExerciseInput = ({ name, ...rest }) => {
  const exercises = useSelector(({ exercises }) => exercises || [])

  return (
    <Field name="exerciseType" subscription={{ value: true }}>
      {({ input: { value } }) => {
        const options = exercises
          .filter(
            exercise =>
              value === 'all' || exercise['@type'] === `${value}Exercise`,
          )
          .map(exercise => {
            let { name } = exercise
            if (value === 'all') {
              switch (exercise['@type']) {
                case 'WeightliftingExercise':
                  name = `WL - ${name}`
                  break
                case 'GymnasticsExercise':
                  name = `G - ${name}`
                  break
                case 'CrossfitExercise':
                  name = `CF - ${name}`
                  break
                default:
                  break
              }
            }
            return { value: exercise['@id'], label: name }
          })
          .sort((a, b) => {
            if (a.label.toUpperCase() > b.label.toUpperCase()) return 1
            if (a.label.toUpperCase() < b.label.toUpperCase()) return -1
            return 0
          })
        return (
          <div {...rest}>
            <Field
              name={name}
              component={SearchAdapter}
              options={options}
              validate={required}
              placeholder="Übung auswählen"
            />
          </div>
        )
      }}
    </Field>
  )
}

export default ExerciseInput
