import { SET_ENTRYPOINTS } from './common'

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ENTRYPOINTS:
      const { entryPoints } = action
      return {
        ...state,
        entryPoints,
      }
    default:
      return state
  }
}

export default reducer
