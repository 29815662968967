import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { groupBy, map } from 'lodash'
import {
  FormGroup,
  InputGroup,
  Button,
  Popover,
  PopoverInteractionKind,
  Position,
  Intent,
} from '@blueprintjs/core'
import { useToggle } from 'react-use'

const GroupAssignment = ({ groups, value, onChange }) => {
  const [allGroups, setAllGroups] = useState(groups)
  const handleGroupAssignmentChange = group => {
    const idx = value.some(({ name }) => name === group.name)
    if (idx) {
      onChange(value.filter(({ name }) => name !== group.name))
    } else {
      onChange(value.concat(group))
    }
  }

  const [newGroup, setNewGroup] = useState({
    type: '',
    name: '',
  })

  const handleNewGroupClick = () => {
    if (
      !allGroups.find(g => g.type === newGroup.type && g.name === newGroup.name)
    ) {
      setAllGroups(groups => groups.concat(newGroup))
    }
    if (
      !value.find(g => g.type === newGroup.type && g.name === newGroup.name)
    ) {
      onChange(value.concat(newGroup))
    }
    setNewGroup({ type: '', name: '' })
  }

  return (
    <div className="medium-12 cell">
      <h4>Kategorisierung</h4>
      {map(groupBy(allGroups, 'type'), (groups, type) => (
        <>
          <h5>{type}</h5>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              margin: '0 -1em',
            }}
          >
            {groups.map(g => (
              <div key={`${g.type}-${g.name}`} style={{ margin: '0 1em' }}>
                <label>
                  <input
                    style={{ margin: 0 }}
                    type="checkbox"
                    checked={value.find(({ name }) => name === g.name || false)}
                    onChange={() => handleGroupAssignmentChange(g)}
                  />
                  <span style={{ marginLeft: '1em' }}>{g.name}</span>
                </label>
              </div>
            ))}
          </div>
        </>
      ))}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ margin: '8px 0' }}>
          <Popover
            interactionKind={PopoverInteractionKind.CLICK}
            popoverClassName="bp3-popover-content-sizing"
            position={Position.TOP}
            content={
              <div>
                <h5>Neue Kategorie hinzufügen</h5>
                <FormGroup label="Gruppe">
                  <InputGroup
                    value={newGroup.type}
                    onChange={({ target: { value } }) =>
                      setNewGroup(existing => ({ ...existing, type: value }))
                    }
                  />
                </FormGroup>
                <FormGroup label="Ausprägung">
                  <InputGroup
                    value={newGroup.name}
                    onChange={({ target: { value } }) =>
                      setNewGroup(existing => ({ ...existing, name: value }))
                    }
                  />
                </FormGroup>
                <Button
                  className="bp3-popover-dismiss"
                  onClick={handleNewGroupClick}
                  disabled={!(newGroup.name && newGroup.type)}
                >
                  Hinzufügen
                </Button>
                <Button className="bp3-popover-dismiss">Abbrechen</Button>
              </div>
            }
          >
            <Button intent={Intent.PRIMARY}>Neue Kategorie</Button>
          </Popover>
        </div>
      </div>
    </div>
  )
}

GroupAssignment.propTypes = {
  groups: PropTypes.array.isRequired,
  exercise: PropTypes.object.isRequired,
  handleGroupAssignmentChange: PropTypes.func.isRequired,
}

export default GroupAssignment
