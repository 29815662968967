import { Button } from '@blueprintjs/core'
import { TextFieldAdapter } from 'components/Form/Adapters'
import React from 'react'

const ActionCell = ({
  value: initialValue,
  row,
  column: { id },
  handleRowSave, // This is a custom function that we supplied to our table instance
  handleRowDelete,
}) => {
  const handleClick = () => {
    handleRowSave(row.original)
  }
  const handleDeleteClick = () => {
    handleRowDelete(row.original.id)
  }

  return (
    <>
      {row.original.dirty && (
        <Button intent="primary" onClick={handleClick}>
          Speichern
        </Button>
      )}
      <Button intent="warning" minimal onClick={handleDeleteClick}>
        Löschen
      </Button>
    </>
  )
}

export default ActionCell
