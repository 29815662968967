import {
  Alignment,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
} from '@blueprintjs/core'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import Spinner from 'react-spinkit'
import { compose } from 'recompose'

import { authState } from 'lib/atoms/authState'
import { useSetRecoilState } from 'recoil'

import { AUTH_TOKEN } from 'lib/constants'

const enhance = compose(
  connect(({ fetch: { total } }) => ({ fetching: total > 0 })),
  withRouter,
)
const NavbarLink = props => (
  <Link className="bp3-button bp3-minimal" {...props} />
)

const LogoutButton = () => {
  const setAuthToken = useSetRecoilState(authState)
  return (
    <div
      className="bp3-button bp3-minimal"
      onClick={() => {
        localStorage.removeItem(AUTH_TOKEN)
        setAuthToken({ token: null })
      }}
    >
      Ausloggen
    </div>
  )
}

const Menu = enhance(({ fetching }) => (
  <Navbar style={{ marginBottom: '20px' }} className="bp3-dark">
    <NavbarGroup align={Alignment.LEFT}>
      <NavbarHeading>The Forge Concept</NavbarHeading>
      <NavbarDivider />
      <NavbarLink to="/plan">Trainingsplan</NavbarLink>
      <NavbarLink to="/templates">Templates</NavbarLink>
      <NavbarLink to="/powerbuilding/table/3">
        Powerbuilding (Wendler)
      </NavbarLink>
      <NavbarLink to="/powerbuilding/table/4">
        Powerbuilding (Nippard)
      </NavbarLink>
      <NavbarLink to="/powerbuilding/table/5">
        Powerbuilding (2022 April)
      </NavbarLink>
      {/* <NavbarLink to="/strengthplans">Strength Cycle</NavbarLink> */}
      <NavbarLink to="/exercises">Übungen</NavbarLink>
      {/* <NavbarLink to="/exercise-categorize">Übungen kategorisieren</NavbarLink> */}
      <NavbarLink to="/weightlifting">Weightlifting Import</NavbarLink>
      {/* <NavbarLink to="/evaluation/weightlifting">
        Weightlifting Evaluation
      </NavbarLink> */}
      <NavbarLink to="/users">Benutzer</NavbarLink>
      <NavbarLink to="/videos">Videos</NavbarLink>
      <LogoutButton />
      {/* <NavbarLink to="/blog">Blog</NavbarLink> */}
      <div className="right menu">
        {fetching ? (
          <Spinner
            style={{ width: '15px', height: '15px', marginTop: '10px' }}
            name="folding-cube"
            color="#1779ba"
          />
        ) : (
          <div style={{ width: '15px', height: '15px', marginTop: '10px' }} />
        )}
      </div>
    </NavbarGroup>
  </Navbar>
))

export default Menu
