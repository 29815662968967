import React from 'react'
import ModuleComponentEditFields from './ModuleComponentEditFields'

const ModuleComponentEdit = props => (
  <div>
    <ModuleComponentEditFields {...props} />
    {props.children}
  </div>
)

export default ModuleComponentEdit
