import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { dispatchFetchError, FETCH_END, FETCH_START } from '../ducks/fetch'
import { createPost, fetchBlogPosts, updatePost } from '../lib/fetchService'

import {
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  CREATE_POST_FAIL,
  SAVE_POST_REQUEST,
  SAVE_POST_SUCCESS,
  SAVE_POST_FAIL,
  FETCH_POSTS_REQUEST,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAIL,
} from '../ducks/posts'

function* createPostRequest({ post }) {
  try {
    yield put({ type: FETCH_START })
    const { data: savedPost } = yield call(createPost, post)
    yield put({ type: CREATE_POST_SUCCESS, savedPost })
    yield put({ type: FETCH_END, post })
  } catch (error) {
    yield put({ type: CREATE_POST_FAIL, message: error.message })
    yield put(dispatchFetchError({ endpoint: 'post', error, post }))
  }
}

export function* createPostSaga() {
  yield takeEvery(CREATE_POST_REQUEST, createPostRequest)
}

function* savePostRequest({ post }) {
  try {
    yield put({ type: FETCH_START })
    yield call(updatePost, post)
    yield put({ type: SAVE_POST_SUCCESS, post })
    yield put({ type: FETCH_END })
  } catch (error) {
    yield put({ type: SAVE_POST_FAIL, message: error.message })
    yield put(dispatchFetchError({ endpoint: 'post', error, post }))
  }
}

export function* saveModuleSaga() {
  yield takeLatest(SAVE_POST_REQUEST, savePostRequest)
}

function* fetchPostsRequest() {
  try {
    yield put({ type: FETCH_START })
    const { data: posts } = yield call(fetchBlogPosts)
    yield put({ type: FETCH_POSTS_SUCCESS, posts: posts['hydra:member'] })
    yield put({ type: FETCH_END })
  } catch (error) {
    yield put({ type: FETCH_POSTS_FAIL, message: error.message })
    yield put(dispatchFetchError({ endpoint: 'post', error }))
  }
}

export function* fetchPostsSaga() {
  yield takeEvery(FETCH_POSTS_REQUEST, fetchPostsRequest)
}
