import React from 'react'
import Module from './Module'
import { Draggable } from 'react-beautiful-dnd'

const ModuleList = ({ modules, subscriptionPlans, session }) =>
  modules
    .filter(m => m.session === session)
    .sort((a, b) => a.position - b.position)
    .map((m, index) => (
      <Draggable
        key={m.id}
        draggableId={JSON.stringify({ id: m.id })}
        index={index}
      >
        {(provided, snapshot) => (
          <Module
            {...m}
            allSubscriptionPlans={subscriptionPlans}
            // snapshot={snapshot}
            provided={provided}
          />
        )}
      </Draggable>
    ))

export default ModuleList
