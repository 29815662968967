import { combineReducers } from 'redux'
import calendar from './ducks/calendar'
import config from './ducks/config'
import emomModuleComponents from './ducks/emomModuleComponents'
import evaluation from './ducks/evaluation'
import exercises from './ducks/exercises'
import fetch from './ducks/fetch'
import messages from './ducks/messages'
import modules from './ducks/modules'
import posts from './ducks/posts'
import restModuleComponents from './ducks/restModuleComponents'
import powerbuildingModuleComponents from './ducks/powerbuildingModuleComponents'
import strengthModuleComponents from './ducks/strengthModuleComponents'
import roundModuleComponents from './ducks/roundModuleComponents'
import scoringModuleComponents from './ducks/scoringModuleComponents'
import subscriptionPlans from './ducks/subscriptionPlans'
import lifts from './ducks/lifts'

import textModuleComponents from './ducks/textModuleComponents'
import users from './ducks/users'
import weightliftingModuleComponents from './ducks/weightliftingModuleComponents'
import moduleDates from './ducks/moduleDates'

export const appReducer = combineReducers({
  fetch,
  modules,
  roundModuleComponents,
  emomModuleComponents,
  restModuleComponents,
  powerbuildingModuleComponents,
  strengthModuleComponents,
  weightliftingModuleComponents,
  scoringModuleComponents,
  textModuleComponents,
  exercises,
  messages,
  calendar,
  config,
  subscriptionPlans,
  posts,
  evaluation,
  users,
  moduleDates,
  lifts,
})

export default appReducer
