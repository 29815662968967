import React from 'react'
import { lifecycle, compose, renderComponent, branch } from 'recompose'
import { map } from 'lodash'
import { connect } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'

import { Spinner } from '@blueprintjs/core'
import PostEdit from './PostEdit'
import PostCreate from './PostCreate'
import { fetchPosts } from '../../ducks/posts'

export const withSpinnerWhileLoading = branch(
  ({ fetch }) => fetch > 0,
  renderComponent(Spinner),
)

const withPosts = lifecycle({
  async componentWillMount() {
    this.props.fetchPosts()
  },
})

const enhance = compose(
  connect(
    ({ posts, fetch }) => ({ posts, fetch }),
    {
      fetchPosts,
    },
  ),
  withPosts,
  withSpinnerWhileLoading,
)

const BlogApp = ({ posts }) => (
  <div className="grid-container">
    <div className="grid-x">
      <div className="cell medium-4">
        <h1>Posts</h1>
        <Link to="/blog/new" className="button primary">
          Neuen Post anlegen
        </Link>
        {map(posts, p => (
          <div key={p.id}>
            <Link to={`/blog/${p.slug}`}>{p.slug}</Link>
          </div>
        ))}
      </div>
      <div className="cell medium-8">
        <Switch>
          <Route exact path="/blog/new" component={PostCreate} />
          <Route exact path="/blog/:slug" component={PostEdit} />
        </Switch>
      </div>
    </div>
  </div>
)

export default enhance(BlogApp)
