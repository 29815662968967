import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { Button } from '@blueprintjs/core'
import { Form, Field } from 'react-final-form'
import FormAdaptersTextInputAdapter from 'components/UI/Form/Adapters/TextInputAdapter'
import { FORM_ERROR } from 'final-form'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const GET_AUTH_URL = gql`
  query getYoutubeAuthorization {
    getYoutubeAuthorization
  }
`

const AUTHORIZE = gql`
  mutation authorizeYoutube($token: String!) {
    authorizeYoutube(token: $token) {
      success
      reason
    }
  }
`

const Error = styled.div`
  color: red;
`

const YoutubeAuthWidget = ({ authed, setAuthed }) => {
  const [getUrl, { called, loading, data }] = useLazyQuery(GET_AUTH_URL)
  const [authorize, { data: submissionData }] = useMutation(AUTHORIZE)

  const handleSubmit = async values => {
    const {
      data: {
        authorizeYoutube: { success },
      },
    } = await authorize({ variables: values })
    if (success) {
      setAuthed(true)
    } else {
      return {
        [FORM_ERROR]:
          'Authorisierung fehlgeschlagen. Probier es noch einmal und kontaktiere ansonsten Nik',
      }
      setAuthed(false)
    }
  }

  if (authed) {
    return null
  }

  return (
    <div>
      <h2>Youtube Authorisieren</h2>
      <Button onClick={getUrl} disabled={called}>
        1. Start
      </Button>
      {loading && <div>Loading</div>}
      {data && (
        <>
          <div>
            <a href={data.getYoutubeAuthorization} target="_blank">
              2. Bei Youtube Authorisieren
            </a>
          </div>
          <div>
            <Form onSubmit={handleSubmit}>
              {({ handleSubmit, submitError }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="token"
                    label="3. Token eingeben"
                    validate={value => (value ? undefined : 'Pflichtfeld')}
                    component={FormAdaptersTextInputAdapter}
                  />
                  <Button type="submit">4. Fertigstellen</Button>
                  {submitError && <Error>{submitError}</Error>}
                </form>
              )}
            </Form>
          </div>
        </>
      )}
      <pre>{JSON.stringify(submissionData, null, 2)}</pre>
    </div>
  )
}

YoutubeAuthWidget.propTypes = {
  authed: PropTypes.bool.isRequired,
  setAuthed: PropTypes.func.isRequired,
}

export default YoutubeAuthWidget
