import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import BlueprintSearch from 'components/Common/BlueprintSearch'
import { fetchTemplateMicrocycles } from 'lib/fetch/templates'

class MicrocycleTemplateSearch extends React.Component {
  state = {
    microcycles: [],
    loading: false,
  }

  componentWillMount() {
    this.fetchData()
  }

  fetchData = async () => {
    this.setState({ loading: true })
    const res = await fetchTemplateMicrocycles()
    this.setState({ microcycles: res.data['hydra:member'], loading: false })
  }

  render() {
    const { cycle, onChange } = this.props
    const { microcycles } = this.state
    const handleChange = ({ value }) =>
      onChange(microcycles.find(m => m.id === value))
    return (
      <BlueprintSearch
        shouldDismissPopover={false}
        value={get(cycle, 'id')}
        onChange={handleChange}
        options={microcycles.map(m => ({
          value: m.id,
          label: m.name,
        }))}
      />
    )
  }
}

MicrocycleTemplateSearch.propTypes = {
  cycle: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
}

MicrocycleTemplateSearch.defaultProps = {
  cycle: { id: -1 },
}

export default MicrocycleTemplateSearch
