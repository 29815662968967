import { Button, Classes, Label } from '@blueprintjs/core'
import React from 'react'
import { Field, Form } from 'react-final-form'
import FormAdaptersNumberInputAdapter from '../UI/Form/Adapters/NumberInputAdapter'
import TextInputAdapter from '../UI/Form/Adapters/TextInputAdapter'
import GroupAssignment from './GroupAssignment'
import QuillEditor from './QuillEditor'

const categories = [
  { value: 'Gymnastics', label: 'Gymnastics' },
  { value: 'Weightlifting', label: 'Weightlifting' },
  { value: 'Monostructural', label: 'Monostructural' },
  { value: 'Custom', label: 'Custom' },
]

const CrossfitExercise = ({ exercise, handleSave, groups }) => (
  <Form
    onSubmit={handleSave}
    initialValues={exercise}
    render={({ handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Field name="name" label="Name" component={TextInputAdapter} />
        <Field name="pluralName" label="Plural" component={TextInputAdapter} />
        <Label>
          Beschreibung
          <Field
            name="description"
            render={({ input }) => (
              <QuillEditor
                key={exercise.id}
                content={input.value}
                onChange={input.onChange}
              />
            )}
          />
        </Label>
        <Field
          name="category"
          render={({ input }) => (
            <Label>
              Kategorie
              <div className={Classes.SELECT}>
                <select {...input}>
                  {categories.map(({ value, label }) => (
                    <option value={value}>{label}</option>
                  ))}
                </select>
              </div>
            </Label>
          )}
        />

        <Field
          name="groups"
          render={({ input }) => <GroupAssignment groups={groups} {...input} />}
        />

        {/* <Field
          name="femaleScalingFactor"
          label="Frauen Skalierung"
          component={FormAdaptersNumberInputAdapter}
        /> */}
        <Button type="submit" intent="primary">
          {submitting ? 'Wird gespeichert... ' : 'Speichern'}
        </Button>
      </form>
    )}
  />
)

export default CrossfitExercise
