import { Button, ButtonGroup, Card, ControlGroup } from '@blueprintjs/core'
import {
  DropDownAdapter,
  NumberInputAdapter,
  TextFieldAdapter,
} from 'components/Form/Adapters'
import { required } from 'components/Form/Validation'
import BeautifulDragHandle from 'components/Layout/BeautifulDragHandle'
import CenteredRow from 'components/Layout/StyledComponents/CenteredRow'
import Row from 'components/Layout/StyledComponents/Row'
import MinuteBlockField from 'components/Module/ModuleComponents/ExerciseFields/MinuteBlockField'
import arrayMutators from 'final-form-arrays'
import { Draggable, Droppable } from 'lib/dragdrop'
import useModuleComponent from 'lib/hooks/useModuleComponent'
import { sortBy } from 'lodash'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useModuleActions } from '../hooks'
import { getItemStyle, getListStyle } from './styles'

const EmomModuleComponent = props => {
  const { minuteBlocks, ...component } = useModuleComponent(props.id, 'emom')
  const { handleRemove, handleSave } = useModuleActions(component)

  const initialValues = {
    ...component,
    minuteBlocks: sortBy(minuteBlocks, 'position').map(
      ({ exercises, complexes, ...block }) => ({
        ...block,
        exercises: sortBy([...exercises, ...complexes], 'position'),
      }),
    ),
  }

  const onSave = async ({ minuteBlocks, ...values }) => {
    const payload = {
      ...values,
      minuteBlocks: minuteBlocks.map(({ exercises, ...block }) => ({
        ...block,
        exercises: exercises.filter(e => e['@type'] === 'ExerciseSingleBlock'),
        complexes: exercises.filter(e => e['@type'] === 'ExerciseComplexBlock'),
      })),
    }

    handleSave(payload)
  }

  return (
    <Card>
      <Form
        onSubmit={onSave}
        mutators={{
          ...arrayMutators,
          arrayMove: ([name, from, to], state, { changeValue }) => {
            changeValue(state, name, array => {
              const copy = [...(array || [])]
              const value = copy[from]
              copy.splice(from, 1)
              copy.splice(to, 0, value)
              return copy
            })
          },
        }}
        initialValues={initialValues}
        render={({
          values,
          handleSubmit,
          submitting,
          submitError,
          form: {
            change,
            mutators: { arrayMove },
          },
        }) => (
          <form onSubmit={handleSubmit}>
            {submitError && <div className="error">{submitError}</div>}
            <Row>
              <div style={{ flexGrow: 1 }}>
                <h3>{props.title || component['@type']}</h3>
              </div>
              <ButtonGroup>
                <Button
                  intent="success"
                  icon="floppy-disk"
                  type="submit"
                  loading={submitting}
                />
                {/* <Button
                  icon={this.state.expanded ? 'chevron-up' : 'chevron-down'}
                  onClick={this.toggleExpand}
                />
                 */}
                {component['@type'] !== 'WeightliftingModuleComponent' && (
                  <Button icon="trash" intent="danger" onClick={handleRemove} />
                )}
              </ButtonGroup>
            </Row>
            <ControlGroup
              style={{
                alignItems: 'flex-end',
                marginTop: '8px',
                marginBottom: '8px',
              }}
              fill
            >
              <Field
                name="comment"
                type="text"
                label="Kommentar"
                component={TextFieldAdapter}
                parse={value => (value === '' ? null : value)}
              />
              <Field
                name="everyX"
                type="text"
                label="Jede X Minute"
                validate={required}
                component={TextFieldAdapter}
              />
              <Field
                name="duration"
                type="text"
                label="Dauer"
                format={value => value / 60}
                parse={value => value * 60}
                validate={required}
                component={NumberInputAdapter}
              />
              <Field
                name="exerciseType"
                label="Übungstyp"
                component={DropDownAdapter}
                options={[
                  { value: 'all', label: 'Alle' },
                  { value: 'Crossfit', label: 'CrossFit' },
                  { value: 'Weightlifting', label: 'Weightlifting' },
                  { value: 'Gymnastics', label: 'Gymnastics' },
                ]}
              />
            </ControlGroup>

            <FieldArray name="minuteBlocks">
              {({ fields }) => {
                const droppableId = `${component['@id']}-minutes`

                const handleExerciseDragEnd = event => {
                  if (
                    event.destination.droppableId === droppableId &&
                    event.source.droppableId === droppableId
                  ) {
                    arrayMove(
                      fields.name,
                      event.source.index,
                      event.destination.index,
                    )

                    fields.forEach((name, index) => {
                      change(`${name}.position`, index)
                    })
                  }
                }
                return (
                  <>
                    <Droppable
                      droppableId={droppableId}
                      type={droppableId}
                      onDragEnd={handleExerciseDragEnd}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {fields.map((name, index) => (
                            <Draggable
                              type={droppableId}
                              key={name}
                              draggableId={`${droppableId}-${index}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}
                                >
                                  <BeautifulDragHandle
                                    {...provided.dragHandleProps}
                                  />
                                  <MinuteBlockField
                                    name={name}
                                    index={index}
                                    fields={fields}
                                    exerciseType={values.exerciseType}
                                    component={component}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>

                    <CenteredRow style={{ marginTop: '8px' }}>
                      <ButtonGroup>
                        <Button
                          intent="primary"
                          icon="plus"
                          onClick={() =>
                            fields.push({
                              '@type': 'EmomBlock',
                              position: fields.length,
                            })
                          }
                        >
                          Minute hinzufügen
                        </Button>
                      </ButtonGroup>
                    </CenteredRow>
                  </>
                )
              }}
            </FieldArray>
          </form>
        )}
      />
    </Card>
  )
}

export default EmomModuleComponent
