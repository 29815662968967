import BlueprintSearch from 'components/Common/BlueprintSearch'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { fetchGeneric } from '../../lib/fetchService'

class LiftSearch extends React.Component {
  state = {
    lifts: [],
    loading: false,
  }

  componentWillMount() {
    this.fetchData()
  }

  fetchData = async () => {
    this.setState({ loading: true })
    const res = await fetchGeneric('lifts')
    this.setState({ lifts: res.data['hydra:member'], loading: false })
  }

  render() {
    const { lift, onChange } = this.props
    const { lifts } = this.state
    const handleChange = ({ value }) =>
      onChange(lifts.find(m => m['@id'] === value))
    return (
      <BlueprintSearch
        inputProps={{
          placeholder: 'Lift auswählen',
        }}
        shouldDismissPopover={false}
        value={get(lift, '@id')}
        onChange={handleChange}
        options={lifts.map(m => ({
          value: m['@id'],
          label: m.name,
        }))}
      />
    )
  }
}

LiftSearch.propTypes = {
  lift: PropTypes.shape({
    '@id': PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
}

LiftSearch.defaultProps = {
  lift: { '@id': null },
}

export default LiftSearch
