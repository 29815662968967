import { fetchModule } from 'ducks/modules'
import { useDispatch } from 'react-redux'
import { removeComponent, updateComponent } from 'lib/fetchService'
import { AppToaster } from 'lib/toaster'
import { FORM_ERROR } from 'final-form'
import { useModule } from './ModuleContext'

export const useModuleActions = component => {
  const dispatch = useDispatch()

  const module = useModule()

  const handleRemove = async () => {
    try {
      await removeComponent(component)
      AppToaster.show({
        message: 'Modul gelöscht.',
        intent: 'success',
      })
      dispatch(fetchModule(module.id))
    } catch (e) {
      AppToaster.show({
        message: 'Fehler beim löschen',
        intent: 'warning',
      })
    }
  }

  const handleSave = async payload => {
    try {
      await updateComponent(payload)
      AppToaster.show({
        message: 'Modul gespeichert.',
        intent: 'success',
      })
    } catch (e) {
      AppToaster.show({
        message: 'Fehler beim speichern',
        intent: 'warning',
      })
      return { [FORM_ERROR]: e.message }
    }
  }

  return { handleRemove, handleSave }
}
