import { Button, ButtonGroup, Classes } from '@blueprintjs/core'
import { EditableCell, Column, Table } from '@blueprintjs/table'
import '@blueprintjs/table/lib/css/table.css'
import React from 'react'
import TopAlignedRow from './Layout/StyledComponents/TopAlignedRow'

const WeightliftingComplexExercise = ({
  exercise,
  sets,
  handleRepsChange,
  addSet,
  removeSet,
  name,
  classes,
}) => {
  const handleAddSet = () => addSet(name)
  const handleRemoveSet = () => removeSet(name)
  const changeValue = (type, index) => value =>
    handleRepsChange(`${name}.sets.${index}.${type}`, value)
  return (
    <div>
      <div className={Classes.TEXT_LARGE}>
        {exercise && exercise.displayName}
      </div>
      <TopAlignedRow>
        <Table numRows={sets.length}>
          <Column
            name="Reps"
            cellRenderer={idx => (
              <EditableCell
                value={sets[idx].reps}
                onConfirm={changeValue('reps', idx)}
              />
            )}
          />
          <Column
            name="Weight"
            cellRenderer={idx => (
              <EditableCell
                value={sets[idx].weight}
                onConfirm={changeValue('weight', idx)}
              />
            )}
          />
        </Table>
        <ButtonGroup vertical>
          <Button icon="plus" name={name} onClick={handleAddSet}>
            Satz hinzufügen
          </Button>
          <Button icon="trash" name={name} onClick={handleRemoveSet}>
            Letzten Satz entfernen
          </Button>
        </ButtonGroup>
      </TopAlignedRow>
    </div>
  )
}

export default WeightliftingComplexExercise
