import produce from 'immer'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { dispatchFetchError, FETCH_END, FETCH_START } from '../ducks/fetch'
import {
  CREATE_MODULE_DATE_FAIL,
  CREATE_MODULE_DATE_REQUEST,
  CREATE_MODULE_DATE_SUCCESS,
  FETCH_MODULE_DATE_FAIL,
  FETCH_MODULE_DATE_REQUEST,
  FETCH_MODULE_DATE_SUCCESS,
  REMOVE_MODULE_DATE_FAIL,
  REMOVE_MODULE_DATE_REQUEST,
  REMOVE_MODULE_DATE_SUCCESS,
  SAVE_MODULE_DATE_FAIL,
  SAVE_MODULE_DATE_REQUEST,
  SAVE_MODULE_DATE_SUCCESS,
} from '../ducks/moduleDates'
import {
  createModuleDate,
  fetchModuleDate,
  removeModuleDate,
  saveModuleDate,
} from '../lib/fetch/moduleDates'
import { denormalizeModuleDate } from '../lib/normalize'

function* fetchModuleDateRequest({ payload: { id } }) {
  try {
    const res = yield call(fetchModuleDate, id)

    yield put({
      type: FETCH_MODULE_DATE_SUCCESS,
      moduleDate: res.data,
    })
  } catch (err) {
    console.log(err)
    yield put({ type: FETCH_MODULE_DATE_FAIL })
    yield put(dispatchFetchError({ endpoint: 'day', error: err }))
  }
}

function* createModuleDateRequest({
  moduleDate: { session, date, type, subtype, position },
}) {
  try {
    yield put({ type: FETCH_START, session })
    const plans = yield select(
      ({ subscriptionPlans }) => subscriptionPlans.plans,
    )
    const assignedPlans = plans.filter(
      p => p.active && p.name !== 'weightlifting',
    )
    const { data: moduleDate } = yield call(createModuleDate, {
      session,
      date,
      plans: assignedPlans,
      type,
      subtype,
      position,
    })
    yield put({ type: CREATE_MODULE_DATE_SUCCESS, moduleDate, session })
    yield put({ type: FETCH_END, session })
  } catch (error) {
    yield put({ type: CREATE_MODULE_DATE_FAIL, message: error.message })
    yield put(dispatchFetchError({ endpoint: 'moduleDate', error, session }))
  }
}

const getDenormalizedModuleDate = (state, moduleDate) =>
  denormalizeModuleDate(moduleDate, state)

function* saveModuleDateRequest({ moduleDate }) {
  try {
    yield put({ type: FETCH_START, moduleDate })

    const denormalizedModuleDate = yield select(
      getDenormalizedModuleDate,
      moduleDate,
    )
    const denormalizedwithIri = produce(denormalizedModuleDate, draft => {
      draft.module = draft.module['@id']
    })

    yield call(saveModuleDate, denormalizedwithIri)
    yield put({ type: SAVE_MODULE_DATE_SUCCESS, moduleDate })
    yield put({ type: FETCH_END, moduleDate })
  } catch (error) {
    console.log(error)
    yield put({ type: SAVE_MODULE_DATE_FAIL, message: error.message })
    yield put(dispatchFetchError({ endpoint: 'moduleDate', error, moduleDate }))
  }
}

function* removeModuleDateRequest({ session, moduleDate }) {
  try {
    yield put({ type: FETCH_START, session, moduleDate })
    yield call(removeModuleDate, moduleDate)
    yield put({ type: REMOVE_MODULE_DATE_SUCCESS, session, moduleDate })
    yield put({ type: FETCH_END, session, moduleDate })
  } catch (error) {
    yield put({ type: REMOVE_MODULE_DATE_FAIL, message: error.message })
    yield put(
      dispatchFetchError({
        endpoint: 'moduleDate',
        error,
        session,
        moduleDate,
      }),
    )
  }
}

function* moduleDateSaga() {
  yield takeEvery(REMOVE_MODULE_DATE_REQUEST, removeModuleDateRequest)
  yield takeEvery(SAVE_MODULE_DATE_REQUEST, saveModuleDateRequest)
  yield takeEvery(CREATE_MODULE_DATE_REQUEST, createModuleDateRequest)
  yield takeEvery(FETCH_MODULE_DATE_REQUEST, fetchModuleDateRequest)
}

export default moduleDateSaga
