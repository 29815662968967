import arrayMove from 'array-move'
import { fetchModuleDatesBetween } from 'ducks/calendar'
import produce from 'immer'
import { groupBy, map, size, sortBy, flatMap, differenceWith } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { saveModuleDate } from 'lib/fetch/moduleDates'
import { AppToaster } from 'lib/toaster'
import Session from './Session'

const Container = styled.div`
  grid-column: 1 / -1;
`

const PlanDayPlan = ({ moduleDates, date }) => {
  const dispatch = useDispatch()
  const [groupedModuleDates, setGroupedModuleDates] = useState({})

  useEffect(() => {
    const sorted = sortBy(moduleDates, ['session', 'position'])

    const grouped = groupBy(sorted, 'session')

    const newPositions = map(grouped, modules =>
      modules.map((m, i) => ({ ...m, position: i })),
    )

    setGroupedModuleDates(newPositions)
  }, [moduleDates, date])

  const handleDrop = async event => {
    if (!event.destination) {
      return
    }

    const { session: destinationSession } = JSON.parse(
      event.destination.droppableId,
    )
    const { session: sourceSession } = JSON.parse(event.source.droppableId)

    if (
      destinationSession === sourceSession &&
      event.source.index === event.destination.index
    ) {
      return
    }

    const { id } = JSON.parse(event.draggableId)

    const module = moduleDates.find(m => m.id === id)

    const toast = AppToaster.show({
      message: 'Wird gespeichert...',
      timeout: 0,
    })

    // we change the data here

    const newDates = produce(groupedModuleDates, draft => {
      if (destinationSession === sourceSession) {
        draft[destinationSession] = arrayMove(
          draft[destinationSession],
          event.source.index,
          event.destination.index,
        )
      } else {
        module.session = destinationSession
        draft[sourceSession].splice(event.source.index, 1)
        if (draft[destinationSession]) {
          draft[destinationSession].splice(event.destination.index, 0, module)
        } else {
          draft[destinationSession] = [module]
        }
      }
    })

    const newPositions = map(newDates, modules =>
      modules.map((m, i) => ({ ...m, position: i })),
    )

    setGroupedModuleDates(newPositions)

    // we save the new positions

    const allModuleDates = flatMap(newPositions)

    const promises = allModuleDates.map(({ id, session, position }) =>
      saveModuleDate({ id, session, position }),
    )

    await Promise.all(promises)

    AppToaster.dismiss(toast)
    AppToaster.show({
      message: 'Gespeichert',
      intent: 'success',
    })

    // we refresh the data

    dispatch(fetchModuleDatesBetween(date, date))
  }

  return (
    <DragDropContext onDragEnd={handleDrop}>
      <Container>
        {map(groupedModuleDates, (moduleDates, session) => (
          <Session
            key={session}
            index={Number(session)}
            moduleDates={moduleDates}
            date={date}
          />
        ))}

        <Session index={size(groupedModuleDates)} date={date} />
      </Container>
    </DragDropContext>
  )
}

PlanDayPlan.propTypes = {
  date: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  moduleDates: PropTypes.array.isRequired,
}

export default PlanDayPlan
