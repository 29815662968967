import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { fetchModule } from '../../ducks/modules'
import Module from '../Module/Module'

class EditModuleContainer extends React.Component {
  componentDidMount() {
    this.props.fetchModule(this.props.match.params.id)
  }

  render() {
    const {
      match: {
        params: { id },
      },
    } = this.props
    return (
      <div>
        <h1>{`Modul ${id}`}</h1>
        <Module id={id} expanded />
      </div>
    )
  }
}

const enhance = compose(
  withRouter,
  connect(
    null,
    { fetchModule },
  ),
)

export default enhance(EditModuleContainer)
