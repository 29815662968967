import {
  Button,
  Classes,
  Intent,
  Popover,
  Position,
  ProgressBar,
} from '@blueprintjs/core'
import { DatePicker } from '@blueprintjs/datetime'
import { fetchModuleDatesBetween } from 'ducks/calendar'
import { updateModuleDate } from 'ducks/moduleDates'
import { saveModuleDate as fetchSaveModuleDate } from 'lib/fetch/moduleDates'
import { AppToaster } from 'lib/toaster'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import LuxonLocaleUtils from '../../lib/LuxonLocaleUtils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
`

const ModuleDateInfo = ({
  moduleDateId,
  moduleDateDate,
  willBeAnnouncedAt,
}) => {
  const [date, setDate] = useState(
    willBeAnnouncedAt
      ? DateTime.fromISO(willBeAnnouncedAt)
          .setZone('Europe/Berlin')
          .toJSDate()
      : null,
  )

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const handleSave = async () => {
    setLoading(true)
    const payload = {
      id: moduleDateId,
      willBeAnnouncedAt: DateTime.fromJSDate(date).toISO(),
    }

    dispatch(updateModuleDate(payload))

    const toast = AppToaster.show({
      message: <ProgressBar />,
      timeout: 0,
    })

    await fetchSaveModuleDate(payload)

    AppToaster.show(
      {
        message: 'Gespeichert',
        intent: 'success',
      },
      toast,
    )

    dispatch(fetchModuleDatesBetween(moduleDateDate, moduleDateDate))
    setLoading(false)
  }

  return (
    <Popover
      content={
        <Container>
          <DatePicker
            locale="de"
            localeUtils={LuxonLocaleUtils}
            className={Classes.ELEVATION_1}
            onChange={setDate}
            showActionsBar
            timePrecision="minute"
            dayPickerProps={{
              showWeekNumbers: true,
            }}
            value={date}
            maxDate={DateTime.local()
              .plus({ months: 1 })
              .toJSDate()}
          />
          <Button
            intent={Intent.PRIMARY}
            loading={loading}
            onClick={handleSave}
          >
            Speichern
          </Button>
        </Container>
      }
      position={Position.RIGHT}
    >
      <Button minimal icon="time" text="Einplanen" />
    </Popover>
  )
}

export default ModuleDateInfo
