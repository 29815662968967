import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import Table from './Table'

const query = gql`
  query powerbuildingCycle($id: ID!) {
    powerbuildingCycle(id: $id) {
      id
      slug
      exercises {
        id
        week
        day
        exerciseOfWeightliftingModule
        exercise {
          ... on IExercise {
            id
            name
          }
        }
        benchmark {
          id
        }
        preModifier
        postModifier
        identifier
        warmupSets
        sets
        reps
        load
        rest
        repsInReserve
        rateOfPerceivedExertion
        comment
        tempo

        strengthPlan {
          id
          name
        }

        createdAt
        updatedAt
      }
    }
  }
`

const PowerbuildingTableContainer = () => {
  const { id } = useParams()

  const { data, error, loading } = useQuery(query, { variables: { id } })

  if (data) {
    return <Table data={data} />
  }

  return null
}

export default PowerbuildingTableContainer
