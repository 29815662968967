import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import VideoUploadForm from 'components/Apps/VideoUploadForm'
import { Button, AnchorButton } from '@blueprintjs/core'
import YoutubeAuthWidget from 'components/Apps/YoutubeAuthWidget'
import styled from 'styled-components/macro'

export const ALL_VIDEOS = gql`
  query videos {
    videos {
      id
      name
      slug
      uploadPath
      youtubeId
      status
    }
  }
`

const UPLOAD_TO_YOUTUBE = gql`
  mutation uploadVideoToYoutube($id: ID!) {
    uploadVideoToYoutube(id: $id) {
      success
      reason
    }
  }
`

const PROCESS_VIDEO = gql`
  mutation processVideo($id: ID!) {
    processVideo(id: $id) {
      success
      reason
    }
  }
`

const Error = styled.div`
  color: red;
`

const VideosApp = () => {
  const [uploadError, setUploadError] = useState(null)
  const [authed, setAuthed] = useState(true)
  const { loading, error, data, refetch } = useQuery(ALL_VIDEOS, {
    pollInterval: 5000,
  })

  const [uploadToYoutube, { loading: uploading }] = useMutation(
    UPLOAD_TO_YOUTUBE,
  )
  const [processVideo] = useMutation(PROCESS_VIDEO)

  const processHandler = async id => {
    await processVideo({ variables: { id } })
    refetch()
  }

  const uploadHandler = async id => {
    setUploadError(null)
    try {
      const {
        data: {
          uploadVideoToYoutube: { success, reason },
        },
      } = await uploadToYoutube({ variables: { id } })
      if (!success) {
        setUploadError(reason)
      }
    } catch (e) {
      console.log(e.message)
      setUploadError(e.message)
      setAuthed(false)
    }
  }

  if (loading) return <p>Loading...</p>
  if (error)
    return (
      <p>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </p>
    )

  return (
    <div>
      <table className="bp3-html-table .modifier">
        <thead>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>slug</th>
            <th>YouTube ID</th>
            <th>YouTube Link</th>
            <th>status</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {data.videos.map(
            ({ id, name, slug, youtubeId, uploadPath, status }) => (
              <tr key={id}>
                <td>{id}</td>
                <td>{name}</td>
                <td>{slug}</td>
                <td>{youtubeId}</td>
                <td>
                  <a
                    href={`https://www.youtube.com/watch?v=${youtubeId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{`https://www.youtube.com/watch?v=${youtubeId}`}</a>
                </td>
                <td>{status}</td>
                <td>
                  {['Waiting for Conversion', 'Error'].includes(status) && (
                    <Button
                      loading={uploading}
                      onClick={() => processHandler(id)}
                    >
                      Video verarbeiten
                    </Button>
                  )}
                  {['Ready for Upload', 'Uploaded'].includes(status) && (
                    <AnchorButton
                      href={`${process.env.REACT_APP_GRAPHQL_URL}/video_download/${slug}`}
                      target="_blank"
                    >
                      Herunterladen
                    </AnchorButton>
                  )}
                  {['Ready for Upload'].includes(status) && (
                    <Button
                      loading={uploading}
                      onClick={() => uploadHandler(id)}
                    >
                      Bei Youtube hochladen
                    </Button>
                  )}
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
      <div>
        <h2>Neues Video hochladen</h2>
        <VideoUploadForm refetch={refetch} />
      </div>
      <YoutubeAuthWidget authed={authed} setAuthed={setAuthed} />
      {uploadError && <Error>{uploadError}</Error>}
    </div>
  )
}

export default VideosApp
