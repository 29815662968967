import {
  Checkbox,
  InputGroup,
  NumericInput,
  ControlGroup,
} from '@blueprintjs/core'
import PropTypes from 'prop-types'
import React from 'react'
import Dropdown from './Common/Dropdown'

const ModuleComponentEditFields = ({
  fields,
  component,
  onSelectChange,
  onNumberValueChange,
  onChange,
}) => (
  <ControlGroup
    style={{
      alignItems: 'flex-end',
      marginTop: '8px',
      marginBottom: '8px',
    }}
    fill
  >
    {fields.map(field => {
      const handleValueChange = value =>
        onNumberValueChange({ name: field.name, value })
      const handleSelectChange = value => onSelectChange(field.name, value)

      switch (field.type) {
        case 'select':
          return (
            <div key={field.name}>
              <Dropdown
                name={field.name}
                label={field.label}
                value={component[field.name]}
                options={field.options}
                onChange={handleSelectChange}
              />
            </div>
          )
        case 'duration':
          return (
            <div key={field.name}>
              <label>
                {field.label}
                <NumericInput
                  style={{ display: 'block' }}
                  name={field.name}
                  value={component[field.name] / 60}
                  onValueChange={handleValueChange}
                />
              </label>
            </div>
          )

        case 'checkbox':
          return (
            <div key={field.name}>
              <label>
                {field.label}
                <Checkbox
                  style={{ display: 'block' }}
                  type="checkbox"
                  name={field.name}
                  checked={component[field.name]}
                  onChange={onChange}
                />
              </label>
            </div>
          )

        default:
          return (
            <div key={field.name}>
              <label>
                {field.label}
                <InputGroup
                  style={{ display: 'block' }}
                  type="text"
                  name={field.name}
                  value={component[field.name]}
                  onChange={onChange}
                />
              </label>
            </div>
          )
      }
    })}
  </ControlGroup>
)

ModuleComponentEditFields.propTypes = {
  fields: PropTypes.array.isRequired,
  component: PropTypes.object.isRequired,
}

export default ModuleComponentEditFields
