import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components/macro'

const Container = styled.div`
  padding: 4px;
`

export const PositionDisplay = ({ name }) => (
  <Field
    style={{ width: '40px' }}
    name={`${name}.position`}
    placeholder="reps"
    type="text"
    render={({ input: { value } }) => <Container>{`${value + 1}.`}</Container>}
  />
)
