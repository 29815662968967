import React from 'react'
import {
  fetchTemplateMesocycles,
  createTemplateMesocycle,
} from '../../../lib/fetch/templates'
import TemplateListContainer from '../Generic/TemplateListContainer'

export default () => (
  <TemplateListContainer
    fetchFunction={fetchTemplateMesocycles}
    createFunction={createTemplateMesocycle}
    type="mesocycles"
  />
)
