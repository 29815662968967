import { useMutation } from '@apollo/react-hooks'
import { Button, Dialog, Intent } from '@blueprintjs/core'
import { gql } from 'apollo-boost'
import { FORM_ERROR } from 'final-form'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useToggle } from 'react-use'
import styled from 'styled-components'
import { AppToaster } from '../../lib/toaster'
import ExerciseForm from './ExerciseForm'

const Error = styled.div`
  color: red;
`

const mutation = gql`
  mutation insertPowerbuildingCycleExercise(
    $data: InsertPowerbuildingCycleExerciseInput!
  ) {
    insertPowerbuildingCycleExercise(data: $data) {
      id
      week
      day
      exercise {
        ... on IExercise {
          id
          name
        }
      }
      sets {
        reps
      }
      repsInReserve
      comment
      tempo

      createdAt
      updatedAt
    }
  }
`

const PowerbuildingAddExerciseDialog = ({ cycleId, day, week }) => {
  const [open, toggleOpen] = useToggle(false)

  const [mutate] = useMutation(mutation, {
    refetchQueries: ['powerbuildingCycle'],
  })

  const onSubmit = async values => {
    try {
      await mutate({ variables: { data: { cycleId, ...values } } })
      AppToaster.show({
        message: 'Erfolgreich',
        intent: 'success',
      })
      toggleOpen(false)
    } catch (e) {
      AppToaster.show({
        message: 'Es ist ein Fehler aufgetreten',
        intent: 'error',
      })
      console.log(e)

      return { [FORM_ERROR]: e.message }
    }
  }

  const validate = () => undefined

  return (
    <>
      <Button
        onClick={() => toggleOpen(true)}
        icon="add"
        intent={Intent.PRIMARY}
      />
      <Dialog
        isOpen={open}
        usePortal
        icon="info-sign"
        title="Übung hinzufügen"
        onClose={() => toggleOpen(false)}
      >
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={{ day, week }}
          component={ExerciseForm}
        />
      </Dialog>
    </>
  )
}

PowerbuildingAddExerciseDialog.defaultProps = { day: 1, week: 1 }

export default PowerbuildingAddExerciseDialog
