import { Button, ButtonGroup } from '@blueprintjs/core'
import { TextFieldAdapter } from 'components/Form/Adapters'
import { Droppable } from 'lib/dragdrop'
import React from 'react'
import { Field, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import styled from 'styled-components/macro'
import CenteredRow from '../../../Layout/StyledComponents/CenteredRow'
import Row from '../../../Layout/StyledComponents/Row'
import { getListStyle } from '../styles'
import ExerciseBlockField from './ExerciseBlockField'
import ExerciseComplexBlockField from './ExerciseComplexBlockField'

const StyledRow = styled(Row)`
  justify-content: space-between;
  margin: 1rem 0;
`

const MinuteBlockField = ({
  name: emomName,
  fields,
  index,
  exerciseType,
  dragHandle,
  component,
}) => {
  const form = useForm()
  return (
    <>
      <StyledRow>
        <span>Minute {index + 1}</span>
        <Field
          fill
          name={`${emomName}.position`}
          placeholder="Kommentar"
          component={TextFieldAdapter}
        />
        <Field
          fill
          name={`${emomName}.comment`}
          placeholder="Kommentar"
          component={TextFieldAdapter}
        />
        <Button
          style={{ flexGrow: 0 }}
          icon="trash"
          intent="danger"
          type="button"
          onClick={() => fields.remove(index)}
        />
      </StyledRow>
      <FieldArray name={`${emomName}.exercises`}>
        {({ fields }) => {
          const droppableId = `${component['@id']}-${emomName}-exercises`

          const handleExerciseDragEnd = event => {
            if (
              event.destination.droppableId === droppableId &&
              event.source.droppableId === droppableId
            ) {
              form.mutators.arrayMove(
                fields.name,
                event.source.index,
                event.destination.index,
              )
              fields.forEach((name, index) => {
                form.change(`${name}.position`, index)
              })
            }
          }
          return (
            <>
              <Droppable
                type={droppableId}
                onDragEnd={handleExerciseDragEnd}
                droppableId={droppableId}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {fields.map((name, index) => (
                      <Field
                        name={`${name}.@type`}
                        subscription={{ value: true }}
                      >
                        {({ input: { value } }) =>
                          value === 'ExerciseComplexBlock' ? (
                            <ExerciseComplexBlockField
                              name={name}
                              index={index}
                              fields={fields}
                              exerciseType={exerciseType}
                              droppableId={droppableId}
                            />
                          ) : (
                            <ExerciseBlockField
                              name={name}
                              index={index}
                              fields={fields}
                              exerciseType={exerciseType}
                              droppableId={droppableId}
                            />
                          )
                        }
                      </Field>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <CenteredRow style={{ marginTop: '8px' }}>
                <ButtonGroup>
                  <Button
                    intent="primary"
                    icon="plus"
                    onClick={() =>
                      fields.push({
                        '@type': 'ExerciseSingleBlock',
                        position: fields.length,
                      })
                    }
                  >
                    Übung hinzufügen
                  </Button>
                  <Button
                    icon="plus"
                    onClick={() =>
                      fields.push({
                        '@type': 'ExerciseComplexBlock',
                        position: fields.length,
                      })
                    }
                  >
                    Komplex oder Ladder hinzufügen
                  </Button>
                </ButtonGroup>
              </CenteredRow>
            </>
          )
        }}
      </FieldArray>
    </>
  )
}

export default MinuteBlockField
