import React from 'react'

import DatePicker from 'react-datepicker'
import { compose } from 'recompose'
import { DateTime } from 'luxon'

import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import AthleteFilter from './AthleteFilter'
import { setEndDate, setStartDate } from '../../../../ducks/evaluation'
import LuxonLocaleUtils from '../../../../lib/LuxonLocaleUtils'

const enhance = compose(
  connect(
    ({
      evaluation: {
        filter: { startDate, endDate },
      },
    }) => ({
      startDate,
      endDate,
    }),
    { setStartDate, setEndDate },
  ),
)

const DateFilter = enhance(
  ({ startDate, setStartDate, endDate, setEndDate }) => (
    <div style={{ marginTop: '16px' }}>
      <div className="grid-x grid-margin-x">
        <div className="cell auto">
          <DatePicker
            style={{ display: 'inline-block' }}
            selected={startDate}
            onChange={setStartDate}
            localeUtils={LuxonLocaleUtils}
            locale="de"
            maxDate={DateTime.local()
              .plus({ years: 1 })
              .toJSDate()}
          />
        </div>
        <div className="cell auto">
          <DatePicker
            localeUtils={LuxonLocaleUtils}
            selected={endDate}
            onChange={setEndDate}
            locale="de"
          />
        </div>
        <div className="cell shrink">
          <button className="button">laden</button>
        </div>
      </div>
    </div>
  ),
)

const Controls = () => (
  <div>
    <DateFilter />
    <AthleteFilter />
  </div>
)

export default Controls
