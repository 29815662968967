import React from 'react'
import { AppToaster } from 'lib/toaster'

const GraphQLErrors = ({ error, children }) => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    if (error.graphQLErrors[0].message === 'Not authorized') {
      AppToaster.show({
        message: 'Nicht angemeldet',
        intent: 'warning',
      })
    }
  }

  return children || null
}

export default GraphQLErrors
