import { DateTime } from 'luxon'

const dateFormats = {
  DATE_FULL: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
  DATE_FULL_WITH_WEEKDAY: {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
}

export const formatFullDateFromISO = value =>
  DateTime.fromISO(value).toLocaleString(dateFormats.DATE_FULL)

export default dateFormats
