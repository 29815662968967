import React from 'react'
import ImportWeightliftingContainer from './ImportWeightlifting'

import { Link, Route, Switch } from 'react-router-dom'

export default () => (
  <div>
    <div className="grid-x">
      <div className="cell medium-3">
        <ul className="vertical menu">
          <li>
            <Link to="/weightlifting/import">Import</Link>
          </li>
        </ul>
      </div>
      <div className="cell medium-9">
        <Switch>
          <Route
            path="/weightlifting/import"
            component={ImportWeightliftingContainer}
          />
        </Switch>
      </div>
    </div>
  </div>
)
