import { Icon } from '@blueprintjs/core'
import React from 'react'
import styled from 'styled-components/macro'

const DragHandle = styled.div`
  &&& {
    flex-grow: 0;
  }
`

export default ({ dragHandleProps, style, ...props }) => (
  <DragHandle
    className="bp3-button"
    style={style}
    {...props}
    {...dragHandleProps}
  >
    <Icon icon="move" />
  </DragHandle>
)
