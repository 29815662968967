/* eslint-disable import/no-extraneous-dependencies, no-underscore-dangle */
import { DateTime, Info } from 'luxon'

export function formatDay(day, locale = 'en') {
  return DateTime.fromJSDate(day)
    .setLocale(locale)
    .toLocaleString({
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    })
}

export function formatMonthTitle(date, locale = 'en') {
  return DateTime.fromJSDate(date)
    .setLocale(locale)
    .toLocaleString({ year: 'numeric', month: 'long' })
}

export function formatWeekdayShort(day, locale = 'en') {
  return Info.weekdays('short', { locale })[(day + 6) % 7]
}

export function formatWeekdayLong(day, locale = 'en') {
  return Info.weekdays('long', { locale })[(day + 6) % 7]
}

export function getFirstDayOfWeek(locale = 'en') {
  return 1
}

export function getMonths(locale = 'en') {
  return Info.months('long', { locale })
}

export default {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths,
}
