import { instance } from '../fetchService'

export const baseUrl = process.env.REACT_APP_API_URL

export const createModuleDate = ({
  session,
  date,
  plans,
  type,
  subtype,
  position,
  module,
  copy,
}) =>
  instance.post('create_module_date', {
    session,
    date,
    subscriptionPlans: plans ? plans.map(p => p['@id']) : [],
    type,
    subtype,
    position,
    module,
    copy,
  })

export const saveModuleDate = moduleDate =>
  instance.put(`module_dates/${moduleDate.id}`, moduleDate)

export const removeModuleDate = moduleDate =>
  instance.delete(`module_dates/${moduleDate.id}`)

export const fetchModuleDate = id => instance.get(`module_dates/${id}`)

// export const moveModule = ({ id, ...rest }) =>
//   instance.put(`modules/${id}`, rest)

export const fetchModuleDates = date =>
  instance.get('module_dates', {
    params: { date: date, 'userCycle[exists]': false },
  })

export const fetchModuleDatesBetween = (start, end) =>
  instance.get('module_dates', {
    params: {
      'date[after]': start,
      'date[before]': end,
      'userCycle[exists]': false,
    },
  })

export const fetchModuleDatesBetweenForCalendar = (start, end) =>
  instance.get('module_dates', {
    params: {
      'date[after]': start,
      'date[before]': end,
      'userCycle[exists]': false,
    },
    headers: {
      granularity: 'calendar',
    },
  })
