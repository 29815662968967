import { findIndex, map } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import Row from './Layout/StyledComponents/Row'

const PlanDropdown = ({ onChange, moduleDate }) => {
  const { id } = moduleDate
  const plans = useSelector(({ subscriptionPlans: { plans } }) => plans)

  const assignedPlans = moduleDate.subscriptionPlans.map(planIri =>
    plans.find(plan => plan['@id'] === planIri),
  )

  const onTogglePlan = plan => {
    const idx = findIndex(assignedPlans, ['id', plan.id])
    if (idx !== -1) {
      assignedPlans.splice(idx, 1)
    } else {
      assignedPlans.push(plan)
    }
    onChange(assignedPlans)
  }

  return (
    <Row>
      {map(plans, plan => (
        <div key={plan.id}>
          <div className="ui checkbox">
            <input
              type="checkbox"
              id={`plan_${id}_${plan.id}`}
              name={plan.name}
              checked={assignedPlans.findIndex(p => p.id === plan.id) >= 0}
              onChange={() => onTogglePlan(plan)}
            />
            <label
              htmlFor={`plan_${id}_${plan.id}`}
              style={{
                color:
                  assignedPlans.findIndex(p => p.id === plan.id) < 0
                    ? 'rgba(0, 0, 0, 0.37)'
                    : 'black',
              }}
            >
              {plan.name}
            </label>
          </div>
        </div>
      ))}
    </Row>
  )
}

PlanDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  moduleDate: PropTypes.object.isRequired,
}

export default PlanDropdown
