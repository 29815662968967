export const Colors = {
  SCORE: '255, 87, 34',
  EMOM: '156, 39, 176',
  TEXT: '255, 193, 7',
  ROUND: '96, 125, 139',
  COMPLEX: '0, 150, 136',
  EXERCISE: '76, 175, 80',
  REST: '244, 67, 54',
  ScoreModuleComponent: [255, 87, 34],
  EmomModuleComponent: [156, 39, 176],
  TextModuleComponent: [255, 193, 7],
  RoundModuleComponent: [96, 125, 139],
  ComplexModuleComponent: [0, 150, 136],
  ExerciseModuleComponent: [76, 175, 80],
  RestModuleComponent: [244, 67, 54],
  PowerbuildingModuleComponent: [244, 67, 54],
  CROSSFIT: '255, 87, 34',
  WEIGHTLIFTING: '0, 188, 212',
  GYMNASTICS: '3, 169, 244',
  CrossfitExercise: '#ff2d55',
  WeightliftingExercise: '#007aff',
  GymnasticsExercise: '#4cd964',
  crossfit: '#ff2d55',
  weightlifting: '#007aff',
  gymnastics: '#4cd964',
  endurance: '#FDC02F',
  strength: '#00ffff',
  crossfitexercise: '#ff2d55',
  weightliftingexercise: '#007aff',
  gymnasticsexercise: '#4cd964',
}
