import { Button, ControlGroup } from '@blueprintjs/core'
import { Draggable } from 'lib/dragdrop'
import React from 'react'
import { Field } from 'react-final-form'
import ExerciseInput from '../../../ExerciseInput'
import { TextFieldAdapter } from '../../../Form/Adapters'
import { required } from '../../../Form/Validation'
import BeautifulDragHandle from '../../../Layout/BeautifulDragHandle'
import { PositionDisplay } from '../PositionDisplay'
import { getItemStyle } from '../styles'

const ExerciseBlockField = ({ name, fields, index, droppableId }) => {
  const handleRemove = () => fields.remove(index)
  return (
    <Draggable
      key={name}
      type={droppableId}
      draggableId={`${droppableId}-${index}`}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          // color={color}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
        >
          <ControlGroup>
            <BeautifulDragHandle {...provided.dragHandleProps} />
            <PositionDisplay name={name} />
            <Field
              style={{ width: '40px' }}
              name={`${name}.reps`}
              placeholder="reps"
              type="text"
              component={TextFieldAdapter}
              validate={value => !value && 'required'}
              parse={value => (value === '' ? null : value)}
            />
            <Field
              style={{ width: '100px' }}
              component={TextFieldAdapter}
              name={`${name}.preModifier`}
              placeholder="preModifier"
              parse={value => (value === '' ? null : value)}
              type="text"
            />

            <ExerciseInput
              style={{ width: '300px' }}
              name={`${name}.exercise`}
              validate={required}
              placeholder="Movement"
            />

            <Field
              style={{ width: '100px' }}
              component={TextFieldAdapter}
              name={`${name}.postModifier`}
              placeholder="postModifier"
              parse={value => (value === '' ? null : value)}
              type="text"
            />
            <Field
              style={{ width: '80px' }}
              component={TextFieldAdapter}
              name={`${name}.weight`}
              placeholder="Gewicht"
              parse={value => (value === '' ? null : value)}
              type="text"
            />
            <Field
              name={`${name}.comment`}
              fill
              type="text"
              component={TextFieldAdapter}
              parse={value => (value === '' ? null : value)}
              placeholder="Kommentar"
            />
            <Button
              icon="trash"
              intent="danger"
              type="button"
              onClick={handleRemove}
            />
          </ControlGroup>
        </div>
      )}
    </Draggable>
  )
}

export default ExerciseBlockField
