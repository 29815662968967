import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { fetchModule } from '../../ducks/modules'
import Module from '../Module/Module'

class ViewModuleContainer extends React.Component {
  componentDidMount() {
    this.props.fetchModule(this.props.id)
  }

  render() {
    const { id } = this.props
    return <Module id={id} expanded />
  }
}

const enhance = compose(
  withRouter,
  connect(
    null,
    { fetchModule },
  ),
)

export default enhance(ViewModuleContainer)
