import dotProp from 'dot-prop-immutable'
import {
  toggleWeightliftingPremium,
  updateWeightliftingModuleComponent,
} from 'ducks/weightliftingModuleComponents'
import useModuleComponent from 'lib/hooks/useModuleComponent'
import { dropRight } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Colors } from 'lib/Colors'
import WeightliftingComplex from 'components/WeightliftingComplex'
import ModuleComponent from './ModuleComponent'

const WeightliftingModuleComponent = ({ id }) => {
  const dispatch = useDispatch()
  const subscriptionPlans = useSelector(
    ({ subscriptionPlans }) => subscriptionPlans,
  )

  const component = useModuleComponent(id, 'weightlifting')

  const handleRepsChange = (name, value) => {
    const updated = dotProp.set(component, name, value)
    dispatch(updateWeightliftingModuleComponent(updated))
  }

  const handleChange = ({ target: { name, value } }) => {
    const updated = dotProp.set(component, name, value)
    dispatch(updateWeightliftingModuleComponent(updated))
  }

  const handleAddSet = name => {
    const updated = dotProp.set(component, `${name}.sets`, sets =>
      sets.concat({ reps: 0, weight: 0 }),
    )
    dispatch(updateWeightliftingModuleComponent(updated))
  }

  const handleRemoveSet = name => {
    const updated = dotProp.set(component, `${name}.sets`, sets =>
      dropRight(sets),
    )
    dispatch(updateWeightliftingModuleComponent(updated))
  }

  const handleSetPremium = complexIndex => {
    dispatch(
      toggleWeightliftingPremium(
        component,
        complexIndex,
        subscriptionPlans.plans.find(p => p.name === 'titan'),
      ),
    )
  }

  return (
    <ModuleComponent
      title="Weightlifting"
      component={component}
      color={Colors.WEIGHTLIFTING}
    >
      {component.complexes.map((complex, index) => (
        <WeightliftingComplex
          key={index}
          addSet={handleAddSet}
          removeSet={handleRemoveSet}
          index={index}
          name={`complexes.${index}`}
          onChange={handleChange}
          handleRepsChange={handleRepsChange}
          onSetPremium={handleSetPremium}
          {...complex}
        />
      ))}
    </ModuleComponent>
  )
}

export default WeightliftingModuleComponent
