import { Button, ControlGroup, HTMLSelect } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { addModuleDate } from '../../ducks/moduleDates'
import moduleTypes from '../../lib/moduleTypes'

class ModuleDateCreate extends React.Component {
  state = {
    type: 'crossfit',
    subtype: 'Conditioning',
  }

  setType = ({ target: { value } }) =>
    this.setState({
      type: value,
      subtype: ' ',
    })

  setSubtype = ({ target: { value } }) => this.setState({ subtype: value })

  handleAddModuleDate = () => {
    const { addModuleDate, index, date, position } = this.props
    const { type, subtype } = this.state
    addModuleDate({ session: Number(index), date, type, subtype, position })
  }

  render() {
    const { type, subtype } = this.state
    return (
      <ControlGroup>
        <HTMLSelect name="type" value={type} onChange={this.setType}>
          {moduleTypes.types.map(type => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </HTMLSelect>
        <HTMLSelect name="subtype" value={subtype} onChange={this.setSubtype}>
          <option value=" ">Subtyp wählen</option>
          {moduleTypes.subtypes[type].map(type => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </HTMLSelect>
        <Button
          intent="success"
          onClick={this.handleAddModuleDate}
          disabled={!type || !subtype || subtype === ' '}
        >
          Modul hinzufügen
        </Button>
      </ControlGroup>
    )
  }
}

ModuleDateCreate.propTypes = {
  addModuleDate: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
}

const enhance = compose(
  connect(null, {
    addModuleDate,
  }),
)

export default enhance(ModuleDateCreate)
