import PropTypes from 'prop-types'
import React from 'react'
import { Colors } from 'lib/Colors'
import useModuleComponent from 'lib/hooks/useModuleComponent'
import { Button, ButtonGroup, Card, ControlGroup } from '@blueprintjs/core'

import Row from 'components/Layout/StyledComponents/Row'
import { Field, Form } from 'react-final-form'
import { NumberInputAdapter } from 'components/Form/Adapters'
import { useModuleActions } from '../hooks'

export const RestModuleComponent = ({ id, dragHandle }) => {
  const component = useModuleComponent(id, 'rest')
  const { handleRemove, handleSave } = useModuleActions(component)

  return (
    <Card>
      <Form
        onSubmit={handleSave}
        initialValues={component}
        render={({ form, values, handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            {submitError && <div className="error">{submitError}</div>}
            <Row>
              {dragHandle}
              <div style={{ flexGrow: 1 }}>
                <h3>{component.title || component['@type']}</h3>
              </div>
              <ButtonGroup>
                <Button
                  intent="success"
                  icon="floppy-disk"
                  type="submit"
                  loading={submitting}
                />
                {/* <Button
                  icon={this.state.expanded ? 'chevron-up' : 'chevron-down'}
                  onClick={this.toggleExpand}
                /> */}
                {component['@type'] !== 'WeightliftingModuleComponent' && (
                  <Button icon="trash" intent="danger" onClick={handleRemove} />
                )}
              </ButtonGroup>
            </Row>
            <ControlGroup
              style={{
                alignItems: 'flex-end',
                marginTop: '8px',
                marginBottom: '8px',
              }}
              fill
            >
              <Field
                name="duration"
                type="text"
                label="Dauer"
                format={value => value / 60}
                parse={value => value * 60}
                component={NumberInputAdapter}
              />
            </ControlGroup>
          </form>
        )}
      />
    </Card>

    // <ModuleComponent
    //   title="Rest"
    //   component={component}
    //   fields={[{ name: 'duration', label: 'Dauer', type: 'duration' }]}
    //   color={Colors.REST}
    // />
  )
}

RestModuleComponent.propTypes = {
  id: PropTypes.number.isRequired,
}

export default RestModuleComponent
