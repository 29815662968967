import { DateTime } from 'luxon'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Row from '../Layout/StyledComponents/Row'
import ScheduleButton from './ScheduleButton'

const Container = styled(Row)`
  margin: -20px -20px 20px;
  padding: 10px;
  color: rgba(0, 0, 0, 0.37);
`

const Highlighted = styled.div`
  color: rgba(0, 0, 0, 0.87);
`

const Important = styled.div`
  color: red;
  font-weight: bold;
`

const ModuleDateInfo = ({
  id,
  updatedAt,
  createdAt,
  willBeAnnouncedAt,
  date,
  module: moduleId,
  dragHandle,
}) => {
  const module = useSelector(
    ({ calendar }) => calendar.entities.modules[moduleId],
  )
  return (
    <div>
      <Container>
        <div>{`ID: ${id}`}</div>
        <div>{`Datum: ${DateTime.fromISO(date).toLocaleString(
          DateTime.DATE_SHORT,
        )}`}</div>
        <div>
          {`Erstellt: ${DateTime.fromISO(createdAt).toLocaleString(
            DateTime.DATETIME_MED,
          )}`}
        </div>
        <div>
          {`Aktualisiert: ${DateTime.fromISO(updatedAt).toLocaleString(
            DateTime.DATETIME_MED,
          )}`}
        </div>
        {willBeAnnouncedAt && (
          <Important>
            {`Zu aktualisieren am: ${DateTime.fromISO(
              willBeAnnouncedAt,
            ).toLocaleString(DateTime.DATETIME_FULL)}`}
          </Important>
        )}
        <ScheduleButton
          moduleDateId={id}
          moduleDateDate={date}
          willBeAnnouncedAt={willBeAnnouncedAt}
        />
      </Container>
      <Container>
        <Highlighted>{dragHandle}</Highlighted>
        <Highlighted>{`${module.type} - ${module.subtype}`}</Highlighted>
        <Highlighted>{module.name}</Highlighted>
      </Container>
    </div>
  )
}

export default ModuleDateInfo
