import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import tfcApp from './reducers'
import {
  fetchStateSaga,
  watchForExerciseBlockChange,
  watchForFetchError,
  watchForMarkAsDirty,
  watchInputForComponent,
} from './sagas'
import { getMicrocyclesSaga } from './sagas/calendar'
import {
  createComponentSaga,
  removeComponentSaga,
  resortComponentSaga,
  saveComponentSaga,
} from './sagas/components'
import { evaluationSaga } from './sagas/evaluation'
import {
  // createModuleSaga,
  fetchModuleSaga,
  removeModuleSaga,
  saveModuleSaga,
} from './sagas/modules'
import moduleDateSaga from './sagas/moduleDates'
import { fetchPostsSaga } from './sagas/posts'
import { fetchUsersSaga } from './sagas/users'
import crashReporter from './crashReporter'

const sentryMiddleware = crashReporter

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default initialState => {
  const store = createStore(
    tfcApp,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, sentryMiddleware)),
  )

  sagaMiddleware.run(fetchModuleSaga)
  sagaMiddleware.run(fetchStateSaga)
  sagaMiddleware.run(watchInputForComponent)
  sagaMiddleware.run(watchForFetchError)
  sagaMiddleware.run(watchForExerciseBlockChange)
  sagaMiddleware.run(watchForMarkAsDirty)
  sagaMiddleware.run(saveComponentSaga)
  sagaMiddleware.run(createComponentSaga)
  sagaMiddleware.run(removeComponentSaga)
  // sagaMiddleware.run(createModuleSaga)
  sagaMiddleware.run(removeModuleSaga)
  sagaMiddleware.run(saveModuleSaga)
  sagaMiddleware.run(getMicrocyclesSaga)
  sagaMiddleware.run(resortComponentSaga)
  sagaMiddleware.run(fetchPostsSaga)
  sagaMiddleware.run(fetchUsersSaga)
  sagaMiddleware.run(evaluationSaga)
  // sagaMiddleware.run(createModuleDateSaga)
  // sagaMiddleware.run(fetchModuleDateSaga)
  sagaMiddleware.run(moduleDateSaga)

  return store
}
