import { Dialog } from '@blueprintjs/core'
import Login from 'components/login/Login'
import { authState } from 'lib/atoms/authState'
import React from 'react'
import { useRecoilValue } from 'recoil'

const LoginDialog = () => {
  const { token } = useRecoilValue(authState)

  if (!token) {
    return (
      <Dialog isOpen usePortal icon="info-sign" title="Einloggen">
        <Login />
      </Dialog>
    )
  }
  return null
}

export default LoginDialog
