import { Menu, MenuItem } from '@blueprintjs/core'
import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import ModuleTemplateApp from './ModuleTemplateApp'

import MicrocycleTemplateContainer from './MicrocycleTemplates/MicrocycleTemplateContainer'
import MicrocycleTemplateListContainer from './MicrocycleTemplates/MicrocycleTemplateListContainer'
import MesocycleTemplateListContainer from './MesocycleTemplates/MesocycleTemplateListContainer'
import MesocycleTemplateContainer from './MesocycleTemplates/MesocycleTemplateContainer'
import moduleTypes from '../../lib/moduleTypes'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr;
  grid-gap: 16px;
`

const LinkMenuItem = ({ label, ...props }) => (
  <Link className="bp3-menu-item" {...props}>
    {label}
  </Link>
)

const TemplateRouter = () => (
  <Grid>
    <Menu>
      <MenuItem text="Module">
        <LinkMenuItem label="Alle" to="/templates/modules" />
        {moduleTypes.types.map(type => (
          <MenuItem key={type.value} text={type.label}>
            <LinkMenuItem
              label="Alle"
              to={`/templates/modules/${type.value}`}
            />
            {moduleTypes.subtypes[type.value].map(subtype => (
              <LinkMenuItem
                key={subtype.value}
                label={subtype.label}
                to={`/templates/modules/${type.value}/${subtype.value}`}
              />
            ))}
          </MenuItem>
        ))}
      </MenuItem>

      <LinkMenuItem label="Microzyklen" to="/templates/microcycles" />
      <LinkMenuItem label="Mesozyklen" to="/templates/mesocycles" />
    </Menu>
    <Switch>
      <Route
        path="/templates/modules/:type?/:subtype?"
        component={ModuleTemplateApp}
      />
      <Route
        path="/templates/microcycles"
        exact
        component={MicrocycleTemplateListContainer}
      />
      <Route
        path="/templates/microcycles/:id"
        exact
        component={MicrocycleTemplateContainer}
      />
      <Route
        path="/templates/mesocycles"
        exact
        component={MesocycleTemplateListContainer}
      />

      <Route
        path="/templates/mesocycles/:id"
        exact
        component={MesocycleTemplateContainer}
      />
    </Switch>
  </Grid>
)

export default TemplateRouter
