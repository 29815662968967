import update from 'immutability-helper'
import { findIndex } from 'lodash'

export const SET_SUBSCRIPTION_PLANS = 'SET_SUBSCRIPTION_PLANS'
export const TOGGLE_SUBSCRIPTION_PLAN = 'TOGGLE_SUBSCRIPTION_PLAN'
export const SET_ALL_SUBSCRIPTION_PLANS_ACTIVE =
  'SET_ALL_SUBSCRIPTION_PLANS_ACTIVE'
export const TOGGLE_SHOW_ALL = 'TOGGLE_SHOW_ALL'

export const toggleSubscriptionPlan = ({ id }) => ({
  type: TOGGLE_SUBSCRIPTION_PLAN,
  id,
})

export const setAllPlansActive = () => ({
  type: SET_ALL_SUBSCRIPTION_PLANS_ACTIVE,
})

export const toggleShowAll = () => ({
  type: TOGGLE_SHOW_ALL,
})

const reducer = (state = { plans: [], showAll: false }, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION_PLANS: {
      return {
        plans: action.subscriptionPlans.map(plan => ({
          ...plan,
          active: true,
        })),
      }
    }
    case TOGGLE_SUBSCRIPTION_PLAN: {
      const idx = findIndex(state.plans, p => p.id === action.id)
      return update(state, {
        plans: { [idx]: { active: { $set: !state.plans[idx].active } } },
      })
    }
    case SET_ALL_SUBSCRIPTION_PLANS_ACTIVE: {
      return update(state, {
        plans: {
          $apply: plans => plans.map(plan => ({ ...plan, active: true })),
        },
      })
    }
    case TOGGLE_SHOW_ALL: {
      return {
        ...state,
        showAll: !state.showAll,
      }
    }
    default:
      return state
  }
}

export default reducer
