import * as Sentry from '@sentry/browser'

const crashReporter = store => next => action => {
  try {
    return next(action) // dispatch
  } catch (err) {
    console.error('Caught an exception!', err)
    Sentry.captureException(err, {
      // send to crash reporting tool
      extra: {
        action,
        state: store.getState(), // dump application state
      },
    })
    throw err // re-throw error
  }
}

export default crashReporter
