import { useMutation, useQuery } from '@apollo/react-hooks'
import { Classes, Drawer, Spinner } from '@blueprintjs/core'
import GraphQLErrors from 'components/Common/GraphQLErrors'
import gql from 'graphql-tag'
import { AppToaster } from 'lib/toaster'
import React from 'react'
import { useHistory } from 'react-router-dom'
import CrossfitExercise from './CrossfitExercise'
import './fix.css'
import GymnasticsExercise from './GymnasticsExercise'
import WeightliftingExercise from './WeightliftingExercise'

const GET_EXERCISE = gql`
  query exercise($id: ID!) {
    exercise(id: $id) {
      ... on IExercise {
        id
        name
        category
        discr
        pluralName
        description
        quillDescription
        createdAt
        updatedAt
        femaleScalingFactor
        groups {
          id
          name
          type
        }
      }
    }
    exerciseGroups {
      id
      name
      type
    }
  }
`

const UPDATE_EXERCISE = gql`
  mutation updateExercise($data: UpdateExerciseInput!) {
    updateExercise(data: $data) {
      ... on IExercise {
        id
      }
    }
  }
`

const ExerciseContainer = ({
  match: {
    params: { id },
  },
}) => {
  const { data, error, loading } = useQuery(GET_EXERCISE, {
    variables: { id: Number(id) },
  })

  const [submit] = useMutation(UPDATE_EXERCISE, {
    refetchQueries: ['exercise', 'exercises'],
  })

  const history = useHistory()

  const handleSave = async ({ createdAt, updatedAt, __typename, ...data }) => {
    try {
      await submit({
        variables: {
          data: {
            ...data,
            groups: data.groups.map(({ id, name, type }) => ({
              id,
              name,
              type,
            })),
          },
        },
      })
      AppToaster.show({
        message: 'Gespeichert',
        intent: 'success',
      })
    } catch (e) {
      console.log(e)
      AppToaster.show({
        message: 'Fehler beim Speichern',
        intent: 'warning',
      })
    }
  }

  if (error) {
    return (
      <GraphQLErrors error={error}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </GraphQLErrors>
    )
  }

  let Component = null

  if (data) {
    const { exercise, exerciseGroups } = data

    switch (exercise.discr) {
      case 'crossfitexercise':
        Component = (
          <CrossfitExercise
            exercise={exercise}
            groups={exerciseGroups}
            handleSave={handleSave}
          />
        )
        break
      case 'weightliftingexercise':
        Component = (
          <WeightliftingExercise
            exercise={exercise}
            groups={exerciseGroups}
            handleSave={handleSave}
          />
        )
        break
      case 'gymnasticsexercise':
        Component = (
          <GymnasticsExercise
            exercise={exercise}
            groups={exerciseGroups}
            handleSave={handleSave}
          />
        )
        break
      default:
        return null
    }
  }

  return (
    <Drawer
      isOpen
      size={Drawer.SIZE_LARGE}
      title={data ? data.exercise.name : ''}
      onClose={() => history.push('/exercises')}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          {loading ? <Spinner /> : Component}
        </div>
      </div>
    </Drawer>
  )
}

export default ExerciseContainer
