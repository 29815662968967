import update from 'immutability-helper'
import { DateTime } from 'luxon'

export const SET_LOGS = 'SET_LOGS'
export const SET_COMPONENTS = 'EVALUATION::SET_COMPONENTS'
export const SET_DETAILED_COMPLEX = 'SET_DETAILED_COMPLEX'
export const SET_FILTER = 'SET_FILTER'
export const EVALUATION_SET_START_DATE = 'EVALUATION::SET_START_DATE'
export const EVALUATION_SET_END_DATE = 'EVALUATION::SET_END_DATE'

export const setLogs = logs => ({
  type: SET_LOGS,
  logs,
})

export const setModuleComponents = components => ({
  type: SET_COMPONENTS,
  components,
})

export const setDetailedComplex = complex => ({
  type: SET_DETAILED_COMPLEX,
  complex,
})

export const setFilter = filter => ({
  type: SET_FILTER,
  filter,
})

export const setStartDate = date => ({
  type: EVALUATION_SET_START_DATE,
  date,
})

export const setEndDate = date => ({
  type: EVALUATION_SET_END_DATE,
  date,
})

const initialState = {
  logs: [],
  filter: {
    id: [],
    startDate: DateTime.local().minus({ months: 1 }),
    endDate: DateTime.local(),
  },
  detail: {
    complex: null,
  },
  components: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGS:
      return {
        ...initialState,
        logs: action.logs,
      }
    case SET_FILTER:
      return update(state, {
        filter: {
          id: { $set: action.filter.id },
        },
      })
    case SET_DETAILED_COMPLEX:
      return update(state, {
        detail: {
          complex: { $set: action.complex.id },
        },
      })
    case EVALUATION_SET_START_DATE:
      return update(state, {
        filter: {
          startDate: { $set: action.date },
        },
      })
    case EVALUATION_SET_END_DATE:
      return update(state, {
        filter: {
          endDate: { $set: action.date },
        },
      })
    case SET_COMPONENTS:
      return update(state, {
        components: { $set: action.components },
      })
    default:
      return state
  }
}

export default reducer
