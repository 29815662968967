import { Radio, RadioGroup } from '@blueprintjs/core'
import { flatten, map, pipe, uniq } from 'ramda'
import React from 'react'
import { branch, renderComponent } from 'recompose'
import styled from 'styled-components'
import CenteredSpinner from '../CenteredSpinner'
import ModuleTemplate from './ModuleTemplate'

const getUniqueTypes = pipe(
  map(m => m.type),
  uniq(),
)

const getUniqueMicrocycles = pipe(
  map(m => m.microcycles),
  flatten,
  uniq(),
)

const Panel = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 16px;
`

const Header = styled.h3`
  grid-column: 1 / -1;
  text-align: center;
`

class ModuleTemplateGrid extends React.Component {
  state = {
    groupBy: 'type',
  }

  setGroupBy = ({ target: { value } }) => {
    this.setState({ groupBy: value })
  }

  render() {
    const { modules, onDelete, loading } = this.props
    return (
      <Panel>
        <div style={{ gridColumn: '1 / -1' }}>
          <RadioGroup
            inline
            label="Gruppieren"
            name="group"
            onChange={this.setGroupBy}
            selectedValue={this.state.groupBy}
          >
            <Radio label="Typ" value="type" />
            <Radio label="Microcycle" value="microcycle" />
          </RadioGroup>
        </div>

        {this.state.groupBy === 'type' &&
          getUniqueTypes(modules).map(type => [
            <Header>{type}</Header>,
            modules
              .filter(m => m.type === type)
              .sort((a, b) => b.id - a.id)
              .map(m => {
                const handleDelete = () => onDelete(m)
                return <ModuleTemplate {...m} onDelete={handleDelete} />
              }),
          ])}
        {this.state.groupBy === 'microcycle' &&
          getUniqueMicrocycles(modules).map(cycle => [
            <Header>{cycle.name}</Header>,
            modules
              .filter(m => m.microcycles.find(c => c.id === cycle.id))
              .sort((a, b) => b.id - a.id)
              .map(m => {
                const handleDelete = () => onDelete(m)
                return <ModuleTemplate {...m} onDelete={handleDelete} />
              }),
          ])}
      </Panel>
    )
  }
}

export default branch(
  ({ loading }) => loading,
  renderComponent(CenteredSpinner),
)(ModuleTemplateGrid)
