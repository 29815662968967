import { Button, ButtonGroup, Card } from '@blueprintjs/core'
import Row from 'components/Layout/StyledComponents/Row'
import useModuleComponent from 'lib/hooks/useModuleComponent'
import React from 'react'
import { Field, Form } from 'react-final-form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useModuleActions } from '../hooks'

const TextModuleComponent = ({ id, dragHandle }) => {
  const component = useModuleComponent(id, 'text')

  const { handleRemove, handleSave } = useModuleActions(component)

  return (
    <Card>
      <Form
        onSubmit={handleSave}
        initialValues={component}
        render={({ form, values, handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            {submitError && <div className="error">{submitError}</div>}
            <Row>
              {dragHandle}
              <div style={{ flexGrow: 1 }}>
                <h3>{component.title || component['@type']}</h3>
              </div>
              <ButtonGroup>
                <Button
                  intent="success"
                  icon="floppy-disk"
                  type="submit"
                  loading={submitting}
                />
                {/* <Button
                  icon={this.state.expanded ? 'chevron-up' : 'chevron-down'}
                  onClick={this.toggleExpand}
                /> */}
                {component['@type'] !== 'WeightliftingModuleComponent' && (
                  <Button icon="trash" intent="danger" onClick={handleRemove} />
                )}
              </ButtonGroup>
            </Row>
            <Field
              name="htmlContent"
              render={({ input }) => (
                <ReactQuill
                  theme="snow"
                  value={input.value}
                  onChange={input.onChange}
                  modules={TextModuleComponent.modules}
                  formats={TextModuleComponent.formats}
                />
              )}
            />
          </form>
        )}
      />
    </Card>
  )
}

TextModuleComponent.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
}

TextModuleComponent.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'video',
]

export default TextModuleComponent
