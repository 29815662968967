import update from 'immutability-helper'
import { FETCH_USERS_SUCCESS } from '../sagas/users'

const initialState = {
  users: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
      return update(state, { users: { $set: action.users } })

    default:
      return state
  }
}

export default reducer
