import React from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import VideosApp from 'components/Apps/VideosApp'
import BlogApp from './components/blog/BlogApp'
import WeightliftingEvaluationApp from './components/Evaluation/Weightlifting/App'
import ExerciseApp from './components/Exercises/ExerciseApp'
import Login from './components/login/Login'
import MessageContainer from './components/MessageContainer'
import ModuleRouter from './components/ModuleApp/ModuleRouter'
import Menu from './components/Navigation/Menu'
import TemplateRouter from './components/Templates/TemplateRouter'
import UserApp from './components/Users/UserApp'
import WeightliftingApp from './components/Weightlifting/WeightliftingApp'
import StrengthPlanRouter from './components/StrengthPlan/StrengthPlanRouter'
import PowerbuildingRouter from './components/Powerbuilding/Router'
import PlanApp from './PlanApp'
import ExerciseCategorizer from './components/ExerciseCategorizer/ExerciseCategorizer'
import WeightliftingOverview from './components/WeightliftingOverview/WeightliftingOverview'

import LoginDialog from './components/login/LoginDialog'

const AppGrid = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
`

const TopBar = styled.div`
  grid-column: 1 / -1;
`

export default () => (
  <>
    <LoginDialog />
    <div>
      <div>
        <Menu />
      </div>
      <div>
        <MessageContainer />
        <Switch>
          <Route path="/plan" component={PlanApp} />
          <Route path="/templates" component={TemplateRouter} />
          <Route path="/module" component={ModuleRouter} />
          <Route path="/strengthplans" component={StrengthPlanRouter} />
          <Route path="/exercises" component={ExerciseApp} />
          <Route path="/powerbuilding" component={PowerbuildingRouter} />
          <Route path="/weightlifting" component={WeightliftingApp} />
          <Route
            path="/weightlifting-overview"
            component={WeightliftingOverview}
          />
          <Route path="/exercise-categorize" component={ExerciseCategorizer} />
          <Route
            path="/evaluation/weightlifting"
            component={WeightliftingEvaluationApp}
          />
          <Route path="/users" component={UserApp} />
          <Route path="/blog" component={BlogApp} />
          <Route path="/videos" component={VideosApp} />
        </Switch>
      </div>
    </div>
  </>
)
