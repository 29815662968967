/* global fetch */
import React from 'react'
import { compose, lifecycle, withProps } from 'recompose'
import { sortBy } from 'lodash'
import { flatMapDeep, flow, uniqBy } from 'lodash/fp'
import { connect } from 'react-redux'
import { withSpinnerWhileCondition } from '../../Layout/withSpinnerWhileLoading'
import Component from './Component'
import Controls from './Controls/Controls'
import { fetchModuleComponentsRequest } from '../../../sagas/evaluation'

const withLifecycle = lifecycle({
  async componentWillMount() {
    this.props.fetchModuleComponentsRequest()
  },
})

const WeightliftingEvaluationApp = ({ components, athleteIds }) => (
  <div>
    <div className="grid-x grid-padding-x">
      <div className="cell small-8">
        <div className="grid-x grid-margin-x small-up-1 medium-up-1 large-up-2">
          {sortBy(components, 'date').map(c => (
            <Component key={c.id} component={c} />
          ))}
        </div>
      </div>
      <div className="cell small-4">
        <Controls />
      </div>
    </div>
  </div>
)

const uniqueAthleteIds = flow(
  flatMapDeep(c => c.logs.map(l => ({ id: l.athleteDetails.id }))),
  uniqBy('id'),
)

const enhance = compose(
  connect(
    ({ evaluation: { components } }) => ({ components }),
    {
      fetchModuleComponentsRequest,
    },
  ),
  withLifecycle,
  withProps(({ components }) => ({
    athleteIds: uniqueAthleteIds(components),
  })),
  withSpinnerWhileCondition(({ components }) => !components),
)

export default enhance(WeightliftingEvaluationApp)
