import merge from 'deepmerge'
import produce from 'immer'
import update from 'immutability-helper'
import { normalize } from 'normalizr'
import { calendarModuleDate } from '../lib/normalize'
import {
  CREATE_MODULE_DATE_SUCCESS,
  REMOVE_MODULE_DATE_SUCCESS,
  FETCH_MODULE_DATE_SUCCESS,
  TOGGLE_MODULE_DATE_SUBSCRIPTION_PLANS,
} from './moduleDates'
import { UPDATE_MODULE } from './modules'
import { UPDATE_MODULE_DATE } from 'ducks/moduleDates'
import { uniq } from 'lodash'

export const FETCH_CALENDAR_MODULE_DATES_REQUEST =
  'FETCH_CALENDAR_MODULE_DATES_REQUEST'
export const FETCH_CALENDAR_MODULE_DATES_SUCCESS =
  'FETCH_CALENDAR_MODULE_DATES_SUCCESS'
export const FETCH_CALENDAR_MODULE_DATES_FAIL =
  'FETCH_CALENDAR_MODULE_DATES_FAIL'

export const fetchModuleDatesBetween = (start, end) => ({
  type: FETCH_CALENDAR_MODULE_DATES_REQUEST,
  payload: { start, end },
})

const reducer = (
  state = { result: [], entities: { moduleDates: {}, modules: {} } },
  action,
) => {
  switch (action.type) {
    case FETCH_CALENDAR_MODULE_DATES_SUCCESS: {
      const { entities, result, types } = action
      return produce(state, draft => {
        return {
          entities: {
            moduleDates: {
              ...draft.entities.moduleDates,
              ...entities.moduleDates,
            },
            modules: { ...draft.entities.modules, ...entities.modules },
          },
          result: uniq([...draft.result, ...result]),
        }
      })
    }
    case UPDATE_MODULE_DATE:
      if (!state.entities.moduleDates[action.moduleDate.id]) {
        return state
      }
      return produce(state, draft => {
        const md = draft.entities.moduleDates[action.moduleDate.id]
        draft.entities.moduleDates[action.moduleDate.id] = {
          ...md,
          ...action.moduleDate,
        }
      })

    case UPDATE_MODULE:
      if (!state.entities.modules[action.module.id]) {
        return state
      }
      return update(state, {
        entities: {
          modules: {
            [action.module.id]: { $merge: { ...action.module } },
          },
        },
      })
    case TOGGLE_MODULE_DATE_SUBSCRIPTION_PLANS:
      if (!state.entities.moduleDates[action.payload.moduleDate.id]) {
        return state
      }

      return produce(state, draft => {
        let md = draft.entities.moduleDates[action.payload.moduleDate.id]

        if (md) {
          draft.entities.moduleDates[action.payload.moduleDate.id] = {
            ...md,
            subscriptionPlans: action.payload.plans,
          }
        }
      })

    case CREATE_MODULE_DATE_SUCCESS:
    case FETCH_MODULE_DATE_SUCCESS:
      return produce(state, draft => {
        const normalized = normalize(action.moduleDate, calendarModuleDate)
        const { entities } = draft

        draft.entities = merge(entities, normalized.entities)
        draft.result.push(normalized.result)
      })
    case REMOVE_MODULE_DATE_SUCCESS: {
      return produce(state, draft => {
        draft.result = draft.result.filter(id => id !== action.moduleDate.id)
        delete draft.entities.moduleDates[action.moduleDate.id]
      })
    }

    default:
      return state
  }
}

export default reducer
