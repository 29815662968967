import { Spinner } from '@blueprintjs/core'
import { get } from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Row from '../../Layout/StyledComponents/Row'
import Dates from './Dates'

const Container = styled(Row)`
  /* margin: -20px -20px 20px; */
  padding: 10px;
  color: rgba(0, 0, 0, 0.37);
`

const ModuleInfo = ({ id }) => {
  const module = useSelector(({ modules }) => get(modules, id))

  if (!module) {
    return <Spinner />
  }

  const { name, updatedAt, createdAt, copyOf } = module

  return (
    <Container>
      <div>{`ID: ${id}`}</div>

      <div>
        {`Erstellt:
                ${DateTime.fromISO(createdAt).toLocaleString(
                  DateTime.DATETIME_MED,
                )}`}
      </div>

      <div>
        {`Aktualisiert:
                ${DateTime.fromISO(updatedAt).toLocaleString(
                  DateTime.DATETIME_MED,
                )}`}
      </div>
      <div>{`Name: ${name || 'kein Name'}`}</div>
      <div>{copyOf ? `Kopie von: ${copyOf.id}` : 'Keine Kopie'}</div>
      <Dates id={id} />
    </Container>
  )
}

export default ModuleInfo
