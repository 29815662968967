import update from 'immutability-helper'
import { normalize } from 'normalizr'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { dispatchFetchError, FETCH_END, FETCH_START } from '../ducks/fetch'
import {
  CREATE_MODULE_FAIL,
  CREATE_MODULE_REQUEST,
  CREATE_MODULE_SUCCESS,
  FETCH_MODULE_FAIL,
  FETCH_MODULE_REQUEST,
  FETCH_MODULE_SUCCESS,
  REMOVE_MODULE_FAIL,
  REMOVE_MODULE_REQUEST,
  REMOVE_MODULE_SUCCESS,
  SAVE_MODULE_FAIL,
  SAVE_MODULE_REQUEST,
  SAVE_MODULE_SUCCESS,
} from '../ducks/modules'
import {
  removeModule,
  saveModule,
  // createModule,
  fetchModule,
} from '../lib/fetch/modules'

import { arrayOfModules, denormalizeModule } from '../lib/normalize'
import { getCommonData } from '../sagas'
import { SET_MODULE } from '../ducks/common'

function* fetchModuleRequest({ payload: { id } }) {
  try {
    const { data: module } = yield call(fetchModule, id)
    yield call(getCommonData)

    const normalizedModules = normalize([module], arrayOfModules)

    yield put({
      type: SET_MODULE,
      plan: normalizedModules,
    })

    yield put({ type: FETCH_MODULE_SUCCESS })
  } catch (err) {
    console.log(err)
    yield put({ type: FETCH_MODULE_FAIL })
    yield put(dispatchFetchError({ endpoint: 'day', error: err }))
  }
}

export function* fetchModuleSaga() {
  yield takeEvery(FETCH_MODULE_REQUEST, fetchModuleRequest)
}

const getDenormalizedModule = (state, module) =>
  denormalizeModule(module, state)

function* saveModuleRequest({ module }) {
  try {
    yield put({ type: FETCH_START, module })
    let denormalizedModule = yield select(getDenormalizedModule, module)
    denormalizedModule = update(denormalizedModule, {
      components: {
        $apply: components =>
          components.map(c => ({ '@id': c['@id'], position: c.position })),
      },
    })

    yield call(saveModule, denormalizedModule)
    yield put({ type: SAVE_MODULE_SUCCESS, module })
    yield put({ type: FETCH_END, module })
  } catch (error) {
    yield put({ type: SAVE_MODULE_FAIL, message: error.message })
    yield put(dispatchFetchError({ endpoint: 'module', error, module }))
  }
}

export function* saveModuleSaga() {
  yield takeEvery(SAVE_MODULE_REQUEST, saveModuleRequest)
}

function* removeModuleRequest({ session, module }) {
  try {
    yield put({ type: FETCH_START, session, module })
    yield call(removeModule, module)
    yield put({ type: REMOVE_MODULE_SUCCESS, session, module })
    yield put({ type: FETCH_END, session, module })
  } catch (error) {
    yield put({ type: REMOVE_MODULE_FAIL, message: error.message })
    yield put(
      dispatchFetchError({ endpoint: 'module', error, session, module }),
    )
  }
}

export function* removeModuleSaga() {
  yield takeEvery(REMOVE_MODULE_REQUEST, removeModuleRequest)
}
