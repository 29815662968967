import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../lib/Colors'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const Inner = styled.div`
  height: 9px;
  display: flex;
  justify-content: center;
`

const Dot = styled.i`
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  margin: 2px;
  background-color: ${props => Colors[props.type]};
`

const Dots = ({ crossfit, weightlifting, gymnastics, endurance, empty }) => (
  <Container>
    <Inner>
      {crossfit && <Dot type="crossfit" />}
      {weightlifting && <Dot type="weightlifting" />}
      {gymnastics && <Dot type="gymnastics" />}
      {endurance && <Dot type="endurance" />}
    </Inner>
  </Container>
)

export default Dots
