import { Radio, RadioGroup, Checkbox, Label } from '@blueprintjs/core'
import { ResponsiveBar } from '@nivo/bar'
import humanizeDuration from 'humanize-duration'
import { find, sum, uniq } from 'lodash'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import React, { useMemo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import produce from 'immer'
import styled from 'styled-components/macro'

const ControlsContainer = styled.div`
  display: flex;
`

const StatsContainer = styled.div`
  height: 300px;
  padding: 16px;
`

const mapStateToProps = ({ fetch, calendar, subscriptionPlans }) => ({
  fetch: fetch.calendar > 0,
  calendar,
  subscriptionPlans,
})

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
)

const Statistics = ({
  calendar,
  subscriptionPlans,
  match: {
    params: { date },
  },
}) => {
  const [plans, setPlans] = useState([])
  const [period, setPeriod] = useState('week')

  const togglePlan = (id, active) =>
    setPlans(plans =>
      produce(plans, draft => {
        draft.find(p => p.id === id).active = active
      }),
    )

  useEffect(
    () => {
      setPlans(subscriptionPlans.plans)
    },
    [subscriptionPlans],
  )

  const filtered = useMemo(
    () =>
      calendar.result
        .map(id => calendar.entities.moduleDates[id])
        .filter(
          moduleDate =>
            DateTime.fromISO(moduleDate.date).hasSame(
              DateTime.local(),
              period,
            ) &&
            moduleDate.subscriptionPlans.some(p =>
              plans.find(plan => plan['@id'] === p && plan.active),
            ),
        )

        .map(moduleDate => calendar.entities.modules[moduleDate.module]),
    [calendar.result, period, date, plans],
  )

  const total = useMemo(() => sum(filtered.map(m => m.duration)), [filtered])

  const subtypes = useMemo(() => uniq(filtered.map(m => m.subtype)), [filtered])

  const durations = useMemo(
    () =>
      filtered.reduce((acc, module) => {
        const { type, subtype } = module
        const duration = module.duration / 60

        const entry = find(acc, { type })
        if (entry) {
          entry[subtype]
            ? (entry[subtype] += parseInt(duration))
            : (entry[subtype] = parseInt(duration))
        } else {
          acc.push({ type, [subtype]: parseInt(duration) })
        }
        return acc
      }, []),
    [filtered],
  )

  return (
    <StatsContainer>
      <ControlsContainer>
        <RadioGroup
          inline
          label="Zeitraum"
          onChange={({ target: { value } }) => setPeriod(value)}
          selectedValue={period}
        >
          <Radio label="Monat" value="month" />
          <Radio label="Woche" value="week" />
          <Radio label="Tag" value="day" />
        </RadioGroup>

        <div>
          <Label>Plan</Label>
          {plans.map(p => (
            <Checkbox
              inline
              checked={p.active}
              label={p.name}
              onChange={({ target: { checked } }) => togglePlan(p.id, checked)}
            />
          ))}
        </div>
      </ControlsContainer>
      <ResponsiveBar
        data={durations}
        keys={subtypes}
        indexBy="type"
        margin={{ top: 50, right: 130, bottom: 50, left: 100 }}
        padding={0.3}
        layout="horizontal"
        colors={{ scheme: 'nivo' }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'minutes',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        animate
        motionStiffness={90}
        motionDamping={15}
      />
      <DurationRow type="Total" duration={total} />
    </StatsContainer>
  )
}

Statistics.propTypes = {
  modules: PropTypes.array.isRequired,
}

Statistics.defaultProps = {
  modules: [],
}

const EnhancedStatistics = enhance(Statistics)

const DurationRow = ({ type, subtype, duration }) => (
  <div>
    <span>{type}:</span>
    <span>{subtype}</span>
    <span>{humanizeDuration(duration * 1000, { language: 'de' })}</span>
  </div>
)

export default EnhancedStatistics
