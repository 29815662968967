import {
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Popover,
  Position,
} from '@blueprintjs/core'
import PropTypes from 'prop-types'
import React from 'react'
import ModuleDeleteButton from '../../Common/ModuleDeleteButton'
import AssignModuleToDateAction from './AssignModuleToDateAction'
import DuplicateModuleToDateAction from './DuplicateModuleToDateAction'
import CopyModuleToTemplateAction from './CopyModuleToTemplateAction'
import { ModuleActionsContext } from '../ModuleActionsContext'

const ModuleCopyActions = () => (
  <ModuleActionsContext.Consumer>
    {({
      handleCopyModule,
      handleAssignModuleToDate,
      handleDuplicateModuleToDate,
      handleCopyModuleToTemplate,
    }) => (
      <Menu>
        <MenuItem
          text="Als Vorlage speichern"
          onClick={handleCopyModule}
          shouldDismissPopover={false}
        />
        <AssignModuleToDateAction
          handleAssignToDate={handleAssignModuleToDate}
        />
        <DuplicateModuleToDateAction
          handleDuplicateToDate={handleDuplicateModuleToDate}
        />
        <CopyModuleToTemplateAction
          handleCopyToTemplate={handleCopyModuleToTemplate}
        />
        <CopyModuleToTemplateAction
          handleCopyToTemplate={handleCopyModuleToTemplate}
          duplicate
        />
      </Menu>
    )}
  </ModuleActionsContext.Consumer>
)

const ModuleActions = ({
  handleSave,
  expanded,
  reordering,
  dirty,
  handleExpand,
  handleRemove,
  handleReorder,
  hasDelete,
  ...props
}) => (
  <ButtonGroup {...props}>
    <Button
      intent="success"
      icon="floppy-disk"
      onClick={handleSave}
      disabled={!dirty}
    />
    <Button icon="sort" onClick={handleReorder} active={reordering} />

    <Button
      icon={expanded ? 'chevron-up' : 'chevron-down'}
      onClick={handleExpand}
    />
    <Popover content={<ModuleCopyActions />} position={Position.BOTTOM_RIGHT}>
      <Button rightIcon="caret-down" icon="duplicate" />
    </Popover>

    {hasDelete && <ModuleDeleteButton onDelete={handleRemove} />}
  </ButtonGroup>
)

ModuleActions.propTypes = {
  handleSave: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  dirty: PropTypes.bool,
  handleExpand: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  hasDelete: PropTypes.bool,
}

ModuleActions.defaultProps = {
  hasDelete: false,
  dirty: false,
}

export default ModuleActions
