import {
  Button,
  Checkbox,
  FormGroup,
  InputGroup,
  Intent,
  NumericInput,
} from '@blueprintjs/core'
import '@blueprintjs/table/lib/css/table.css'
import produce from 'immer'
import React from 'react'
import { compose, withProps, branch, renderNothing } from 'recompose'
import styled from 'styled-components'
import { round } from 'lodash'
import { withContext } from './StrengthPlanContext'

const ControlGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 4px;
`

class StrengthPlanEditMicrocycle extends React.Component {
  handleUpdate = microcycle => {
    const {
      updateMicrocycle,
      selected: { microIndex, mesoIndex },
    } = this.props
    updateMicrocycle({
      microIndex,
      mesoIndex,
      microcycle,
    })
  }

  handleChangeName = ({ target: { value } }) => {
    const { microcycle } = this.props
    this.handleUpdate(
      produce(microcycle, draft => {
        draft.name = value
      }),
    )
  }

  handleValueChange = (type, index) => value => {
    const { microcycle } = this.props
    this.handleUpdate(
      produce(microcycle, draft => {
        draft.sets[index][type] =
          type === 'weight' ? round(value / 100, 3) : value
      }),
    )
  }

  handleTextValueChange = (type, index) => ({ target: { value } }) => {
    const { microcycle } = this.props
    this.handleUpdate(
      produce(microcycle, draft => {
        draft.sets[index][type] = value
      }),
    )
  }

  handleMaxEffortChange = index => ({ target: { checked } }) => {
    const { microcycle } = this.props
    this.handleUpdate(
      produce(microcycle, draft => {
        draft.sets[index].maxEffort = checked
      }),
    )
  }

  handleAddSet = () => {
    const { microcycle } = this.props
    this.handleUpdate(
      produce(microcycle, draft => {
        draft.sets.push({
          reps: 0,
          weight: 0,
          maxEffort: false,
          note: null,
          position: draft.sets.length,
        })
      }),
    )
  }

  render() {
    const { microcycle } = this.props
    const { sets, name } = microcycle
    return (
      <div>
        <FormGroup label="Name">
          <InputGroup value={name} onChange={this.handleChangeName} />
        </FormGroup>
        <ControlGrid>
          <span>Reps</span>
          <span>Weight</span>
          <span>MaxEffort</span>
          <span>Note</span>
          {sets.map((s, idx) => [
            <NumericInput
              key="reps"
              value={s.reps}
              selectAllOnFocus
              onValueChange={this.handleValueChange('reps', idx)}
            />,
            <NumericInput
              key="weight"
              majorStepSize={10}
              stepSize={1}
              minorStepSize={0.1}
              selectAllOnFocus
              value={round(s.weight * 100, 2)}
              onValueChange={this.handleValueChange('weight', idx)}
            />,
            <Checkbox
              key="max-effort"
              checked={s.maxEffort}
              onChange={this.handleMaxEffortChange(idx)}
            />,
            <InputGroup
              key="note"
              value={s.note || ''}
              onChange={this.handleTextValueChange('note', idx)}
            />,
          ])}
        </ControlGrid>

        <Button
          intent={Intent.SUCCESS}
          minimal
          text="Satz hinzufügen"
          icon="add"
          onClick={this.handleAddSet}
        />
      </div>
    )
  }
}

const enhance = compose(
  withContext,
  withProps(
    ({ selected: { microIndex, mesoIndex }, plan: { mesocycles } }) => ({
      microcycle: mesocycles[mesoIndex].microcycles[microIndex],
    }),
  ),
  branch(({ microcycle }) => !microcycle, renderNothing),
)

export default enhance(StrengthPlanEditMicrocycle)
