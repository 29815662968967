import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchModule } from 'ducks/modules'
import Module from './Module'

const EditModuleContainer = ({ id }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchModule(id))
  }, [dispatch, id])

  return <Module id={id} expanded />
}

export default EditModuleContainer
