import React from 'react'

import { Switch, Route } from 'react-router-dom'
import EditModuleContainer from './EditModuleContainer'
import ViewModuleContainer from './ViewModuleContainer'

const ModuleRouter = () => (
  <Switch>
    <Route path="/module/:id/edit" component={EditModuleContainer} />
    <Route path="/module/:id" exact component={ViewModuleContainer} />
  </Switch>
)

export default ModuleRouter
