export const SET_MESSAGE = 'SET_MESSAGE'

export const setMessage = message => ({
  type: SET_MESSAGE,
  message,
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        type: action.message.type,
        message: action.message.message,
      }
    default:
      return state
  }
}

export default reducer
