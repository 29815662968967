import React from 'react'
import {
  fetchTemplateMicrocycles,
  createTemplateMicrocycle,
} from '../../../lib/fetch/templates'
import TemplateListContainer from '../Generic/TemplateListContainer'

export default () => (
  <TemplateListContainer
    fetchFunction={fetchTemplateMicrocycles}
    createFunction={createTemplateMicrocycle}
    type="microcycles"
  />
)
