import { Button, Classes, Dialog, Intent, MenuItem } from '@blueprintjs/core'
import { DatePicker } from '@blueprintjs/datetime'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import React from 'react'
import { Toggle, Value } from 'react-powerplug'
import CenteredRow from '../../Layout/StyledComponents/CenteredRow'
import LuxonLocaleUtils from '../../../lib/LuxonLocaleUtils'

const DuplicateModuleToDateAction = ({ handleDuplicateToDate }) => (
  <Toggle>
    {({ on, toggle }) => (
      <div>
        <MenuItem
          text="Als neues Modul an Datum einfügen"
          onClick={toggle}
          shouldDismissPopover={false}
        />
        <Dialog isOpen={on}>
          <Value initial={null}>
            {({ value, set }) => {
              const onCopy = () => {
                handleDuplicateToDate(
                  DateTime.fromISO(value.toISOString()).toISODate(),
                )
                toggle()
              }

              return (
                <div>
                  <div className={Classes.DIALOG_BODY}>
                    <CenteredRow>
                      <DatePicker
                        locale="de"
                        canClearSelection={false}
                        localeUtils={LuxonLocaleUtils}
                        className={Classes.ELEVATION_1}
                        onChange={value => set(value)}
                        dayPickerProps={{
                          showWeekNumbers: true,
                        }}
                        maxDate={DateTime.local()
                          .plus({ years: 1 })
                          .toJSDate()}
                      />
                    </CenteredRow>
                  </div>
                  <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                      <Button onClick={toggle}>abbrechen</Button>
                      <Button
                        disabled={!value}
                        intent={Intent.PRIMARY}
                        onClick={onCopy}
                      >
                        Kopieren
                      </Button>
                    </div>
                  </div>
                </div>
              )
            }}
          </Value>
        </Dialog>
      </div>
    )}
  </Toggle>
)

DuplicateModuleToDateAction.propTypes = {
  handleDuplicateToDate: PropTypes.func.isRequired,
}

export default DuplicateModuleToDateAction
