import BeautifulDragHandle from 'components/Layout/BeautifulDragHandle'
import { DragDropContext, Draggable, Droppable } from 'lib/dragdrop'
import React, { useCallback, useState } from 'react'
import arrayMove from 'array-move'
import { Card, Button } from '@blueprintjs/core'
import EmomModuleComponent from './ModuleComponents/EmomModuleComponent'
import RestModuleComponent from './ModuleComponents/RestModuleComponent'
import PowerbuildingModuleComponent from './ModuleComponents/PowerbuildingModuleComponent'
import RoundModuleComponent from './ModuleComponents/RoundModuleComponent'
import ScoringModuleComponent from './ModuleComponents/ScoringModuleComponent'
import StrengthModuleComponent from './ModuleComponents/StrengthModuleComponent'
import TextModuleComponent from './ModuleComponents/TextModuleComponent'
import WeightliftingModuleComponent from './ModuleComponents/WeightliftingModuleComponent'

const cTypes = {
  RoundModuleComponent,
  EmomModuleComponent,
  TextModuleComponent,
  ScoringModuleComponent,
  RestModuleComponent,
  WeightliftingModuleComponent,
  StrengthModuleComponent,
  PowerbuildingModuleComponent,
}

const grid = 4

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const ModuleComponent = ({ id, schema, position, reordering }) => {
  const ComponentType = cTypes[schema]

  if (!ComponentType) {
    return null
  }

  if (!reordering) {
    return <ComponentType id={id} />
  }
  return (
    <div>
      <Draggable key={id} draggableId={`${schema}-${id}`} index={position}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            // color={color}
            {...provided.draggableProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}
          >
            <Card id={id} {...provided.dragHandleProps}>
              <h3>{schema}</h3>
            </Card>
          </div>
        )}
      </Draggable>
    </div>
  )
}

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
})

const Components = ({
  components,
  onSortEnd,
  onRemoveComponent,
  reordering,
}) => {
  if (!reordering) {
    return (
      <div>
        {components.map(c => (
          <ModuleComponent
            {...c}
            key={c.id}
            onRemove={onRemoveComponent}
            reordering={reordering}
          />
        ))}
      </div>
    )
  }

  return (
    <Droppable onDragEnd={onSortEnd} droppableId="components">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {components.map(c => (
            <ModuleComponent
              {...c}
              key={c.id}
              onRemove={onRemoveComponent}
              reordering={reordering}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default Components
