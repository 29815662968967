import { Button } from '@blueprintjs/core'
import React from 'react'
import { useSelector } from 'react-redux'

const NewModuleComponentButton = ({ onClick, label, type }) => {
  const loading = useSelector(({ fetch }) => fetch.component > 0)
  const handleClick = () => onClick(type)
  return (
    <Button icon="new-link" onClick={handleClick} loading={loading}>
      {label}
    </Button>
  )
}

export default NewModuleComponentButton
