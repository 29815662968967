import {
  Spinner,
  RadioGroup,
  Radio,
  Classes,
  ProgressBar,
  ButtonGroup,
  Button,
} from '@blueprintjs/core'
import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link, Route, Switch, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import styled from 'styled-components/macro'
import { getExercises, saveExercise } from '../../lib/fetchService'
import { Form } from 'react-final-form'

const Container = styled.div`
  margin: 0 1em;
  max-width: 600px;

  margin: -0.5em 0;
  > * {
    margin: 0.5em 0;
  }
`

const ExerciseCategorizer = ({ history }) => {
  const [fetching, setFetching] = useState(true)
  const [exercises, setExercises] = useState([])
  const [unmodified, setUnmodified] = useState([])

  const currentExercise = unmodified[0]

  const fetchData = async () => {
    const { data: exercises } = await getExercises({
      'order[updatedAt]': 'asc',
    })
    const allExercises = exercises['hydra:member']
    setExercises(allExercises)
    const unmodified = allExercises.filter(
      ({ femaleScalingFactor }) => femaleScalingFactor === 0,
    )
    setUnmodified(unmodified)
    setFetching(false)
  }

  useEffect(() => {
    try {
      setFetching(true)
      fetchData()
    } catch (error) {
      console.log(error)
      if (error.code === 401 || error.code === 403) {
        history.push('/login')
      }
    }
  }, [])

  const handleSave = value => {
    saveExercise({ ...currentExercise, femaleScalingFactor: value })
    setUnmodified(unmodified.filter(({ id }) => currentExercise.id !== id))
  }

  if (fetching) {
    return <Spinner />
  }
  if (!currentExercise) {
    return <div>no</div>
  }
  return (
    <Container>
      <div>Name: {currentExercise.name}</div>
      <div>Typ: {currentExercise['@type']}</div>

      <ButtonGroup>
        {[1, 0.8, 0.7].map(value => (
          <Button large onClick={() => handleSave(value)}>
            {value * 100} %
          </Button>
        ))}
        <Button onClick={() => handleSave(0)}>?</Button>
      </ButtonGroup>
      <ProgressBar
        value={1 - unmodified.length / exercises.length}
        stripes={false}
      />
    </Container>
  )
}

const enhance = compose(withRouter)

export default enhance(ExerciseCategorizer)
