import {
  Button,
  ButtonGroup,
  Classes,
  FormGroup,
  Intent,
  Menu,
} from '@blueprintjs/core'
import { DateInput } from '@blueprintjs/datetime'
import classnames from 'classnames'
import { get } from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import styled from 'styled-components'
import {
  fetchTemplateMesocycle,
  insertMesocycleAtDate,
  removeMicrocycleFromMesocycle,
} from '../../../lib/fetch/templates'
import { AppToaster } from '../../../lib/toaster'
import ConfirmButton from '../../Common/ConfirmButton'
import AddMicrocycleTemplateForm from './AddMicrocycleTemplateForm'

const Container = styled.div`
  max-width: 400px;
  > * {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }
`

const CycleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1em;
`

class MesocycleTemplateContainer extends React.Component {
  state = {
    mesocycle: { name: '', microcycles: [] },
    loading: false,
    targetDate: null,
  }

  componentWillMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props
    this.setState({ loading: true })
    const res = await fetchTemplateMesocycle(id)
    this.setState({ mesocycle: res.data, loading: false })
  }

  handleDateChange = targetDate => this.setState({ targetDate })

  handleRemoveCycle = async microcycle => {
    const { mesocycle } = this.state
    try {
      await removeMicrocycleFromMesocycle({ microcycle, mesocycle })
      this.fetchData()
      AppToaster.show({
        message: 'Zyklus entfernt.',
        intent: 'success',
      })
    } catch (e) {
      AppToaster.show({
        message: get(e, 'response.data.hydra:description'),
        intent: 'warning',
      })
    }
  }

  render() {
    const { mesocycle, loading } = this.state
    const { microcycles, name } = mesocycle
    return (
      <Container className={loading && Classes.SKELETON}>
        <h2>{name}</h2>
        <Menu className={Classes.ELEVATION_1}>
          {microcycles.map(m => {
            const removeCycle = () => this.handleRemoveCycle(m)
            return (
              <CycleRow>
                {m.name}
                <ButtonGroup>
                  <Link
                    className={classnames(Classes.BUTTON, 'bp3-icon-edit')}
                    to={`/templates/microcycles/${m.id}`}
                  />
                  <ConfirmButton
                    onConfirm={removeCycle}
                    buttonProps={{
                      intent: Intent.DANGER,
                      icon: 'trash',
                    }}
                    alertProps={{
                      cancelButtonText: 'Abbrechen',
                      confirmButtonText: 'Löschen',
                      icon: 'trash',
                      intent: Intent.DANGER,
                      content: <p>Zyklus entfernen?</p>,
                    }}
                  />
                </ButtonGroup>
              </CycleRow>
            )
          })}
        </Menu>

        <AddMicrocycleTemplateForm
          mesocycle={mesocycle}
          refetch={this.fetchData}
        />

        <FormGroup label="In Woche einfügen">
          <DateInput
            dayPickerProps={{
              showWeekNumbers: true,
              firstDayOfWeek: 1,
              disabledDays: { daysOfWeek: [0, 2, 3, 4, 5, 6] },
            }}
            onChange={this.handleDateChange}
            parseDate={str => DateTime.fromISO(str)}
            formatDate={date => DateTime.fromJSDate(date).toISODate()}
            placeholder={'DD.MM.YYYY'}
            value={this.state.targetDate}
            maxDate={DateTime.local()
              .plus({ years: 1 })
              .toJSDate()}
          />
          <Button
            icon="locate"
            onClick={async () => {
              const {
                match: {
                  params: { id },
                },
              } = this.props
              const datetime = DateTime.fromJSDate(this.state.targetDate)
              await insertMesocycleAtDate({ id, date: datetime.toISO() })
              AppToaster.show({
                message: `Template in KW ${datetime.toFormat('WW')} eingefügt`,
                intent: 'success',
              })
            }}
          />
        </FormGroup>
      </Container>
    )
  }
}

const enhance = compose(withRouter)

export default enhance(MesocycleTemplateContainer)
