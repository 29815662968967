export const CREATE_POST_REQUEST = 'CREATE_POST_REQUEST'
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS'
export const CREATE_POST_FAIL = 'CREATE_POST_FAIL'

export const SAVE_POST_REQUEST = 'SAVE_POST_REQUEST'
export const SAVE_POST_SUCCESS = 'SAVE_POST_SUCCESS'
export const SAVE_POST_FAIL = 'SAVE_POST_FAIL'

export const FETCH_POSTS_REQUEST = 'FETCH_POSTS_REQUEST'
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS'
export const FETCH_POSTS_FAIL = 'FETCH_POSTS_FAIL'

export const ADD_MODULE = 'ADD_MODULE'
export const UPDATE_MODULE = 'UPDATE_MODULE'
export const REMOVE_MODULE = 'REMOVE_MODULE'
export const TOGGLE_MODULE_SUBSCRIPTION_PLAN = 'TOGGLE_MODULE_SUBSCRIPTION_PLAN'

export const fetchPosts = () => ({
  type: FETCH_POSTS_REQUEST,
})

export const savePost = post => ({
  type: SAVE_POST_REQUEST,
  post,
})

export const createPost = post => ({
  type: CREATE_POST_REQUEST,
  post,
})

const reducer = (state = {}, { type, posts }) => {
  switch (type) {
    case FETCH_POSTS_SUCCESS:
      return posts
    default:
      // eslint-disable-line no-fallthrough
      return state
  }
}

export default reducer
