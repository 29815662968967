import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PowerbuildingCycle from 'components/Powerbuilding/Cycle'
import PowerbuildingTableContainer from 'components/Powerbuilding/TableContainer'

const PowerbuildingRouter = () => (
  <div>
    <Switch>
      <Route
        path="/powerbuilding/table/:id"
        component={PowerbuildingTableContainer}
      />
      <Route path="/powerbuilding/:id" component={PowerbuildingCycle} />
    </Switch>
  </div>
)

export default PowerbuildingRouter
