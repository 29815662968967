import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import CenteredSpinner from 'components/CenteredSpinner'
import { DateTime } from 'luxon'
import { sortBy, orderBy } from 'lodash'
import { Card } from '@blueprintjs/core'
import styled from 'styled-components/macro'

export const ALL_WEIGHTLIFTING_MODULES = gql`
  query modules {
    modules(
      pageSize: 110
      orderBy: id_DESC
      where: { type: [weightlifting], subtype: ["Training"] }
    ) {
      modules {
        id
        comment
        type
        duration
        subtype
        components {
          ... on IModuleComponent {
            id
          }
          ... on WeightliftingModuleComponent {
            id
            duration
            comment
            complexes {
              position
              comment
              exercises {
                position
                exercise {
                  ... on WeightliftingExercise {
                    displayName
                  }
                }
                sets {
                  weight
                  reps
                  ownWeight
                  position
                }
              }
            }
          }
        }
        moduleDates {
          date
        }
        createdAt
        updatedAt
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  > div {
    margin: 1rem;
  }

  .complex:not(:last-child) {
    margin-bottom: 1rem;
  }
`

const Comment = styled.div`
  color: gray;
`

const Title = styled.div`
  display: flex;
  > * {
    margin: 0 1rem;
  }
`

const Complex = ({ complex: { position, comment, exercises, ...complex } }) => (
  <div className="complex">
    <div>Übung {position + 1}</div>
    <Comment>{comment}</Comment>
    {sortBy(exercises, 'position').map(({ exercise, sets }) => (
      <>
        <div>{exercise.displayName}</div>
        <div>{sets.map(set => `${set.reps}x${set.weight}`).join(' / ')}</div>
      </>
    ))}
  </div>
)

const Component = ({ component: { complexes, comment } }) => (
  <div>
    <Comment>{comment}</Comment>
    {sortBy(complexes, 'position').map(complex => (
      <Complex complex={complex} />
    ))}
  </div>
)

const Module = ({
  module: { id, moduleDates, duration, type, subtype, components },
}) => (
  <Card>
    <Title>
      <div>
        {moduleDates
          .map(moduleDate =>
            DateTime.fromISO(moduleDate.date).toLocaleString({
              weekday: 'long',
              month: 'long',
              day: '2-digit',
            }),
          )
          .join(', ')}
      </div>
      <div>{id}</div>
      <div>
        {type} - {subtype} - {duration / 60}'
      </div>
    </Title>
    <div>
      {components.map(component => (
        <Component component={component} />
      ))}
    </div>
  </Card>
)

const WeightliftingOverview = () => {
  const { data, error, loading } = useQuery(ALL_WEIGHTLIFTING_MODULES)

  if (loading) {
    return <CenteredSpinner />
  }

  if (error) {
    return (
      <div>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </div>
    )
  }

  const modules = data.modules.modules.filter(m => m.moduleDates.length > 0)

  return (
    <Container>
      {orderBy(modules, module => module.moduleDates[0].date, 'asc').map(m => (
        <Module module={m} />
      ))}
    </Container>
  )
}

export default WeightliftingOverview
