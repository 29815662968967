export const SET_ENTRYPOINTS = 'SET_ENTRYPOINTS'

export const MARK_AS_DIRTY = 'MARK_AS_DIRTY'

export const SET_PLAN = 'SET_PLAN'
export const SET_MODULE = 'SET_MODULE'

export const FETCH_PLAN_REQUEST = 'FETCH_PLAN_REQUEST'
export const FETCH_PLAN_SUCCESS = 'FETCH_PLAN_SUCCESS'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const FETCH_PLAN_FAIL = 'FETCH_PLAN_FAIL'
export const FETCH_ERROR = 'FETCH_ERROR'
export const SET_CURRENT_DAY = 'SET_CURRENT_DAY'
export const SET_ENTITY = 'SET_ENTITY'
export const FETCH_ENTITY_REQUEST = 'FETCH_ENTITY_REQUEST'

export const fetchPlan = date => ({ type: FETCH_PLAN_REQUEST, date })

export const getEntity = iri => ({
  type: FETCH_ENTITY_REQUEST,
  iri,
})

export const setPlan = plan => ({
  type: SET_PLAN,
  plan,
})
