import { Button, ControlGroup, InputGroup, Intent } from '@blueprintjs/core'
import { AppToaster } from 'lib/toaster'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { createStrengthPlan } from '../../lib/fetch/strengthplans'

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Pflichtfeld'
  }
  return errors
}

const NewStrenghPlanForm = ({ refetch, style }) => {
  const onSubmit = async values => {
    try {
      await createStrengthPlan(values)
      refetch()
      AppToaster.show({
        message: 'Plan angelegt.',
        intent: 'success',
      })
    } catch (e) {
      AppToaster.show({
        message: get(e.response.data, 'hydra:description'),
        intent: 'warning',
      })
    }
  }
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, pristine, invalid, reset }) => (
        <form
          style={{ ...style }}
          onSubmit={async e => {
            await handleSubmit(e)
            reset()
          }}
        >
          <ControlGroup fill vertical>
            <Field
              name="name"
              render={({ input, meta }) => (
                <InputGroup id="text-input" placeholder="Name" {...input} />
              )}
            />

            <Button
              icon="new-grid-item"
              type="submit"
              intent={Intent.SUCCESS}
              disabled={pristine || invalid}
            >
              Neuen Plan erstellen
            </Button>
          </ControlGroup>
        </form>
      )}
    />
  )
}

NewStrenghPlanForm.propTypes = {
  refetch: PropTypes.func.isRequired,
  style: PropTypes.object,
}

export default NewStrenghPlanForm
