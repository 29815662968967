import { instance } from '../fetchService'

export const baseUrl = process.env.REACT_APP_API_URL

// export const createModule = (
//   session,
//   date,
//   plans,
//   type,
//   subtype,
//   position,
//   template = false,
//   day = null,
// ) =>
//   instance.post('modules', {
//     session,
//     date: date ? date.format() : null,
//     subscriptionPlans: plans ? plans.map(p => p['@id']) : [],
//     type,
//     subtype,
//     position,
//     template,
//     day,
//   })

export const saveModule = module => instance.put(`modules/${module.id}`, module)

export const removeModule = module => instance.delete(`modules/${module.id}`)

export const fetchModule = id =>
  instance.get(`modules/${id}`, { headers: { admin: undefined } })

export const fetchModules = date =>
  instance.get('modules', { params: { 'dates.date': date } })

export const fetchModuleInfo = id =>
  instance.get(`modules/${id}`, { headers: { granularity: 'info' } })
