import React from 'react'
import {
  compose,
  withState,
  withHandlers,
  branch,
  renderComponent,
} from 'recompose'
import { connect } from 'react-redux'
import { set } from 'dot-prop-immutable'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { Spinner } from '@blueprintjs/core'
import { createPost } from '../../lib/fetchService'
import { fetchPosts } from '../../ducks/posts'
import history from '../../createHistory'

const withSpinnerWhileLoading = branch(
  ({ post }) => !post,
  renderComponent(Spinner),
)

const enhance = compose(
  connect(
    null,
    { fetchPosts },
  ),
  withState('post', 'setPost', {}),
  withHandlers({
    handleChange: ({ setPost, post }) => ({
      target: { name, value, type, checked },
    }) => setPost(set(post, name, type === 'checkbox' ? checked : value)),
    handleContentChange: ({ setPost, post }) => value =>
      setPost(set(post, 'content', value)),
    handleAbstractChange: ({ setPost, post }) => value =>
      setPost(set(post, 'abstract', value)),
    handleSubmit: ({ post, setPost, fetchPosts }) => async e => {
      e.preventDefault()
      try {
        setPost(null)
        const savedPost = await createPost(post)
        fetchPosts()
        // showNotification(
        //   {
        //     title: (
        //       <span>
        //         <i className="fa fa-check" /> Speichern erfolgreich
        //       </span>
        //     ),
        //   },
        //   'success',
        // )
        history.push(`/blog/${savedPost.slug}`)
      } catch (error) {
        console.log(error)
        setPost(post)
        // showNotification(
        //   { title: 'Fehler', message: error['hydra:description'] },
        //   'success',
        // )
      }
    },
  }),
  withSpinnerWhileLoading,
)

const PostCreate = ({
  post,
  handleChange,
  handleContentChange,
  handleAbstractChange,
  handleSubmit,
}) => {
  const { title, content, abstract, author, published } = post
  return (
    <form onSubmit={handleSubmit}>
      <div className="grid-x grid-margin-x">
        <div className="cell auto">
          <label>
            Titel
            <input
              type="text"
              name="title"
              value={title}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="cell medium-6">
          <label>
            Author
            <input
              type="text"
              name="author"
              value={author}
              onChange={handleChange}
            />
          </label>
        </div>
      </div>
      <div className="grid-x">
        <div className="cell auto">
          <label>
            Abstract
            <ReactQuill
              theme="snow"
              value={abstract}
              onChange={handleAbstractChange}
              modules={PostCreate.modules}
              formats={PostCreate.formats}
            />
          </label>
        </div>
      </div>
      <div className="grid-x">
        <div className="cell auto">
          <label>
            Inhalt
            <ReactQuill
              theme="snow"
              value={content}
              onChange={handleContentChange}
              modules={PostCreate.modules}
              formats={PostCreate.formats}
            />
          </label>
        </div>
      </div>
      <div className="grid-x">
        <div className="cell auto">
          <input
            id="published"
            name="published"
            type="checkbox"
            checked={published}
            onChange={handleChange}
          />
          <label htmlFor="published">Veröffentlicht</label>
        </div>
      </div>
      <div className="grid-x">
        <div className="cell auto">
          <input type="submit" className="success button" value="Speichern" />
        </div>
      </div>
    </form>
  )
}

PostCreate.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
}

PostCreate.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'video',
]

export default enhance(PostCreate)
