import { normalize } from 'normalizr'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  FETCH_CALENDAR_MODULE_DATES_FAIL,
  FETCH_CALENDAR_MODULE_DATES_REQUEST,
  FETCH_CALENDAR_MODULE_DATES_SUCCESS,
} from '../ducks/calendar'
import { dispatchFetchError, FETCH_END, FETCH_START } from '../ducks/fetch'
import { fetchModuleDatesBetweenForCalendar } from '../lib/fetch/moduleDates'
import { arrayOfCalendarModuleDates } from '../lib/normalize'

function* getModules(action) {
  try {
    yield put({ type: FETCH_START, endpoint: 'calendar' })

    const {
      payload: { start, end },
    } = action
    const { data: moduleDates } = yield call(
      fetchModuleDatesBetweenForCalendar,
      start,
      end,
    )
    const normalized = normalize(
      moduleDates['hydra:member'],
      arrayOfCalendarModuleDates,
    )
    yield put({ type: FETCH_CALENDAR_MODULE_DATES_SUCCESS, ...normalized })
    yield put({ type: FETCH_END, endpoint: 'calendar' })
  } catch (error) {
    yield put({
      type: FETCH_CALENDAR_MODULE_DATES_FAIL,
      message: error.message,
    })
    yield put(dispatchFetchError({ endpoint: 'calendar', error }))
  }
}

export function* getMicrocyclesSaga() {
  yield takeEvery(FETCH_CALENDAR_MODULE_DATES_REQUEST, getModules)
}
