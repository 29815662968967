import PropTypes from 'prop-types'
import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import ModuleDateCreate from '../../ModuleDate/Create'
import ModuleDate from '../../ModuleDate/ModuleDate'

const Grid = styled.div`
  display: grid;
  grid-gap: 1em;
  margin-top: 1em;
`

const Spacer = styled.div`
  height: 50px;
`

const PlanDaySessionsContainer = ({ session, moduleDates, index, date }) => {
  const key = `session-${index}`
  const droppableId = JSON.stringify({ session: index })
  return (
    <Droppable key={key} droppableId={droppableId}>
      {(provided, snapshot) => (
        <Grid ref={provided.innerRef}>
          <h3>
            Trainingseinheit {Number(index) + 1}{' '}
            <small>{moduleDates.length} Module</small>{' '}
          </h3>
          {moduleDates.map(moduleDate => (
            <ModuleDate
              key={moduleDate.id}
              index={index}
              position={moduleDate.position}
              moduleDateId={moduleDate.id}
            />
          ))}
          {provided.placeholder}
          <Spacer />
          <ModuleDateCreate
            index={index}
            position={moduleDates.length}
            date={date}
          />
        </Grid>
      )}
    </Droppable>
  )
}

// TODO: extract NewModuleForm

PlanDaySessionsContainer.propTypes = {
  moduleDates: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
}

PlanDaySessionsContainer.defaultProps = {
  moduleDates: [],
}

export default PlanDaySessionsContainer
