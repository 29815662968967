import { Button, Card, Collapse } from '@blueprintjs/core'
import PowerbuildingAddExerciseDialog from 'components/Powerbuilding/AddExerciseDialog'
import PowerbuildingEditExerciseDialog from 'components/Powerbuilding/EditExerciseDialog'
import PowerbuildingDeleteExerciseDialog from 'components/Powerbuilding/DeleteExerciseDialog'
import React from 'react'
import { useToggle } from 'react-use'
import { Info } from 'luxon'

const ExerciseName = ({ exercise }) => {
  const parts = [
    exercise.preModifier,
    exercise?.exercise?.name,
    exercise.postModifier,
  ]

  return parts.filter(p => p).join(' ')
}

const PowerbuildingDay = ({ cycleId, week, day, exercises }) => {
  const [open, toggleOpen] = useToggle(false)

  return (
    <div style={{ marginBottom: '8px' }}>
      <Button onClick={toggleOpen} icon={open ? 'chevron-up' : 'chevron-down'}>
        Woche {week} / {Info.weekdays()[day - 1]}
      </Button>

      <Collapse isOpen={open}>
        <div>
          <Card>
            <table className="bp3-html-table bp3-html-table-condensed bp3-html-table-striped">
              <thead>
                <tr>
                  <th />
                  <th>Sets</th>
                  <th>Reps</th>
                  <th>Reps In Reserve</th>
                  <th>Tempo</th>
                  <th>Übung</th>
                  <th>Kommentar</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {exercises.map(exercise => (
                  <tr key={exercise.id}>
                    <td>{exercise.identifier}</td>
                    {exercise.exerciseOfWeightliftingModule ? (
                      <td colSpan={5}>
                        <span
                          style={{
                            textAlign: 'center',
                            width: '100%',
                            display: 'inline-block',
                          }}
                        >
                          #{exercise.exerciseOfWeightliftingModule} von TFC
                          Weightlifting
                        </span>
                      </td>
                    ) : (
                      <>
                        {exercise.strengthPlan ?
                            <td colSpan={4}>Strength Plan Cycle: {exercise.strengthPlan.name}</td>
                            :
                            <>
                              <td>{exercise.sets.length}</td>
                              <td>{exercise.sets[0]?.reps}</td>
                              <td>{exercise.repsInReserve}</td>
                              <td>{exercise.tempo}</td>
                            </>
                        }


                        <td>
                          <ExerciseName exercise={exercise} />
                        </td>
                      </>
                    )}
                    <td>{exercise.comment}</td>
                    <td>
                      <PowerbuildingEditExerciseDialog exercise={exercise} />
                      <PowerbuildingDeleteExerciseDialog exercise={exercise} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <PowerbuildingAddExerciseDialog
              cycleId={cycleId}
              day={day}
              week={week}
            />
          </Card>
        </div>
      </Collapse>
    </div>
  )
}

export default PowerbuildingDay
