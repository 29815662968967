import { Callout, Classes, Spinner } from '@blueprintjs/core'

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  removeModuleDate,
  saveModuleDate,
  toggleSubscriptionPlans,
  updateModuleDate,
} from '../../ducks/moduleDates'
import DragHandle from '../Layout/BeautifulDragHandle'
import ModuleContainer from '../Module/Container'
import Controls from './Controls'
import Info from './Info'

const StyledCallout = styled(Callout)`
  margin-top: 1em;
`

const StyledCard = styled.div.attrs({
  className: Classes.CARD,
})``

const ModuleDate = ({ moduleDateId, position }) => {
  const [expanded, setExpanded] = useState(false)
  const dispatch = useDispatch()

  const { moduleDate } = useSelector(({ calendar, subscriptionPlans }) => ({
    moduleDate: calendar.entities.moduleDates[moduleDateId],
  }))

  if (!moduleDate) {
    return <Spinner />
  }

  const handleValueChange = ({
    target: { name, type, checked, value: inputValue },
  }) => {
    const value = type === 'checkbox' ? checked : inputValue

    dispatch(
      updateModuleDate({
        id: moduleDate.id,
        [name]: name === 'duration' ? value * 60 : value,
      }),
    )
  }

  const handleNumberValueChange = ({ name, value }) => {
    dispatch(
      updateModuleDate({
        id: moduleDate.id,
        [name]: name === 'duration' ? value * 60 : value,
      }),
    )
  }

  const handleSaveModuleDate = () => {
    dispatch(saveModuleDate(moduleDate))
  }

  const handleRemoveModuleDate = () => {
    dispatch(removeModuleDate(moduleDate, moduleDate.session))
  }

  const toggleExpand = () => setExpanded(c => !c)

  return (
    <Draggable
      key={moduleDate.id}
      draggableId={JSON.stringify({ id: moduleDate.id })}
      index={position}
    >
      {(provided, snapshot) => (
        <StyledCard ref={provided.innerRef} {...provided.draggableProps}>
          <Info
            {...moduleDate}
            position={position}
            dragHandle={
              <DragHandle
                dragHandleProps={provided.dragHandleProps}
                position={moduleDate.position}
              />
            }
          />
          <Controls
            moduleDate={moduleDate}
            handleValueChange={handleValueChange}
            handleNumberValueChange={handleNumberValueChange}
            handleSaveModuleDate={handleSaveModuleDate}
            toggleExpand={toggleExpand}
            handleRemoveModuleDate={handleRemoveModuleDate}
            expanded={expanded}
          />
          {expanded && (
            <StyledCallout>
              <ModuleContainer id={moduleDate.module} expanded />
            </StyledCallout>
          )}
        </StyledCard>
      )}
    </Draggable>
  )
}

ModuleDate.propTypes = {
  moduleDateId: PropTypes.number.isRequired,
}

export default ModuleDate
