import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import 'react-select/dist/react-select.css'
import styled from 'styled-components'
import Calendar from './components/Calendar/Calendar'
import DayContainer from './components/Plan/Day/Container'
import EnhancedStatistics from './components/Statistics/Statistics'
import { fetchPlan } from './ducks/common'
// import './App.css'
import './font.css'
import { DragDropContext } from 'lib/dragdrop'

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: auto auto auto;
  grid-template-rows: min-content auto;
  margin-top: 1em;
  padding: 1em;
`

const Plan = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPlan())
  }, [dispatch])

  return (
    <Container>
      <Route path="/plan/:date" component={Calendar} />
      <Route path="/plan/:date" component={EnhancedStatistics} />

      <Switch>
        <Route
          exact
          path="/plan"
          render={() => (
            <Redirect to={`plan/${DateTime.local().toISODate()}`} />
          )}
        />
        <Route
          path="/plan/:date"
          render={props => (
            <DayContainer
              key={props.match.params.date}
              date={props.match.params.date}
              {...props}
            />
          )}
        />
      </Switch>
    </Container>
  )
}

export default Plan
