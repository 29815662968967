import { useMutation } from '@apollo/react-hooks'
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core'
import { gql } from 'apollo-boost'
import ApolloExerciseInput from 'components/ApolloExerciseInput'
import { required } from 'components/Apps/VideoUploadForm'
import {
  DropDownAdapter,
  NumberInputAdapter,
  TextFieldAdapter,
} from 'components/Form/Adapters'
import { FORM_ERROR } from 'final-form'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useToggle } from 'react-use'
import styled from 'styled-components'
import { AppToaster } from '../../lib/toaster'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  grid-gap: 20px;
`

const Error = styled.div`
  color: red;
`

const mutation = gql`
  mutation deletePowerbuildingCycleExercise($id: ID!) {
    deletePowerbuildingCycleExercise(id: $id) {
      id
      week
      day
      exercise {
        ... on IExercise {
          id
          name
        }
      }
      sets
      reps
      repsInReserve
      comment
      tempo

      createdAt
      updatedAt
    }
  }
`

const PowerbuildingAddExerciseDialog = ({ exercise: { id } }) => {
  const [open, toggleOpen] = useToggle(false)

  const [mutate, { loading }] = useMutation(mutation, {
    refetchQueries: ['powerbuildingCycle'],
    variables: { id },
  })

  const onSubmit = async values => {
    try {
      await mutate()
      AppToaster.show({
        message: 'Erfolgreich',
        intent: 'success',
      })
      toggleOpen(false)
    } catch (e) {
      AppToaster.show({
        message: 'Es ist ein Fehler aufgetreten',
        intent: 'error',
      })
      console.log(e)

      return { [FORM_ERROR]: e.message }
    }
  }

  const validate = () => undefined

  return (
    <>
      <Button onClick={() => toggleOpen(true)}>Löschen</Button>
      <Dialog
        isOpen={open}
        usePortal
        icon="info-sign"
        title="Übung hinzufügen"
        onClose={() => toggleOpen(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <div>Die Übung sicher löschen?</div>
          <div>Das kann nicht rückgängig gemacht werden?</div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              type="submit"
              intent={Intent.WARNING}
              loading={loading}
              onClick={onSubmit}
            >
              Löschen
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

PowerbuildingAddExerciseDialog.defaultProps = { day: 1, week: 1 }

export default PowerbuildingAddExerciseDialog
