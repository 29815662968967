import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { removeTemplateMicrocycle } from '../../../lib/fetch/templates'
import NewCycleTemplateForm from './NewCycleTemplateForm'
import TemplateList from './TemplateList'

class MicrocycleTemplateListContainer extends React.Component {
  state = {
    cycles: [],
    loading: false,
  }

  componentWillMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const { fetchFunction } = this.props
    this.setState({ loading: true })
    const res = await fetchFunction()
    this.setState({ cycles: res.data['hydra:member'], loading: false })
  }

  deleteModule = async module => {
    await removeTemplateMicrocycle(module)
    this.fetchData()
  }

  render() {
    const { type, createFunction } = this.props
    const { cycles, loading } = this.state
    return (
      <div style={{ maxWidth: '400px' }}>
        <TemplateList type={type} cycles={cycles} loading={loading} />
        <NewCycleTemplateForm
          createFunction={createFunction}
          type={type}
          style={{ marginTop: '1em' }}
          refetch={this.fetchData}
        />
      </div>
    )
  }
}

MicrocycleTemplateListContainer.propTypes = {
  type: PropTypes.string.isRequired,
  fetchFunction: PropTypes.func.isRequired,
  createFunction: PropTypes.func.isRequired,
}

const enhance = compose(withRouter)

export default enhance(MicrocycleTemplateListContainer)
