import { call, put, takeEvery, select } from 'redux-saga/effects'
import { DateTime } from 'luxon'
import {
  EVALUATION_SET_END_DATE,
  EVALUATION_SET_START_DATE,
  setModuleComponents,
  setFilter,
} from '../ducks/evaluation'
import { dispatchFetchError } from '../ducks/fetch'
import { fetchWeightliftingModuleComponents } from '../lib/fetchService'

export const LOGS_SET_FILTER_BY_PLAN = 'EVALUATION::SET_FILTER_BY_PLAN'
export const FETCH_COMPONENTS_REQUEST = 'EVALUATION::FETCH_COMPONENTS_REQUEST'
export const FETCH_COMPONENTS_SUCCESS = 'EVALUATION::FETCH_COMPONENTS_SUCCESS'
export const FETCH_COMPONENTS_FAIL = 'EVALUATION::FETCH_COMPONENTS_FAIL'

export const requestSetFilterUsersByPlan = plan => ({
  type: LOGS_SET_FILTER_BY_PLAN,
  plan,
})

export const fetchModuleComponentsRequest = () => ({
  type: FETCH_COMPONENTS_REQUEST,
})

function* setFilterUsersByPlan({ plan }) {
  const state = yield select()
  const {
    users: { users },
    evaluation: {
      filter: { startDate, endDate },
    },
  } = state

  const filteredUsers = users.filter(u => {
    const memberships = u.memberships.filter(m => {
      const createdAt = DateTime.fromISO(m.createdAt).startOf('day')
      const expiresAt = DateTime.fromISO(m.expiresAt).startOf('day')
      return (
        (createdAt >= startDate && expiresAt <= endDate) ||
        (createdAt <= endDate && expiresAt >= startDate)
      )
    })
    if (memberships.length === 0) {
      return false
    }
    return memberships.reduce(
      (prev, membership) => prev && membership.plan.name === plan,
      true,
    )
  })

  yield put(
    setFilter({
      id: filteredUsers.reduce(
        (acc, user) => acc.concat(user.athleteDetails.id),
        [],
      ),
    }),
  )
}

function* fetchModuleComponents() {
  try {
    const state = yield select()
    const {
      evaluation: {
        filter: { startDate, endDate },
      },
    } = state
    const components = yield call(fetchWeightliftingModuleComponents, {
      page: 1,
      order: 'DESC',
      before: endDate,
      after: startDate,
    })
    yield put(setModuleComponents(components['hydra:member']))
    yield put({ type: FETCH_COMPONENTS_SUCCESS })
  } catch (error) {
    yield put(dispatchFetchError({ error }))
    yield put({ type: FETCH_COMPONENTS_FAIL })
  }
}

export function* evaluationSaga() {
  yield takeEvery(LOGS_SET_FILTER_BY_PLAN, setFilterUsersByPlan)
  yield takeEvery(FETCH_COMPONENTS_REQUEST, fetchModuleComponents)
  yield takeEvery(EVALUATION_SET_START_DATE, fetchModuleComponents)
  yield takeEvery(EVALUATION_SET_END_DATE, fetchModuleComponents)
}
