import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import BlueprintSearch from 'components/Common/BlueprintSearch'
import { fetchStrengthPlans } from 'lib/fetch/strengthplans'

class StrengthPlanSearch extends React.Component {
  state = {
    plans: [],
    loading: false,
  }

  componentWillMount() {
    this.fetchData()
  }

  fetchData = async () => {
    this.setState({ loading: true })
    const res = await fetchStrengthPlans()
    this.setState({ plans: res.data['hydra:member'], loading: false })
  }

  render() {
    const { plan, onChange } = this.props
    const { plans } = this.state
    const handleChange = ({ value }) =>
      onChange(plans.find(m => m['@id'] === value))
    return (
      <BlueprintSearch
        inputProps={{
          placeholder: 'Plan auswählen',
        }}
        shouldDismissPopover={false}
        value={get(plan, '@id')}
        onChange={handleChange}
        options={plans.map(m => ({
          value: m['@id'],
          label: m.name,
        }))}
      />
    )
  }
}

StrengthPlanSearch.propTypes = {
  plan: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
}

StrengthPlanSearch.defaultProps = {
  plan: { '@id': null },
}

export default StrengthPlanSearch
