import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import decode from 'jwt-decode'
import { authState } from 'lib/atoms/authState'
import { AUTH_TOKEN } from 'lib/constants'
import { get } from 'lodash'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useSetRecoilState } from 'recoil'
import history from '../../createHistory'
import { login } from '../../lib/fetchService'
import { AppToaster } from '../../lib/toaster'

const validate = ({ email, password }) => {
  const errors = {}
  if (!email) {
    errors.email = 'Pflichtfeld'
  }
  if (!password) {
    errors.email = 'Pflichtfeld'
  }
  return errors
}

const Login = () => {
  const setAuthToken = useSetRecoilState(authState)

  const onSubmit = async ({ email, password }) => {
    try {
      const res = await login(email, password)
      const { roles } = decode(res.data.token)
      if (!roles.some(r => r === 'ROLE_ADMIN')) {
        throw new Error('Du hast keine Berechtigung')
      }
      localStorage.setItem(AUTH_TOKEN, res.data.token)
      setAuthToken({ token: res.data.token })

      // history.goBack()
      AppToaster.show({
        message: 'Erfolgreich eingeloggt.',
        intent: 'success',
      })
    } catch (e) {
      const message = get(e.response, 'data.message') || e.message
      AppToaster.show({
        message,
        intent: 'warning',
      })
    }
  }
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, pristine, invalid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <Field
              name="email"
              render={({ input, meta }) => (
                <FormGroup inline label="E-Mail" labelFor="text-input">
                  <InputGroup id="text-input" placeholder="E-Mail" {...input} />
                </FormGroup>
              )}
            />
            <Field
              name="password"
              type="password"
              render={({ input, meta }) => (
                <FormGroup inline label="Passwort" labelFor="text-input">
                  <InputGroup
                    id="text-input"
                    placeholder="Passwort"
                    {...input}
                  />
                </FormGroup>
              )}
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                type="submit"
                intent={Intent.SUCCESS}
                disabled={pristine || invalid}
                loading={submitting}
              >
                Einloggen
              </Button>
            </div>
          </div>
        </form>
      )}
    />
  )
}

export default Login
