export const FETCH_START = 'FETCH_START'
export const FETCH_END = 'FETCH_END'
export const FETCH_ERROR = 'FETCH_ERROR'
export const FETCH_CANCEL = 'FETCH_CANCEL'

export const dispatchFetchError = ({ endpoint, error }) => ({
  type: FETCH_ERROR,
  error,
  endpoint,
})

const reducer = (previousState = { total: 0 }, { type, endpoint }) => {
  switch (type) {
    case FETCH_START:
      return {
        ...previousState,
        total: previousState.total + 1,
        [endpoint]: previousState[endpoint] ? previousState[endpoint] + 1 : 1,
      }
    case FETCH_END:
    case FETCH_ERROR:
    case FETCH_CANCEL:
      return {
        ...previousState,
        total: Math.max(previousState.total - 1, 0),
        [endpoint]: previousState[endpoint] ? previousState[endpoint] - 1 : 0,
      }
    default:
      return previousState
  }
}

export default reducer
