import { Button, MenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import React from 'react'

const Dropdown = ({ label, options, onChange, name, value, filterable }) => {
  const currentItem = options && options.find(option => option.value === value)
  return (
    <Select
      items={options}
      filterable={filterable}
      itemRenderer={(item, { handleClick, modifiers }) => {
        if (filterable && !modifiers.filtered) {
          return null
        }
        return (
          <MenuItem
            active={modifiers.active}
            key={item.value}
            label={item.label}
            onClick={handleClick}
          />
        )
      }}
      onItemSelect={onChange}
    >
      <Button
        text={`${label || name || ''} ${currentItem && currentItem.label}`}
        rightIcon="double-caret-vertical"
      />
    </Select>
  )
}

Dropdown.defaultProps = {
  filterable: false,
}

export default Dropdown
