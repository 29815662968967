import { Button, ButtonGroup, Card, ControlGroup } from '@blueprintjs/core'
import ExerciseBlockField from 'components/Module/ModuleComponents/ExerciseFields/ExerciseBlockField'
import ExerciseComplexBlockField from 'components/Module/ModuleComponents/ExerciseFields/ExerciseComplexBlockField'
import {
  CheckboxAdapter,
  DropDownAdapter,
  NumberInputAdapter,
  TextFieldAdapter,
} from 'components/Form/Adapters'
import { required } from 'components/Form/Validation'
import CenteredRow from 'components/Layout/StyledComponents/CenteredRow'
import Row from 'components/Layout/StyledComponents/Row'
import arrayMutators from 'final-form-arrays'
import { Droppable } from 'lib/dragdrop'
import useModuleComponent from 'lib/hooks/useModuleComponent'
import { sortBy } from 'lodash'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useModuleActions } from '../hooks'
import { getListStyle } from './styles'

const RoundModuleComponent = ({ id, dragHandle }) => {
  const componentFromState = useModuleComponent(id, 'round')

  const { handleRemove, handleSave } = useModuleActions(componentFromState)

  const onSave = async ({ exercises, ...values }) => {
    const payload = {
      ...values,
      exercises: exercises.filter(e => e['@type'] === 'ExerciseSingleBlock'),
      complexes: exercises.filter(e => e['@type'] === 'ExerciseComplexBlock'),
    }
    handleSave(payload)
  }

  const { exercises, complexes, ...component } = componentFromState

  const initialValues = {
    ...component,
    exercises: sortBy([...exercises, ...complexes], 'position'),
  }
  return (
    <Card>
      <Form
        onSubmit={onSave}
        mutators={{
          ...arrayMutators,
          arrayMove: ([name, from, to], state, { changeValue }) => {
            changeValue(state, name, array => {
              const copy = [...(array || [])]
              const value = copy[from]
              copy.splice(from, 1)
              copy.splice(to, 0, value)
              return copy
            })
          },
        }}
        initialValues={initialValues}
        render={({ form, values, handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            {submitError && <div className="error">{submitError}</div>}
            <Row>
              {dragHandle}
              <div style={{ flexGrow: 1 }}>
                <h3>{component.title || component['@type']}</h3>
              </div>
              <ButtonGroup>
                <Button
                  intent="success"
                  icon="floppy-disk"
                  type="submit"
                  loading={submitting}
                />
                {/* <Button
                  icon={this.state.expanded ? 'chevron-up' : 'chevron-down'}
                  onClick={this.toggleExpand}
                /> */}
                {componentFromState['@type'] !==
                  'WeightliftingModuleComponent' && (
                  <Button icon="trash" intent="danger" onClick={handleRemove} />
                )}
              </ButtonGroup>
            </Row>
            <ControlGroup
              style={{
                alignItems: 'flex-end',
                marginTop: '8px',
                marginBottom: '8px',
              }}
              fill
            >
              <Field
                name="comment"
                type="text"
                label="Kommentar"
                component={TextFieldAdapter}
                parse={value => (value === '' ? null : value)}
              />
              <Field
                name="numberOfRounds"
                type="text"
                label="Runden"
                parse={value => (value === '' ? null : value)}
                // validate={required}
                component={TextFieldAdapter}
              />
              <Field
                name="timecap"
                type="text"
                label="TimeCap"
                format={value => value / 60}
                parse={value => value * 60}
                component={NumberInputAdapter}
              />
              <Field
                name="exerciseType"
                label="Übungstyp"
                component={DropDownAdapter}
                options={[
                  { value: 'all', label: 'Alle' },
                  { value: 'Crossfit', label: 'CrossFit' },
                  { value: 'Weightlifting', label: 'Weightlifting' },
                  { value: 'Gymnastics', label: 'Gymnastics' },
                ]}
              />
              <Field name="amrap" label="AMRAP" component={CheckboxAdapter} />
            </ControlGroup>

            <FieldArray name="exercises">
              {({ fields }) => {
                const droppableId = `round-component-${id}-exercises`

                const handleExerciseDragEnd = event => {
                  // if (
                  //   event.source.droppableId === droppableId &&
                  //   event.destination.droppableId !== droppableId
                  // ) {
                  //   fields.remove(event.source.index)
                  // }

                  // if (
                  //   event.destination.droppableId === droppableId &&
                  //   event.source.droppableId !== droppableId
                  // ) {
                  //   console.log(event)
                  //    fields.isert(index, )
                  // }

                  if (
                    event.destination.droppableId === droppableId &&
                    event.source.droppableId === droppableId
                  ) {
                    form.mutators.arrayMove(
                      fields.name,
                      event.source.index,
                      event.destination.index,
                    )
                  }
                }
                return (
                  <>
                    <Droppable
                      type={droppableId}
                      onDragEnd={handleExerciseDragEnd}
                      droppableId={droppableId}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {fields.map((name, index) => (
                            <Field
                              name={`${name}.@type`}
                              subscription={{ value: true }}
                            >
                              {({ input: { value } }) =>
                                value === 'ExerciseComplexBlock' ? (
                                  <ExerciseComplexBlockField
                                    name={name}
                                    index={index}
                                    fields={fields}
                                    exerciseType={values.exerciseType}
                                    droppableId={droppableId}
                                  />
                                ) : (
                                  <ExerciseBlockField
                                    name={name}
                                    index={index}
                                    fields={fields}
                                    exerciseType={values.exerciseType}
                                    droppableId={droppableId}
                                  />
                                )
                              }
                            </Field>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <CenteredRow style={{ marginTop: '8px' }}>
                      <ButtonGroup>
                        <Button
                          intent="primary"
                          icon="plus"
                          onClick={() =>
                            fields.push({
                              '@type': 'ExerciseSingleBlock',
                              position: fields.length,
                            })
                          }
                        >
                          Übung hinzufügen
                        </Button>
                        <Button
                          icon="plus"
                          onClick={() =>
                            fields.push({
                              '@type': 'ExerciseComplexBlock',
                              position: fields.length,
                            })
                          }
                        >
                          Komplex oder Ladder hinzufügen
                        </Button>
                      </ButtonGroup>
                    </CenteredRow>
                  </>
                )
              }}
            </FieldArray>
          </form>
        )}
      />
    </Card>
  )
}

export default RoundModuleComponent
