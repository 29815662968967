import React from 'react'

const StrengthPlanContext = React.createContext({
  plan: {},
  setState: () => {},
})

export function withContext(Component) {
  // ...and returns another component...
  return function WrappedComponent(props) {
    // ... and renders the wrapped component with the context theme!
    // Notice that we pass through any additional props as well
    return (
      <StrengthPlanContext.Consumer>
        {contextProps => <Component {...props} {...contextProps} />}
      </StrengthPlanContext.Consumer>
    )
  }
}

export default StrengthPlanContext
