import { Button, ControlGroup } from '@blueprintjs/core'
import React from 'react'
import { Field, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Draggable, Droppable } from 'lib/dragdrop'
import ExerciseBlockField from './ExerciseBlockField'
import { RadioAdapter, TextFieldAdapter } from '../../../Form/Adapters'
import CenteredRow from '../../../Layout/StyledComponents/CenteredRow'

import BeautifulDragHandle from '../../../Layout/BeautifulDragHandle'
import { getItemStyle, getListStyle } from '../styles'
import { PositionDisplay } from '../PositionDisplay'

const ExerciseComplexBlockField = ({
  name,
  fields: parentFields,
  index,
  exerciseType = 'all',
  droppableId,
}) => {
  const form = useForm()
  return (
    <Draggable
      type={droppableId}
      key={name}
      draggableId={`${droppableId}-${index}`}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          // color={color}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
        >
          <ControlGroup fill>
            <BeautifulDragHandle {...provided.dragHandleProps} />
            <PositionDisplay name={name} />
            <Field
              name={`${name}.type`}
              component={RadioAdapter}
              options={[
                { label: 'Leiter', value: 'ladder' },
                { label: 'Komplex', value: 'complex' },
              ]}
              inline
            />

            <Field
              name={`${name}.comment`}
              type="text"
              component={TextFieldAdapter}
              placeholder="Kommentar"
            />
            <Button
              style={{ flexGrow: 0 }}
              icon="trash"
              intent="danger"
              type="button"
              onClick={() => parentFields.remove(index)}
            />
          </ControlGroup>
          <FieldArray name={`${name}.exercises`}>
            {({ fields }) => {
              const innerDroppableId = `${droppableId}-${index}`
              const handleExerciseDragEnd = event => {
                if (
                  event.destination.droppableId === innerDroppableId &&
                  event.source.droppableId === innerDroppableId
                ) {
                  form.mutators.arrayMove(
                    fields.name,
                    event.source.index,
                    event.destination.index,
                  )
                  fields.forEach((name, index) => {
                    // console.log({ name, index })
                    form.change(`${name}.position`, index)
                  })
                }
              }
              return (
                <Droppable
                  type={innerDroppableId}
                  onDragEnd={handleExerciseDragEnd}
                  droppableId={innerDroppableId}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {fields.map((name, index) => (
                        <ExerciseBlockField
                          name={name}
                          index={index}
                          fields={fields}
                          exerciseType={exerciseType}
                          droppableId={innerDroppableId}
                        />
                      ))}
                      {provided.placeholder}
                      <CenteredRow style={{ padding: '15px 0' }}>
                        <Button
                          intent="primary"
                          icon="plus"
                          onClick={() =>
                            fields.push({
                              '@type': 'ExerciseSingleBlock',
                              position: fields.length,
                            })
                          }
                        >
                          Übung zum Komplex hinzufügen
                        </Button>
                      </CenteredRow>
                    </div>
                  )}
                </Droppable>
              )
            }}
          </FieldArray>
        </div>
      )}
    </Draggable>
  )
}

export default ExerciseComplexBlockField
