const grid = 4
export const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'none',
  padding: 4,
})

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 8px ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'none',

  // styles we need to apply on draggables
  ...draggableStyle,
})
