import { Button, ButtonGroup, Classes, Spinner } from '@blueprintjs/core'
import { isFinite, toNumber } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Hover } from 'react-powerplug'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  saveComponentToServer,
  updateComponent,
} from '../../../ducks/components'
import BeautifulDragHandle from '../../Layout/BeautifulDragHandle'
import Row from '../../Layout/StyledComponents/Row'
import ModuleComponentEdit from '../../ModuleComponentEdit'

const Container = styled.div`
  border-left: ${props => `5px solid rgba(${props.color}, 0.57)`};
  padding: 5px;
  margin-bottom: 10px;
`

class ModuleComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: true,
    }
  }

  toggleExpand = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  handleValueChange = ({
    target: { name, type, checked, value: targetValue },
  }) => {
    let value = type === 'checkbox' ? checked : targetValue
    if (name === 'timecap' || name === 'duration') {
      value = toNumber(value) * 60
      if (!isFinite(value)) {
        return
      }
    }

    this.props.updateComponent({
      id: this.props.component.id,
      [name]: value,
    })
  }

  handleNumberValueChange = ({ name, value }) => {
    let numberValue = value
    if (name === 'timecap' || name === 'duration') {
      numberValue = value * 60
    }
    this.props.updateComponent({
      id: this.props.component.id,
      [name]: numberValue,
    })
  }

  handleSelectChange = (name, value) => {
    this.props.updateComponent({
      id: this.props.component.id,
      [name]: value.value,
    })
  }

  handleRemove = () => {
    this.props.onRemove(this.props.component)
  }

  handleSave = () => {
    this.props.saveComponentToServer(this.props.component)
  }

  render() {
    const { component, color, title } = this.props
    if (component.fetch && component.fetch > 0) {
      return <Spinner />
    }
    return (
      <Hover>
        {({ hovered, bind }) => (
          <Container
            color={color}
            className={hovered ? Classes.ELEVATION_4 : undefined}
          >
            <Row>
              <div style={{ flexGrow: 1 }}>
                <h3>{title || component['@type']}</h3>
              </div>
              <ButtonGroup {...bind}>
                <Button
                  intent="success"
                  icon="floppy-disk"
                  onClick={this.handleSave}
                  // disabled={!this.props.component.dirty}
                />
                <Button
                  icon={this.state.expanded ? 'chevron-up' : 'chevron-down'}
                  onClick={this.toggleExpand}
                />
                {this.props['@type'] !== 'WeightliftingModuleComponent' && (
                  <Button
                    icon="trash"
                    intent="danger"
                    onClick={this.handleRemove}
                  />
                )}
              </ButtonGroup>
            </Row>
            {this.state.expanded && (
              <ModuleComponentEdit
                {...this.props}
                onSelectChange={this.handleSelectChange}
                onChange={this.handleValueChange}
                onNumberValueChange={this.handleNumberValueChange}
              />
            )}
          </Container>
        )}
      </Hover>
    )
  }
}

ModuleComponent.propTypes = {
  title: PropTypes.string,
  component: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  color: PropTypes.string,
  fields: PropTypes.array.isRequired,
}

ModuleComponent.defaultProps = {
  fields: [],
}

export default connect(
  null,
  { updateComponent, saveComponentToServer },
)(ModuleComponent)
