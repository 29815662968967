import React from 'react'
import { head } from 'lodash'
import {
  lifecycle,
  compose,
  withState,
  withHandlers,
  renderComponent,
  branch,
} from 'recompose'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import { set } from 'dot-prop-immutable'
import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'

import { Spinner } from '@blueprintjs/core'
import { fetchBlogPost, updatePost } from '../../lib/fetchService'
import { fetchPosts } from '../../ducks/posts'
import history from '../../createHistory'

const withPost = lifecycle({
  componentWillMount() {
    const {
      match: {
        params: { slug },
      },
    } = this.props
    this.fetchPost(slug)
  },
  componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { slug },
      },
    } = this.props
    const {
      match: {
        params: { slug: newSlug },
      },
    } = nextProps
    if (slug !== newSlug) {
      this.fetchPost(newSlug)
    }
  },
  async fetchPost(slug) {
    this.props.setPost(null)
    const { data: posts } = await fetchBlogPost(slug)
    this.props.setPost(head(posts['hydra:member']))
  },
})

const withSpinnerWhileLoading = branch(
  ({ post }) => !post,
  renderComponent(Spinner),
)

const enhance = compose(
  connect(
    null,
    { fetchPosts },
  ),
  withState('post', 'setPost', null),
  withHandlers({
    handleChange: ({ setPost, post }) => ({
      target: { name, value, type, checked },
    }) => setPost(set(post, name, type === 'checkbox' ? checked : value)),
    handleContentChange: ({ setPost, post }) => value =>
      setPost(set(post, 'content', value)),
    handleAbstractChange: ({ setPost, post }) => value =>
      setPost(set(post, 'abstract', value)),
    handleSubmit: ({ post, setPost, fetchPosts }) => async e => {
      e.preventDefault()
      try {
        setPost(null)
        const savedPost = await updatePost(post)
        setPost(savedPost)
        fetchPosts()
        // showNotification(
        //   {
        //     title: (
        //       <span>
        //         <i className="fa fa-check" /> Speichern erfolgreich
        //       </span>
        //     ),
        //   },
        //   'success',
        // )
        history.push(`/blog/${savedPost.slug}`)
      } catch (error) {
        setPost(post)
        // showNotification({ title: 'Fehler', message: error }, 'error')
      }
    },
  }),
  withPost,
  withSpinnerWhileLoading,
)

const PostEdit = ({
  post,
  handleChange,
  handleContentChange,
  handleAbstractChange,
  handleSubmit,
}) => {
  const { title, content, abstract, author, createdAt, published } = post
  return (
    <form onSubmit={handleSubmit}>
      <div className="grid-x grid-margin-x">
        <div className="cell auto">
          <label>
            Titel
            <input
              type="text"
              name="title"
              value={title}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="cell medium-6">
          <label>
            Author
            <input
              type="text"
              name="author"
              value={author}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="cell medium-6">
          <label>
            Datum
            <input
              type="text"
              readOnly
              name="createdAt"
              value={format(createdAt, 'DD.MM.YYYY')}
            />
          </label>
        </div>
      </div>
      <div className="grid-x">
        <div className="cell auto">
          <label>
            Abstract
            <ReactQuill
              theme="snow"
              value={abstract}
              onChange={handleAbstractChange}
              modules={PostEdit.modules}
              formats={PostEdit.formats}
            />
          </label>
        </div>
      </div>
      <div className="grid-x">
        <div className="cell auto">
          <label>
            Inhalt
            <ReactQuill
              theme="snow"
              value={content}
              onChange={handleContentChange}
              modules={PostEdit.modules}
              formats={PostEdit.formats}
            />
          </label>
        </div>
      </div>
      <div className="grid-x">
        <div className="cell auto">
          <input
            id="published"
            name="published"
            type="checkbox"
            checked={published}
            onChange={handleChange}
          />
          <label htmlFor="published">Veröffentlicht</label>
        </div>
      </div>
      <div className="grid-x">
        <div className="cell auto">
          <input type="submit" className="success button" value="Speichern" />
        </div>
      </div>
    </form>
  )
}

PostEdit.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    ['clean'],
  ],
}

PostEdit.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'video',
]

export default enhance(PostEdit)
