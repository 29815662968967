import { instance } from '../fetchService'

export const baseUrl = process.env.REACT_APP_API_URL

export const createTemplateMicrocycle = name =>
  instance.post('template_microcycles', {
    name,
  })

export const fetchTemplateMicrocycles = () =>
  instance.get('template_microcycles')

export const removeTemplateMicrocycle = microcycle =>
  instance.delete(`template_microcycles/${microcycle.id}`)

export const fetchTemplateMicrocycle = id =>
  instance.get(`template_microcycles/${id}`)

export const insertMicrocycleAtDate = ({ id, date }) =>
  instance.post(`template_microcycles/${id}/copy_to_date/${date}`, {})

export const fetchTemplateMesocycles = () => instance.get('template_mesocycles')

export const createTemplateMesocycle = name =>
  instance.post('template_mesocycles', {
    name,
  })

export const fetchTemplateMesocycle = id =>
  instance.get(`template_mesocycles/${id}`)

export const addMicrocycleToMesocycle = ({ microcycle, mesocycle }) =>
  instance.put(`template_mesocycles/${mesocycle.id}`, {
    microcycles: [
      ...mesocycle.microcycles.map(c => c['@id']),
      microcycle['@id'],
    ],
  })

export const removeMicrocycleFromMesocycle = ({ microcycle, mesocycle }) =>
  instance.put(`template_mesocycles/${mesocycle.id}`, {
    microcycles: mesocycle.microcycles
      .filter(m => m.id !== microcycle.id)
      .map(c => c['@id']),
  })

export const insertMesocycleAtDate = ({ id, date }) =>
  instance.post(`template_mesocycles/${id}/copy_to_date/${date}`, {})

export const saveTemplateModule = templateModule =>
  instance.put(
    `template_microcycle_modules/${templateModule.id}`,
    templateModule,
  )

export const fetchTemplateModules = () =>
  instance.get('modules', {
    params: {
      pagination: false,
      'dates[exists]': false,
    },
  })

export const removeTemplateModule = templateModule =>
  instance.delete(`template_microcycle_modules/${templateModule.id}`)

export const moveTemplateModule = ({ id, ...rest }) =>
  instance.put(`template_microcycle_modules/${id}`, rest)

export const createTemplateModule = args =>
  instance.post('create_template_module', args, {
    headers: { 'Content-Type': 'application/json' },
  })
