import { Menu, MenuItem, Intent } from '@blueprintjs/core'
import { Suggest } from '@blueprintjs/select'
import fuzzaldrin from 'fuzzaldrin-plus'
import PropTypes from 'prop-types'
import React from 'react'
import { FixedSizeList as List } from 'react-window'
import { KeyCodes } from '@blueprintjs/core/lib/esm/components/hotkeys/hotkeyParser'

const fuzzyFilter = (items, input) =>
  input ? fuzzaldrin.filter(items, input, { key: 'label' }) : items

const BlueprintSearch = ({
  options,
  onChange,
  value,
  shouldDismissPopover,
  placeholder,
  inputProps,
  popoverProps,
}) => (
  <Suggest
    popoverProps={{
      targetTagName: 'div',
      captureDismiss: true,
      usePortal: true,
      ...popoverProps,
    }}
    inputProps={{
      value:
        value &&
        options.find(option => option.value === value) &&
        options.find(option => option.value === value).label,
      placeholder,
      onKeyPress: e => {
        if (e.key === 'Enter') {
          e.preventDefault()
        }
      },
      ...inputProps,
    }}
    selectedItem={value && options.find(option => option.value === value)}
    inputValueRenderer={() =>
      value &&
      options.find(option => option.value === value) &&
      options.find(option => option.value === value).label
    }
    itemListPredicate={(query, items) => fuzzyFilter(items, query)}
    itemListRenderer={({ filteredItems, renderItem }) => (
      <Menu>
        <List
          width={400}
          height={filteredItems.length < 5 ? filteredItems.length * 30 : 180}
          itemSize={30}
          itemCount={filteredItems.length}
        >
          {({ index, style, isScrolling }) => (
            <div style={style}>{renderItem(filteredItems[index])}</div>
          )}
        </List>
      </Menu>
    )}
    itemRenderer={(item, { handleClick, modifiers }) => (
      <MenuItem
        active={modifiers.active}
        label={item.label}
        onClick={handleClick}
        shouldDismissPopover={shouldDismissPopover}
      />
    )}
    onItemSelect={onChange}
    items={options}
  />
)

BlueprintSearch.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  shouldDismissPopover: PropTypes.func,
  placeholder: PropTypes.string,
}

BlueprintSearch.defaultProps = {
  shouldDismissPopover: true,
  placeholder: 'Bitte wählen...',
}

export default BlueprintSearch
