import { Button, ControlGroup, Intent } from '@blueprintjs/core'
import { get } from 'lodash'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { addMicrocycleToMesocycle } from '../../../lib/fetch/templates'
import { AppToaster } from '../../../lib/toaster'
import MicrocycleTemplateSearch from '../MicrocycleTemplates/MicrocycleTemplateSearch'

const validate = values => {
  const errors = {}
  if (!get(values, 'microcycle.id')) {
    errors.cycle = 'Pflichtfeld'
  }
  return errors
}

const AddMicrocycleTemplateForm = ({ refetch, style, mesocycle }) => {
  const onSubmit = async ({ microcycle }) => {
    try {
      await addMicrocycleToMesocycle({ microcycle, mesocycle })
      refetch()
      AppToaster.show({
        message: 'Zyklus hinzugefügt.',
        intent: 'success',
      })
    } catch (e) {
      AppToaster.show({
        message: get(e, 'response.data.hydra:description'),
        intent: 'warning',
      })
    }
  }
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, pristine, invalid, form: { reset } }) => (
        <form
          style={{ ...style }}
          onSubmit={async e => {
            await handleSubmit(e)
            reset()
          }}
        >
          <ControlGroup fill>
            <Field
              name="microcycle"
              render={({ input, meta }) => (
                <MicrocycleTemplateSearch
                  cycle={input.value}
                  onChange={input.onChange}
                />
              )}
            />

            <Button
              icon="new-grid-item"
              type="submit"
              intent={Intent.SUCCESS}
              disabled={pristine || invalid}
            >
              Microzyklus hinzufügen
            </Button>
          </ControlGroup>
        </form>
      )}
    />
  )
}

export default AddMicrocycleTemplateForm
