export const SET_EXERCISES = 'SET_EXERCISES'

export const setExercises = exercises => ({
  type: SET_EXERCISES,
  exercises,
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_EXERCISES:
      return action.exercises
    default:
      return state
  }
}

export default reducer
