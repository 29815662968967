export const SET_LIFTS = 'SET_LIFTS'

const reducer = (state = [], action) => {
  switch (action.type) {
    case SET_LIFTS: {
      return action.lifts
    }
    default:
      return state
  }
}

export default reducer
