import { ApolloProvider } from '@apollo/react-hooks'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import * as Sentry from '@sentry/browser'
import ApolloClient from 'apollo-client'

import de from 'date-fns/locale/de'
import { Settings } from 'luxon'
import React from 'react'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { AUTH_TOKEN } from 'lib/constants'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import App from './App'
import history from './createHistory'
import createStore from './createStore'

import introspectionQueryResultData from './generated/fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const cache = new InMemoryCache({ fragmentMatcher })

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_TOKEN)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
})

registerLocale('de', de)
setDefaultLocale('de')

Settings.defaultZoneName = 'Europe/Berlin'

if (process.env === 'production') {
  Sentry.init({
    dsn: 'https://718e03f30a234438948edcdd738f1eb2@sentry.io/1387845',
    maxBreadcrumbs: 20,
  })
}

const store = createStore()

ReactDOM.render(
  <RecoilRoot>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    </ApolloProvider>
  </RecoilRoot>,
  document.getElementById('root'),
)
