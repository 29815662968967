import { denormalize, normalize, schema } from 'normalizr'

const options = {
  idAttribute: 'id',
}

const RoundModuleComponent = new schema.Entity(
  'roundModuleComponents',
  {},
  options,
)
const TextModuleComponent = new schema.Entity(
  'textModuleComponents',
  {},
  options,
)

const RestModuleComponent = new schema.Entity(
  'restModuleComponents',
  {},
  options,
)

const PowerbuildingModuleComponent = new schema.Entity(
  'powerbuildingModuleComponents',
  {},
  options,
)

const StrengthModuleComponent = new schema.Entity(
  'strengthModuleComponents',
  {},
  options,
)

export const EmomModuleComponent = new schema.Entity(
  'emomModuleComponents',
  options,
)

const WeightliftingModuleComponent = new schema.Entity(
  'weightliftingModuleComponents',
  {},
  options,
)
const ScoringModuleComponent = new schema.Entity(
  'scoringModuleComponents',
  {},
  options,
)

const componentUnionSchema = new schema.Union(
  {
    RoundModuleComponent,
    RestModuleComponent,
    StrengthModuleComponent,
    EmomModuleComponent,
    WeightliftingModuleComponent,
    ScoringModuleComponent,
    TextModuleComponent,
    PowerbuildingModuleComponent,
  },
  '@type',
)

export const module = new schema.Entity(
  'modules',
  {
    components: [componentUnionSchema],
  },
  options,
)

export const calendarModule = new schema.Entity('modules', {}, options)

export const arrayOfModules = new schema.Array(module)

export const moduleDate = new schema.Entity(
  'moduleDates',
  {
    module,
  },
  options,
)

export const calendarModuleDate = new schema.Entity(
  'moduleDates',
  {
    module: calendarModule,
  },
  {
    ...options,
  },
)

export const arrayOfModuleDates = new schema.Array(moduleDate)
export const arrayOfCalendarModuleDates = new schema.Array(calendarModuleDate)

const weekplan = new schema.Entity('weekplans', {})

export const arrayOfMicrocycles = new schema.Array(weekplan)

export const normalizeWeekPlan = data => normalize(data, weekplan)

export const denormalizeWeekPlan = (data, entities) =>
  denormalize(data, weekplan, entities)

export const denormalizeComponent = (data, entities) => {
  let schema = null
  switch (
    data['@type'] // eslint-disable-line default-case
  ) {
    case 'RoundModuleComponent':
      schema = RoundModuleComponent
      break
    case 'RestModuleComponent':
      schema = RestModuleComponent
      break
    case 'PowerbuildingModuleComponent':
      schema = PowerbuildingModuleComponent
      break
    case 'StrengthModuleComponent':
      schema = StrengthModuleComponent
      break
    case 'EmomModuleComponent':
      schema = EmomModuleComponent
      break
    case 'WeightliftingModuleComponent':
      schema = WeightliftingModuleComponent
      break
    case 'ScoringModuleComponent':
      schema = ScoringModuleComponent
      break
    case 'TextModuleComponent':
      schema = TextModuleComponent
      break
    default:
      break
  }
  return denormalize(data, schema, entities)
}

export const denormalizeModule = (data, entities) =>
  denormalize(data, module, entities)

export const denormalizeModuleDate = (data, entities) =>
  denormalize(data, moduleDate, entities)
