import { ButtonGroup } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { fetchModule } from 'ducks/modules'
import NewModuleComponentButton from './Module/NewModuleComponentButton'

const CenteredButtonGroup = styled(ButtonGroup)`
  &&& {
    display: flex;
    justify-content: center;
  }
`

const mutation = gql`
  mutation addNewComponent($data: AddNewComponentInput!) {
    addNewComponent(data: $data) {
      ... on IModuleComponent {
        id
      }
    }
  }
`

const AddModuleActions = ({ handleNewComponent, moduleId }) => {
  const [mutate] = useMutation(mutation)
  const dispatch = useDispatch()

  const apolloHandleNewComponent = async schema => {
    await mutate({ variables: { data: { moduleId, schema } } })
    dispatch(fetchModule(moduleId))
  }

  return (
    <CenteredButtonGroup>
      <NewModuleComponentButton
        type="RoundModuleComponent"
        label="Runden"
        onClick={handleNewComponent}
      />
      <NewModuleComponentButton
        type="EmomModuleComponent"
        onClick={handleNewComponent}
        label="EMOM"
      />
      <NewModuleComponentButton
        type="TextModuleComponent"
        onClick={handleNewComponent}
        label="Text"
      />
      <NewModuleComponentButton
        type="ScoringModuleComponent"
        onClick={handleNewComponent}
        label="Score"
      />
      <NewModuleComponentButton
        type="RestModuleComponent"
        onClick={handleNewComponent}
        label="Pause"
      />
      {/* <NewModuleComponentButton
        type="StrengthModuleComponent"
        onClick={handleNewComponent}
        label="Strength (Do not use)"
      /> */}
      <NewModuleComponentButton
        type="PowerbuildingModuleComponent"
        onClick={apolloHandleNewComponent}
        label="Powerbuilding"
      />
    </CenteredButtonGroup>
  )
}

AddModuleActions.propTypes = {
  handleNewComponent: PropTypes.func.isRequired,
  moduleId: PropTypes.number.isRequired,
}

export default AddModuleActions
