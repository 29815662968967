import { call, put, takeEvery } from 'redux-saga/effects'
import { dispatchFetchError, FETCH_END, FETCH_START } from '../ducks/fetch'
import { fetchUsers } from '../lib/fetchService'

export const FETCH_USERS_REQUEST = 'USERS::FETCH_REQUEST'
export const FETCH_USERS_SUCCESS = 'USERS::FETCH_SUCCESS'
export const FETCH_USERS_FAIL = 'USERS::FETCH_FAIL'

export const requestFetchUsers = () => ({
  type: FETCH_USERS_REQUEST,
})

function* getUsers() {
  try {
    yield put({ type: FETCH_START, endpoint: 'users' })
    const { data: users } = yield call(fetchUsers)
    yield put({
      type: FETCH_USERS_SUCCESS,
      users: users['hydra:member'],
    })
    yield put({ type: FETCH_END, endpoint: 'users' })
  } catch (error) {
    yield put({ type: FETCH_USERS_FAIL, message: error.message })
    yield put(dispatchFetchError({ endpoint: 'users', error }))
  }
}

export function* fetchUsersSaga() {
  yield takeEvery(FETCH_USERS_REQUEST, getUsers)
}
