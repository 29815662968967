import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@blueprintjs/core'

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default () => (
  <Centered>
    <Spinner />
  </Centered>
)
