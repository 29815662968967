import {
  Button,
  ButtonGroup,
  Classes,
  Intent,
  Spinner,
  Tree,
} from '@blueprintjs/core'
import produce from 'immer'
import { AppToaster } from 'lib/toaster'
import { get, isEqual } from 'lodash'
import React, { Component } from 'react'
import styled from 'styled-components'
import {
  fetchStrengthPlan,
  saveStrengthPlan,
} from '../../lib/fetch/strengthplans'
import StrengthPlanContext from './StrengthPlanContext'
import StrengthPlanEditDetail from './StrengthPlanEditDetail'

const Container = styled.div`
  display: grid;
  grid-template-columns: 300px max-content;
  grid-gap: 16px;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  > *:not(first-child) {
    margin-top: 1em;
  }
`

const Heading = styled.h2`
  grid-column: 1 / -1;
`

const createId = obj => JSON.stringify(obj)

const parseId = string => JSON.parse(string)

export default class StrengthPlanEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      plan: null,
      openNodes: [],
      selected: null,
      updateMesocycle: this.updateMesocycle,
      updateMicrocycle: this.updateMicrocycle,
      loading: false,
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props

    const res = await fetchStrengthPlan(id)
    this.setState({ plan: res.data })
  }

  toggleExpand = node => {
    if (this.state.openNodes.find(n => n === node.id)) {
      this.setState({
        openNodes: this.state.openNodes.filter(n => n !== node.id),
      })
    } else {
      this.setState({
        openNodes: this.state.openNodes.concat(node.id),
      })
    }
  }

  handleSave = async () => {
    this.setState({ loading: true })
    try {
      await saveStrengthPlan(this.state.plan)
      this.fetchData()
      AppToaster.show({
        message: 'Plan gespeichert.',
        intent: 'success',
      })
    } catch (e) {
      AppToaster.show({
        message: get(e.response.data, 'hydra:description'),
        intent: 'warning',
      })
    }
    this.setState({ loading: false })
  }

  setNode = node =>
    this.setState(
      produce(draft => {
        draft.selected = parseId(node.id)
      }),
    )

  addMesocycle = () =>
    this.setState(
      produce(draft => {
        draft.plan.mesocycles.push({
          name: `Mesozyklus ${draft.plan.mesocycles.length + 1}`,
          position: draft.plan.mesocycles.length,
          microcycles: [],
        })
      }),
    )

  removeMesocyle = idx =>
    this.setState(
      produce(draft => {
        draft.plan.mesocycles.splice(idx, 1)
      }),
    )

  addMicrocycle = idx =>
    this.setState(
      produce(draftState => {
        const m = draftState.plan.mesocycles[idx]

        m.microcycles.push({
          name: `Microzyklus ${m.microcycles.length + 1}`,
          position: m.microcycles.length,
          sets: [],
        })
      }),
    )

  removeMicrocycle = (mesoIdx, microIdx) =>
    this.setState(
      produce(draft => {
        draft.plan.mesocycles[mesoIdx].microcycles.splice(microIdx, 1)
      }),
    )

  updateMicrocycle = ({ mesoIndex, microIndex, microcycle }) => {
    this.setState(
      produce(draft => {
        draft.plan.mesocycles[mesoIndex].microcycles[microIndex] = microcycle
      }),
    )
  }

  updateMesocycle = ({ mesoIndex, mesocycle }) => {
    this.setState(
      produce(draft => {
        draft.plan.mesocycles[mesoIndex] = mesocycle
      }),
    )
  }

  render() {
    const { plan, selected, loading } = this.state
    if (!plan) {
      return <Spinner />
    }
    const { name, mesocycles } = plan
    return (
      <Container>
        <Heading>{name}</Heading>
        <InnerContainer>
          <Tree
            contents={mesocycles.map((mesocycle, mesoIndex) => {
              const id = createId({ type: 'mesocycle', mesoIndex })
              return {
                id,
                nodeData: mesocycle,
                label: mesocycle.name,
                isExpanded: true,
                isSelected: selected && isEqual(JSON.stringify(selected), id),
                secondaryLabel: (
                  <ButtonGroup>
                    <Button
                      minimal
                      icon="add"
                      intent={Intent.SUCCESS}
                      onClick={() => this.addMicrocycle(mesoIndex)}
                    />
                    <Button
                      minimal
                      icon="trash"
                      intent={Intent.DANGER}
                      onClick={() => this.removeMesocyle(mesoIndex)}
                    />
                  </ButtonGroup>
                ),
                childNodes: mesocycle.microcycles.map(
                  (microcycle, microIndex) => {
                    const id = createId({
                      type: 'microcycle',
                      mesoIndex,
                      microIndex,
                    })
                    return {
                      id,
                      label: microcycle.name,
                      isSelected:
                        selected && isEqual(JSON.stringify(selected), id),
                      secondaryLabel: (
                        <Button
                          minimal
                          icon="trash"
                          intent={Intent.DANGER}
                          onClick={() =>
                            this.removeMicrocycle(mesoIndex, microIndex)
                          }
                        />
                      ),
                    }
                  },
                ),
              }
            })}
            onNodeClick={this.setNode}
            onNodeCollapse={this.toggleExpand}
            onNodeExpand={this.toggleExpand}
            className={Classes.ELEVATION_1}
          />
          <Button
            intent={Intent.SUCCESS}
            icon="add"
            text="Mesozyklus hinzufügen"
            onClick={this.addMesocycle}
          />
          <Button
            intent={Intent.SUCCESS}
            icon="save"
            text="Speichern"
            loading={loading}
            onClick={this.handleSave}
          />
        </InnerContainer>
        <InnerContainer>
          <StrengthPlanContext.Provider value={this.state}>
            <StrengthPlanEditDetail />
          </StrengthPlanContext.Provider>
        </InnerContainer>
      </Container>
    )
  }
}
