import produce from 'immer'
import React, { useEffect, useMemo, useState } from 'react'
import {
  useExpanded,
  useGroupBy,
  usePagination,
  useTable,
  useSortBy,
} from 'react-table'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import EditableNumberCell from 'components/Powerbuilding/EditableNumberCell'
import { Button } from '@blueprintjs/core'
import EditableCell from './EditableCell'
import ActionCell from './ActionCell'
import EditableExerciseCell from './EditableExerciseCell'
import EditableBenchmarkCell from './EditableBenchmarkCell'

const ADD_MUTATION = gql`
  mutation insertPowerbuildingCycleExercise(
    $data: InsertPowerbuildingCycleExerciseInput!
  ) {
    insertPowerbuildingCycleExercise(data: $data) {
      id
      week
      day
      exerciseOfWeightliftingModule
      exercise {
        ... on IExercise {
          id
          name
        }
      }
      benchmark {
        id
      }
      preModifier
      postModifier
      identifier
      warmupSets
      sets
      reps
      load
      repsInReserve
      rateOfPerceivedExertion
      comment
      tempo

      strengthPlan {
        id
        name
      }

      createdAt
      updatedAt
    }
  }
`

const EDIT_MUTATION = gql`
  mutation updatePowerbuildingCycleExercise(
    $data: UpdatePowerbuildingCycleExerciseInput!
  ) {
    updatePowerbuildingCycleExercise(data: $data) {
      id
      week
      day
      exerciseOfWeightliftingModule
      exercise {
        ... on IExercise {
          id
          name
        }
      }
      benchmark {
        id
      }
      preModifier
      postModifier
      identifier
      warmupSets
      sets
      reps
      load
      repsInReserve
      rateOfPerceivedExertion
      comment
      tempo

      strengthPlan {
        id
        name
      }

      createdAt
      updatedAt
    }
  }
`

const DELETE_MUTATION = gql`
  mutation deletePowerbuildingCycleExercise($id: ID!) {
    deletePowerbuildingCycleExercise(id: $id) {
      id
    }
  }
`

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
}

const PowerbuildingTable = ({ data }) => {
  const [tableData, setTableData] = useState(data)
  const [dirtyCells, setDirtyCells] = useState([])

  const [skipPageReset, setSkipPageReset] = React.useState(false)

  const [editMutate, { loading }] = useMutation(EDIT_MUTATION, {
    refetchQueries: ['powerbuildingCycle'],
  })

  const [addMutate] = useMutation(ADD_MUTATION, {
    refetchQueries: ['powerbuildingCycle'],
  })

  const [deleteMutate] = useMutation(DELETE_MUTATION, {
    refetchQueries: ['powerbuildingCycle'],
  })

  const handleRowDelete = async id => {
    if (id > 0) {
      await deleteMutate({ variables: { id } })
    }
    setTableData(data =>
      produce(data, draft => {
        const idx = draft.powerbuildingCycle.exercises.findIndex(
          e => e.id === id,
        )

        delete draft.powerbuildingCycle.exercises[idx]
      }),
    )
  }

  const handleRowSave = async cell => {
    setSkipPageReset(true)

    setTableData(data =>
      produce(data, draft => {
        draft.powerbuildingCycle.exercises.find(
          e => e.id === cell.id,
        ).saving = true
      }),
    )

    if (cell.id > 0) {
      const payload = produce(cell, draft => {
        draft.benchmarkId = draft.benchmark?.id
        draft.week = Number(draft.week)
        delete draft.benchmark
        draft.exerciseId = draft.exercise?.id
        delete draft.exercise
        draft.strengthPlanId = draft.strengthPlan?.id
        delete draft.strengthPlan
        delete draft.createdAt
        delete draft.updatedAt
        delete draft.__typename
        delete draft.dirty
        delete draft.saving
        delete draft.error
      })
      const res = await editMutate({ variables: { data: payload } })
      setTableData(data =>
        produce(data, draft => {
          const idx = draft.powerbuildingCycle.exercises.findIndex(
            e => e.id === res.data.updatePowerbuildingCycleExercise.id,
          )

          draft.powerbuildingCycle.exercises[idx] =
            res.data.updatePowerbuildingCycleExercise
        }),
      )
    } else {
      const payload = produce(cell, draft => {
        draft.benchmarkId = draft.benchmark?.id
        draft.week = Number(draft.week)
        delete draft.benchmark
        draft.exerciseId = draft.exercise?.id
        delete draft.exercise
        draft.strengthPlanId = draft.strengthPlan?.id
        delete draft.strengthPlan
        delete draft.createdAt
        delete draft.updatedAt
        delete draft.__typename
        delete draft.dirty
        delete draft.id
        delete draft.saving
        delete draft.error
        draft.cycleId = data.powerbuildingCycle.id
      })

      try {
        const res = await addMutate({ variables: { data: payload } })
        setTableData(data =>
          produce(data, draft => {
            const idx = draft.powerbuildingCycle.exercises.findIndex(
              e => e.id === cell.id,
            )

            draft.powerbuildingCycle.exercises[idx] =
              res.data.insertPowerbuildingCycleExercise
          }),
        )
      } catch (e) {
        setTableData(data =>
          produce(data, draft => {
            draft.powerbuildingCycle.exercises.find(
              e => e.id === cell.id,
            ).error = true
          }),
        )
      }
    }
  }

  const handleAdd = () => {
    setSkipPageReset(true)
    setTableData(tableData =>
      produce(tableData, draft => {
        const newRow = {
          dirty: true,
          id: draft.powerbuildingCycle.exercises.length * -1,
        }
        if (draft.powerbuildingCycle.exercises.length > 0) {
          const lastRow =
            draft.powerbuildingCycle.exercises[
              draft.powerbuildingCycle.exercises.length - 1
            ]

          newRow.week = lastRow.week
          newRow.day = lastRow.day
          newRow.identifier = 'X'
        }

        draft.powerbuildingCycle.exercises.push(newRow)
      }),
    )
  }

  const handleTableUpdate = (rowIdx, column, content) => {
    setSkipPageReset(true)
    setTableData(data =>
      produce(data, draft => {
        draft.powerbuildingCycle.exercises[rowIdx][column] = content
        draft.powerbuildingCycle.exercises[rowIdx].dirty = true
      }),
    )
  }

  const handleExerciseUpdate = (rowIdx, column, content) => {
    setSkipPageReset(true)
    setTableData(data =>
      produce(data, draft => {
        draft.powerbuildingCycle.exercises[rowIdx][column] = content
        draft.powerbuildingCycle.exercises[rowIdx].dirty = true
      }),
    )
  }

  React.useEffect(() => {
    setSkipPageReset(false)
  }, [data])

  const initialGroupBy = useMemo(() => ['week'], [])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Woche',
        accessor: 'week', // accessor is the "key" in the data
        disableGroupBy: true,
      },
      {
        Header: 'Tag',
        accessor: 'day',
        disableGroupBy: true,
        Cell: EditableNumberCell,
      },
      {
        Header: 'Position',
        accessor: 'identifier',
        disableGroupBy: true,
      },
      {
        Header: 'preModifier',
        accessor: 'preModifier',
        disableGroupBy: true,
      },
      {
        Header: 'Übung',
        id: 'exercise',
        accessor: 'exercise.id',
        disableGroupBy: true,
        Cell: EditableExerciseCell,
        minWidth: 300,
      },
      {
        Header: 'postModifier',
        accessor: 'postModifier',
        disableGroupBy: true,
      },
      {
        Header: 'Warmup Sets',
        accessor: 'warmupSets',
        disableGroupBy: true,
        Cell: EditableNumberCell,
      },
      {
        Header: 'Sets',
        accessor: 'sets',
        disableGroupBy: true,
        Cell: EditableNumberCell,
      },
      {
        Header: 'Reps',
        accessor: 'reps',
        disableGroupBy: true,
      },
      {
        Header: 'Load',
        accessor: 'load',
        disableGroupBy: true,
      },

      {
        Header: 'RIR',
        accessor: 'repsInReserve',
        disableGroupBy: true,
      },
      {
        Header: 'RPE',
        accessor: 'rateOfPerceivedExertion',
        disableGroupBy: true,
        Cell: EditableNumberCell,
      },
      {
        Header: 'Rest',
        accessor: 'rest',
        disableGroupBy: true,
      },
      {
        Header: 'Tempo',
        accessor: 'tempo',
        disableGroupBy: true,
      },
      {
        Header: 'Kommentar',
        accessor: 'comment',
        disableGroupBy: true,
      },
      {
        Header: 'Last abhängig von',
        id: 'benchmark',
        accessor: 'benchmark.id',
        disableGroupBy: true,
        Cell: EditableBenchmarkCell,
      },

      {
        Header: 'Aktionen',
        id: 'actions',
        disableGroupBy: true,
        Cell: ActionCell,
      },
    ],
    [],
  )

  const rowData = useMemo(
    () => tableData?.powerbuildingCycle?.exercises || [],
    [tableData],
  )

  const tableInstance = useTable(
    {
      columns,
      data: rowData,
      initialState: {
        // groupBy: initialGroupBy
        pageSize: 10,
        sortBy: [
          {
            id: 'week',
            desc: false,
          },
          {
            id: 'day',
            desc: false,
          },
          {
            id: 'identifier',
            desc: false,
          },
        ],
      },
      defaultColumn,
      autoResetPage: !skipPageReset,
      handleTableUpdate,
      handleExerciseUpdate,
      handleRowSave,
      handleRowDelete,
    },
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.canGroupBy ? (
                    // If the column can be grouped, let's add a toggle
                    <span {...column.getGroupByToggleProps()}>
                      {column.isGrouped ? '🛑 ' : '👊 '}
                    </span>
                  ) : null}
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
          page.map(row => {
            // Prepare the row for display
            prepareRow(row)
            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td
                    // For educational purposes, let's color the
                    // cell depending on what type it is given
                    // from the useGroupBy hook
                    {...cell.getCellProps()}
                    style={{
                      background: cell.row.original.error
                        ? '#ff000042'
                        : cell.row.original.saved
                        ? '#0aff0082'
                        : cell.row.original.saving
                        ? '#ffa50078'
                        : cell.row.original.dirty
                        ? 'gray'
                        : 'white',
                    }}
                  >
                    {cell.isGrouped ? (
                      // If it's a grouped cell, add an expander and row count
                      <>
                        <span {...row.getToggleRowExpandedProps()}>
                          {row.isExpanded ? '👇' : '👉'}
                        </span>{' '}
                        {cell.render('Cell')} ({row.subRows.length})
                      </>
                    ) : cell.isAggregated ? (
                      // If the cell is aggregated, use the Aggregated
                      // renderer for cell
                      cell.render('Aggregated')
                    ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                      // Otherwise, just render the regular cell
                      cell.render('Cell')
                    )}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="pagination">
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </Button>{' '}
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </Button>{' '}
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </Button>{' '}
        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </Button>{' '}
        <span>
          Seite{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Zu Seite:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize} anzeigen
            </option>
          ))}
        </select>
      </div>
      <div>
        <Button icon="plus" onClick={handleAdd}>
          Übung hinzufügen
        </Button>
      </div>
    </>
  )
}

export default PowerbuildingTable
