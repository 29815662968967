import {
  Button,
  Classes,
  FormGroup,
  Menu,
  MenuItem,
  Popover,
  Position,
} from '@blueprintjs/core'
import { DateInput } from '@blueprintjs/datetime'
import arrayMove from 'array-move'
import produce from 'immer'
import { times, groupBy, forEach, sortBy, min, max } from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import styled from 'styled-components'
import {
  fetchTemplateMicrocycle,
  insertMicrocycleAtDate,
  createTemplateModule,
  moveTemplateModule,
} from '../../../lib/fetch/templates'
import moduleTypes from '../../../lib/moduleTypes'
import { AppToaster } from '../../../lib/toaster'
import TemplateContext from '../Generic/TemplateContext'
import ModuleList from './ModuleList'
import { getSubscriptionPlans } from '../../../lib/fetchService'

const Grid = styled.div`
  display: grid;
  grid-template-rows: 30px max-content max-content 30px max-content max-content max-content;
  grid-template-columns: repeat(7, minmax(200px, 1fr));
  grid-gap: 0.5em;
`

const DayContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  > *:not(:first-child) {
    margin-top: 0.5em;
  }
`

const DayDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SessionHeader = styled.div`
  grid-column: 1 / -1;
  text-align: center;
  width: 100%;
  font-size: 2em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.57);
`

class MicrocycleTemplateContainer extends React.Component {
  state = {
    microcycle: { name: '', modules: [] },
    subscriptionPlans: [],
    loading: false,
    targetDate: null,
  }

  componentWillMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props
    this.setState({ loading: true })
    const res = await fetchTemplateMicrocycle(id)
    const subRes = await getSubscriptionPlans()

    this.setState({
      microcycle: res.data,
      subscriptionPlans: subRes.data['hydra:member'],
      loading: false,
    })
  }

  handleCreateModule = async ({ type, subtype, day }) => {
    const {
      match: {
        params: { id },
      },
    } = this.props

    const {
      microcycle: { modules },
    } = this.state

    await createTemplateModule({
      day,
      session: 0,
      position: -1,
      subtype,
      templateMicrocycle: parseInt(id, 10),
      type,
    })
    this.fetchData()
  }

  fixPositions = async () => {
    const {
      microcycle: { modules },
    } = this.state
    const newPositions = []

    forEach(groupBy(modules, 'day'), group => {
      forEach(groupBy(group, 'session'), modules => {
        forEach(sortBy(modules, 'position'), (m, i) => {
          newPositions.push({ ...m, position: i })
        })
      })
    })

    this.updatePositions(newPositions)
  }

  updatePositions = async modules => {
    this.setState(
      produce(draft => {
        modules.forEach(m => {
          const idx = draft.microcycle.modules.findIndex(
            ({ id }) => id === m.id,
          )
          draft.microcycle.modules.splice(idx, 1, m)
        })
      }),
    )

    try {
      await Promise.all(
        modules.map(({ id, position, day, session }) =>
          moveTemplateModule({ id, position, day, session }),
        ),
      )
      AppToaster.show({
        message: 'Fertig',
        intent: 'success',
      })
    } catch (e) {
      console.error(e)
      this.fetchData()
    }
  }

  moveModule = async event => {
    if (!event.destination) {
      return
    }

    const { id } = JSON.parse(event.draggableId)

    const destination = JSON.parse(event.destination.droppableId)
    const source = JSON.parse(event.source.droppableId)

    const {
      microcycle: { modules },
    } = this.state

    const droppedModule = modules.find(m => m.id === id)

    let modulesToUpdate = []
    if (event.destination.droppableId === event.source.droppableId) {
      const from = min([event.source.index, event.destination.index])
      const to = max([event.source.index, event.destination.index])
      const down = event.source.index < event.destination.index

      if (down) {
        modulesToUpdate = [
          ...modules
            .filter(
              m =>
                m.day === source.day &&
                m.session === source.session &&
                m.position > from &&
                m.position <= to,
            )
            .map(m => ({ ...m, position: m.position - 1 })),
        ]
      } else {
        modulesToUpdate = [
          ...modules
            .filter(
              m =>
                m.day === source.day &&
                m.session === source.session &&
                m.position >= from &&
                m.position < to,
            )
            .map(m => ({ ...m, position: m.position + 1 })),
        ]
      }
    } else {
      const sourceModulesToUpdate = modules
        .filter(
          m =>
            m.day === source.day &&
            m.session === source.session &&
            m.position > event.source.index &&
            m.id !== id,
        )
        .map(m => ({ ...m, position: m.position - 1 }))

      const destinationModulesToUpdate = modules
        .filter(
          m =>
            m.day === destination.day &&
            m.session === destination.session &&
            m.position >= event.destination.index &&
            m.id !== id,
        )
        .map(m => ({ ...m, position: m.position + 1 }))

      modulesToUpdate = [
        ...sourceModulesToUpdate,
        ...destinationModulesToUpdate,
      ]
    }

    modulesToUpdate.push({
      ...droppedModule,
      position: event.destination.index,
      session: destination.session,
      day: destination.day,
    })

    this.setState(
      produce(draft => {
        modulesToUpdate.forEach(m => {
          const idx = draft.microcycle.modules.findIndex(
            ({ id }) => id === m.id,
          )
          draft.microcycle.modules.splice(idx, 1, m)
        })
      }),
    )

    try {
      await moveTemplateModule({
        id,
        position: event.destination.index,
        session: destination.session,
        day: destination.day,
      })
    } catch (e) {
      this.fetchData()
    }

    // this.updatePositions(modulesToUpdate)
  }

  handleDateChange = targetDate => this.setState({ targetDate })

  render() {
    const date = DateTime.local().startOf('week')
    const {
      microcycle: { modules, name },
      loading,
      subscriptionPlans,
    } = this.state

    return (
      <div>
        <SessionHeader>{name}</SessionHeader>
        <DragDropContext onDragEnd={this.moveModule}>
          <TemplateContext.Provider value={{ refresh: this.fetchData }}>
            <Grid>
              <SessionHeader>Session 1</SessionHeader>
              {times(7, i => (
                <DayDisplay key={i}>
                  {date.plus({ days: i }).weekdayShort}
                  <Popover
                    autoFocus={false}
                    content={
                      <Menu>
                        {moduleTypes.types.map(type => (
                          <MenuItem key={type.value} text={type.label}>
                            {moduleTypes.subtypes[type.value].map(subtype => (
                              <MenuItem
                                key={subtype.value}
                                text={subtype.label}
                                onClick={() =>
                                  this.handleCreateModule({
                                    type: type.value,
                                    subtype: subtype.value,
                                    day: i,
                                  })
                                }
                              />
                            ))}
                          </MenuItem>
                        ))}
                      </Menu>
                    }
                    position={Position.BOTTOM_LEFT}
                  >
                    <Button rightIcon="caret-down" icon="new-link" />
                  </Popover>
                </DayDisplay>
              ))}
              {times(7, i => (
                <Droppable
                  key={`session-0-day-${i}`}
                  droppableId={JSON.stringify({ session: 0, day: i })}
                >
                  {(provided, snapshot) => (
                    <DayContainer
                      ref={provided.innerRef}
                      className={loading && Classes.SKELETON}
                    >
                      <ModuleList
                        modules={modules.filter(m => m.day === i)}
                        subscriptionPlans={subscriptionPlans}
                        session={0}
                      />

                      {provided.placeholder}
                    </DayContainer>
                  )}
                </Droppable>
              ))}
              <SessionHeader>Session 2</SessionHeader>
              {times(7, i => (
                <Droppable
                  key={`session-1-day-${i}`}
                  droppableId={JSON.stringify({ session: 1, day: i })}
                >
                  {(provided, snapshot) => (
                    <DayContainer
                      ref={provided.innerRef}
                      className={loading && Classes.SKELETON}
                    >
                      <ModuleList
                        modules={modules.filter(m => m.day === i)}
                        subscriptionPlans={subscriptionPlans}
                        session={1}
                      />
                      {provided.placeholder}
                    </DayContainer>
                  )}
                </Droppable>
              ))}
            </Grid>
          </TemplateContext.Provider>
        </DragDropContext>
        <FormGroup label="In Woche einfügen">
          <DateInput
            dayPickerProps={{
              showWeekNumbers: true,
              firstDayOfWeek: 1,
              disabledDays: { daysOfWeek: [0, 2, 3, 4, 5, 6] },
            }}
            onChange={this.handleDateChange}
            parseDate={str => DateTime.fromISO(str)}
            formatDate={date => DateTime.fromJSDate(date).toISODate()}
            placeholder={'DD.MM.YYYY'}
            value={this.state.targetDate}
            maxDate={DateTime.local()
              .plus({ years: 1 })
              .toJSDate()}
          />
          <Button
            icon="locate"
            onClick={async () => {
              const {
                match: {
                  params: { id },
                },
              } = this.props
              const datetime = DateTime.fromJSDate(this.state.targetDate)
              await insertMicrocycleAtDate({ id, date: datetime.toISO() })
              AppToaster.show({
                message: `Template in KW ${datetime.toFormat('WW')} eingefügt`,
                intent: 'success',
              })
            }}
          />
        </FormGroup>
      </div>
    )
  }
}

const enhance = compose(withRouter)

export default enhance(MicrocycleTemplateContainer)
