import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Classes } from '@blueprintjs/core'
import classnames from 'classnames'

const ModuleEditButton = ({ id }) => (
  <Link
    className={classnames(Classes.BUTTON, 'bp3-icon-edit')}
    to={`/module/${id}/edit`}
  />
)

ModuleEditButton.propTypes = {
  id: PropTypes.number.isRequired,
}

export default ModuleEditButton
