import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Menu, Classes } from '@blueprintjs/core'
import CenteredSpinner from '../../CenteredSpinner'

const LinkMenuItem = ({ label, ...props }) => (
  <Link className="bp3-menu-item" {...props}>
    {label}
  </Link>
)

const TemplateList = ({ type, cycles, loading }) => (
  <Menu className={Classes.ELEVATION_1}>
    {loading ? (
      <CenteredSpinner />
    ) : (
      cycles.map(cycle => (
        <LinkMenuItem
          label={cycle.name}
          to={`/templates/${type}/${cycle.id}`}
        />
      ))
    )}
  </Menu>
)

TemplateList.propTypes = {
  type: PropTypes.string.isRequired,
  cycles: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default TemplateList
