import { Button, ButtonGroup, Card } from '@blueprintjs/core'
import { NumberInputAdapter } from 'components/Form/Adapters'
import Row from 'components/Layout/StyledComponents/Row'
import useModuleComponent from 'lib/hooks/useModuleComponent'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useModuleActions } from '../hooks'

export const PowerbuildingModuleComponent = ({ id, dragHandle }) => {
  const component = useModuleComponent(id, 'powerbuilding')
  const { handleRemove, handleSave } = useModuleActions(component)

  return (
    <Card>
      <Form
        onSubmit={handleSave}
        initialValues={component}
        render={({ form, values, handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            {submitError && <div className="error">{submitError}</div>}
            <Row>
              {dragHandle}
              <div style={{ flexGrow: 1 }}>
                <h3>{component.title || component['@type']}</h3>
              </div>
              <ButtonGroup>
                <Button
                  intent="success"
                  icon="floppy-disk"
                  type="submit"
                  loading={submitting}
                />
                {/* <Button
                  icon={this.state.expanded ? 'chevron-up' : 'chevron-down'}
                  onClick={this.toggleExpand}
                /> */}
                {component['@type'] !== 'WeightliftingModuleComponent' && (
                  <Button icon="trash" intent="danger" onClick={handleRemove} />
                )}
              </ButtonGroup>
            </Row>
            <div style={{ marginTop: '8px', marginBottom: '8px' }}>
              <Field
                name="duration"
                type="text"
                label="Dauer"
                format={value => value / 60}
                parse={value => value * 60}
                component={NumberInputAdapter}
              />
            </div>
          </form>
        )}
      />
    </Card>

    // <ModuleComponent
    //   title="powerbuilding"
    //   component={component}
    //   fields={[{ name: 'duration', label: 'Dauer', type: 'duration' }]}
    //   color={Colors.powerbuilding}
    // />
  )
}

PowerbuildingModuleComponent.propTypes = {
  id: PropTypes.number.isRequired,
}

export default PowerbuildingModuleComponent
