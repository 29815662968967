import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withProps, withStateHandlers } from 'recompose'
import { includes, sortBy, xor } from 'lodash'
import matchSorter from 'match-sorter'
import { withSpinnerWhileCondition } from '../../../Layout/withSpinnerWhileLoading'
import { setFilter } from '../../../../ducks/evaluation'
import { requestFetchUsers } from '../../../../sagas/users'
import { requestSetFilterUsersByPlan } from '../../../../sagas/evaluation'

const enhanceAthleteFilter = compose(
  connect(
    ({ evaluation: { filter }, users: { users } }) => ({
      filter,
      athletes: users.map(user => ({
        ...user.athleteDetails,
        email: user.emailCanonical,
      })),
    }),
    {
      setFilter,
      requestSetFilterUsersByPlan,
      requestFetchUsers,
    },
  ),
  withStateHandlers(
    { nameFilter: '' },
    {
      setNameFilter: () => ({ target: { value } }) => ({
        nameFilter: value,
      }),
    },
  ),
  lifecycle({
    componentWillMount() {
      this.props.requestFetchUsers()
    },
  }),
  withSpinnerWhileCondition(({ athletes }) => !athletes),
  withProps(({ athletes, nameFilter, filter }) => ({
    selected: matchSorter(athletes, nameFilter, {
      keys: ['name', 'email'],
    }).filter(({ id }) => includes(filter.id, id)),
    unselected: matchSorter(athletes, nameFilter, {
      keys: ['name', 'email'],
    }).filter(({ id }) => !includes(filter.id, id)),
  })),
)

const Athlete = ({ id, name, email, selected, setFilter, filter }) => (
  <div
    style={{
      border: '1px solid rgba(0, 0, 0, 0.37)',
      borderRadius: '4px',
      cursor: 'pointer',
      margin: '5px 0',
      color: !selected && 'rgba(0, 0, 0, 0.57)',
    }}
    role="button"
    tabIndex={0}
    onClick={() => {
      setFilter({ id: xor(filter.id, [id]) })
    }}
    className="grid-x grid-padding-x"
  >
    <div className="cell shrink">
      <span
        style={{
          padding: '0 10px',
          display: 'inline-block',
        }}
      >
        {selected ? '-' : '+'}
      </span>
    </div>
    <div className="cell auto">{name || email}</div>
  </div>
)

const AthleteFilter = enhanceAthleteFilter(
  ({
    setFilter,
    selected,
    unselected,
    filter,
    nameFilter,
    setNameFilter,
    requestSetFilterUsersByPlan,
  }) => (
    <div style={{ marginTop: '16px' }}>
      <div>
        <input
          style={{ display: 'inline' }}
          type="text"
          placeholder="Suche"
          value={nameFilter}
          onChange={setNameFilter}
        />
      </div>
      <div className="button-group">
        {['titan', 'aluminium', 'free', 'alle'].map(plan => (
          <button
            className="button tiny"
            onClick={() => requestSetFilterUsersByPlan(plan)}
          >
            {plan}
          </button>
        ))}
      </div>

      <div>
        <div
          style={{
            height: `${selected.length * 36}px`,
            overflowY: 'scroll',
            marginBottom: '10px',
            paddingBottom: '10px',
          }}
        >
          {sortBy(selected, 'id').map(({ id, name, email, plan }) => (
            <Athlete
              id={id}
              name={name}
              email={email}
              plan={plan}
              setFilter={setFilter}
              filter={filter}
              selected
            />
          ))}
        </div>
        <div style={{ height: '200px', overflowY: 'scroll' }}>
          {sortBy(unselected, 'id').map(({ id, name, email, plan }) => (
            <Athlete
              id={id}
              name={name}
              email={email}
              plan={plan}
              setFilter={setFilter}
              filter={filter}
            />
          ))}
        </div>
      </div>
    </div>
  ),
)

export default AthleteFilter
