import { Button, Intent, Checkbox } from '@blueprintjs/core'
import produce from 'immer'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const LowerButtonPanel = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: -1em;
  width: 100%;
  > * {
    display: flex;
    justify-content: center;
  }
`

class ModuleSubscriptionPlanControl extends React.Component {
  constructor(props) {
    super(props)
    this.state = { subscriptionPlans: props.subscriptionPlans, changed: false }
  }

  static getDerivedStateFromProps(props, state) {
    return { subscriptionPlans: props.subscriptionPlans, changed: false }
  }

  togglePlan = plan => ({ target: { checked } }) => {
    if (checked) {
      this.setState(
        produce(draft => {
          draft.subscriptionPlans.push(plan)
          draft.changed = true
        }),
      )
    } else {
      this.setState(
        produce(draft => {
          draft.subscriptionPlans.splice(
            draft.subscriptionPlans.findIndex(({ id }) => id === plan.id),
            1,
          )
          draft.changed = true
        }),
      )
    }
  }

  handleSave = () => {
    this.props.onSave(this.state.subscriptionPlans)
    this.setState(
      produce(draft => {
        draft.changed = false
      }),
    )
  }

  render() {
    const { allSubscriptionPlans } = this.props
    const { subscriptionPlans, changed } = this.state
    return (
      <LowerButtonPanel>
        <div>
          <span>Basic</span>
        </div>
        <div>
          <span>Alu</span>
        </div>
        <div>
          <span>Titan</span>
        </div>

        {allSubscriptionPlans
          .filter(p => p.name !== 'weightlifting')
          .map(plan => (
            <div key={plan.name}>
              <Checkbox
                name={plan.name}
                checked={
                  subscriptionPlans.findIndex(
                    ({ name }) => name === plan.name,
                  ) > -1
                }
                onChange={this.togglePlan(plan)}
              />
            </div>
          ))}

        {changed && (
          <Button
            style={{ gridColumn: '1 / -1' }}
            minimal
            intent={Intent.SUCCESS}
            text="Speichern"
            onClick={this.handleSave}
          />
        )}
      </LowerButtonPanel>
    )
  }
}

ModuleSubscriptionPlanControl.propTypes = {
  subscriptionPlans: PropTypes.array.isRequired, // eslint-disable-line
  allSubscriptionPlans: PropTypes.array.isRequired, // eslint-disable-line
  onSave: PropTypes.func.isRequired,
}

export default ModuleSubscriptionPlanControl
