import styled from 'styled-components'

export default styled.div`
  display: flex;
  align-items: center;
  > * {
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
`
