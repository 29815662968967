import update from 'immutability-helper'
import { normalize } from 'normalizr'
import { moduleDate } from '../lib/normalize'
import { SET_MODULE } from './common'
import {
  CREATE_COMPONENT_SUCCESS,
  REMOVE_COMPONENT_SUCCESS,
} from './components'
import { FETCH_END, FETCH_ERROR, FETCH_START } from './fetch'
import {
  CREATE_MODULE_DATE_SUCCESS,
  FETCH_MODULE_DATE_SUCCESS,
} from './moduleDates'

export const FETCH_MODULE_REQUEST = 'FETCH_MODULE_REQUEST'
export const FETCH_MODULE_SUCCESS = 'FETCH_MODULE_SUCCESS'
export const FETCH_MODULE_FAIL = 'FETCH_MODULE_FAIL'

export const CREATE_MODULE_REQUEST = 'CREATE_MODULE_REQUEST'
export const CREATE_MODULE_SUCCESS = 'CREATE_MODULE_SUCCESS'
export const CREATE_MODULE_FAIL = 'CREATE_MODULE_FAIL'

export const SAVE_MODULE_REQUEST = 'SAVE_MODULE_REQUEST'
export const SAVE_MODULE_SUCCESS = 'SAVE_MODULE_SUCCESS'
export const SAVE_MODULE_FAIL = 'SAVE_MODULE_FAIL'

export const REMOVE_MODULE_REQUEST = 'REMOVE_MODULE_REQUEST'
export const REMOVE_MODULE_SUCCESS = 'REMOVE_MODULE_SUCCESS'
export const REMOVE_MODULE_FAIL = 'REMOVE_MODULE_FAIL'

export const ADD_MODULE = 'ADD_MODULE'
export const UPDATE_MODULE = 'UPDATE_MODULE'
export const REMOVE_MODULE = 'REMOVE_MODULE'
export const TOGGLE_MODULE_SUBSCRIPTION_PLANS =
  'TOGGLE_MODULE_SUBSCRIPTION_PLANS'

export const updateModule = module => ({
  type: UPDATE_MODULE,
  module,
})

export const removeModule = (module, session) => ({
  type: REMOVE_MODULE_REQUEST,
  module,
  session,
})

export const saveModule = module => ({
  type: SAVE_MODULE_REQUEST,
  module,
})

export const fetchModule = id => ({
  type: FETCH_MODULE_REQUEST,
  payload: { id },
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_MODULE:
      return {
        ...state,
        ...action.plan.entities.modules,
      }
    case CREATE_MODULE_SUCCESS:
      return {
        ...state,
        [action.module.id]: action.module,
      }
    case CREATE_MODULE_DATE_SUCCESS:
    case FETCH_MODULE_DATE_SUCCESS:
      const normalized = normalize(action.moduleDate, moduleDate)
      return { ...state, ...normalized.entities.modules }

    case SAVE_MODULE_SUCCESS:
      return update(state, {
        [action.module.id]: { $merge: { dirty: false } },
      })
    case UPDATE_MODULE:
      return update(state, {
        [action.module.id]: {
          $merge: { ...action.module, dirty: true },
        },
      })
    case TOGGLE_MODULE_SUBSCRIPTION_PLANS: {
      return update(state, {
        [action.module.id]: {
          $merge: { subscriptionPlans: action.plans, dirty: true },
        },
      })
    }

    case REMOVE_MODULE_SUCCESS:
      return update(state, { $unset: [action.module.id] })
    case CREATE_COMPONENT_SUCCESS:
      return update(state, {
        [action.module.id]: {
          components: {
            $push: [
              { id: action.component.id, schema: action.component['@type'] },
            ],
          },
        },
      })
    case REMOVE_COMPONENT_SUCCESS: {
      const components = state[action.module.id].components.filter(
        component => component.id !== action.component.id,
      )
      return update(state, {
        [action.module.id]: { components: { $set: components } },
      })
    }
    case FETCH_START: {
      if (action.module && state[action.module.id]) {
        return update(state, {
          [action.module.id]: { $merge: { fetch: true } },
        })
      }
    }
    case FETCH_ERROR: // eslint-disable-line no-fallthrough
    // falls through

    case FETCH_END: {
      if (action.module && state[action.module.id]) {
        return update(state, {
          [action.module.id]: { $merge: { fetch: false } },
        })
      }
      return state
    }
    default:
      // eslint-disable-line no-fallthrough
      return state
  }
}

export default reducer
