import React from 'react'
import { Route } from 'react-router-dom'
import styled from 'styled-components'
import StrengthPlanEdit from './StrengthPlanEdit'
import StrengthPlanList from './StrengthPlanList'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  grid-gap: 20px;
`

const StrengthPlanRouter = () => (
  <Grid>
    <StrengthPlanList />
    <Route path="/strengthplans/:id" component={StrengthPlanEdit} />
  </Grid>
)

export default StrengthPlanRouter
